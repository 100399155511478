import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReviewComponent, UPCInputState} from 'pm-components';
import {Attribute, Candidate, CandidateProduct, Product} from 'pm-models';
import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateService} from '../../service/candidate.service';
import {EditCandidateModalService} from '../../service/edit-candidate-modal.service';
import {FileService} from '../../service/file.service';
import {LookupService} from '../../service/lookup.service';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateHistoryService} from '../../service/candidate-history.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {MatUtilService} from '../../service/mat-util.service';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-associate-upc-procurement-review-closed',
  templateUrl: './associate-upc-procurement-review-closed.component.html',
  styleUrls: ['./associate-upc-procurement-review-closed.component.scss']
})
export class AssociateUpcProcurementReviewClosedComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public associateCandidateProduct: CandidateProduct;
  private currentCandidateProductIndex = 1;
  private searchedCandidateProductIndex = 0;
  public productData = [];
  public productImageUrl: string = null;
  public currentCandidateProduct: CandidateProduct;
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public isViewingPage = true;
  public upcState: UPCInputState;
  private product: Product;
  private commodity: any;
  private buyer: any;
  private commodityId;
  public selectedCasePack: number;
  public associateCandidateProductsList: CandidateProduct[];
  showHistoryPanel: boolean = false;

  showMatAttributes = false;
  isLoadingMatData = true;
  public hierarchyNumberToAttributesMap: Map<number, Attribute[]> = new Map();
  public hierarchyAttributes: Attribute[] = [];
  public globalAttributes: Attribute[] = [];
  public upcAttributes: Attribute[] = [];


  constructor(private workflowService: WorkflowService, private route: ActivatedRoute,
              private router: Router, public candidateService: CandidateService, private lookupService: LookupService,
              public fileService: FileService, public editCandidateModalService: EditCandidateModalService, public candidateUtilService: CandidateUtilService,
              public candidateHistoryService: CandidateHistoryService, private growlService: GrowlService,
              public permissionService: NgxPermissionsService, public matUtilService: MatUtilService) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.ASSOCIATE_UPC) {
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.setInitialValues(candidate);
            this.candidateHistoryService.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  private setInitialValues(candidate: Candidate) {
    this.candidate = candidate;
    this.candidateProduct = this.candidate.candidateProducts[0];
    this.currentCandidateProduct = this.getCurrentCandidateProduct();
    this.associateCandidateProduct = this.candidate.candidateProducts[1];
    this.associateCandidateProductsList = this.candidate.candidateProducts.slice(1);
    this.isViewingPage = true;
    this.productImageUrl = null;

    this.setProductData().subscribe(() => {
        this.setupMatAttributes();
      }, (error) => {
        this.growlService.addError(error.message);
      }
    );
    if (candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      this.lookupService.getUpc(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc).subscribe(
        (upc) => {
          this.product = upc.product;
          this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.product);
        });
    } else if (candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_ITEM) {
      this.lookupService.getItem(
        candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode)
        .subscribe((productData) => {
          // using the the upc from the item information to get all the needed fields
          this.lookupService.getUpc(productData.containedUpc.upc.scanCodeId).subscribe(
            (upc) => {
              this.product = upc.product;
              this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.product);
            });
        });
    }
  }

  setupMatAttributes() {
    if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }

  private setProductData(): Observable<any> {
    if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.candidate.productId = productData.productId;
          this.setCaseUpcData(productData);
        })
      );
    } else if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, []).pipe(
        tap((productData) => {
          if (productData) {
            this.productData = productData;
            this.candidate.productId = productData.productId;
            this.setCaseUpcData(productData);
            this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc = productData.primaryScanCodeId;
          }
        })
      );
    }
  }

  setCaseUpcData(productData: any) {
    this.productData = productData.items;
    this.setCasePack();
  }

  setCasePack() {
    for (let x = 0; x < this.productData.length; x++) {
      if (this.productData[x].caseUpc === this.associateCandidateProduct.caseUpc) {
        this.selectedCasePack = this.associateCandidateProduct.caseUpc;
        this.currentCandidateProduct.caseUpc = this.selectedCasePack;
        this.commodityId = this.productData[x].commodity;
        this.findCommodity();
      }
    }
  }

  /**
   * To find the commodity.
   */
  findCommodity() {
    this.lookupService.findCommodity(this.commodityId).subscribe( data => {
      this.commodity = data;

      this.findBuyer();
    });
  }

  /**
   * To find buyer info attached to the commodity.
   */
  findBuyer() {
    this.lookupService.findBuyer(this.commodity[0].buyer.buyerId).subscribe( buyerData => {
      this.buyer = buyerData;
    });
  }

  getSelectedCasePack() {
    return this.selectedCasePack;
  }

  onClickBackToHome() {
    this.resetInitialValues();
    this.router.navigate(['/tasks'], { queryParams: { tabIndex: 1 } }).then();
  }

  onClickPrint() {
    window.print();
  }

  private resetInitialValues() {
    this.isViewingPage = false;
    this.candidate = undefined;
    this.candidateProduct = undefined;
    this.productImageUrl = null;
  }

  onClose() {
    this.resetInitialValues();
    this.router.navigate(['/tasks']).then();
  }

  getTitleSubHeading(): String {
    let infoString = '';

    infoString += 'Status: ';
    infoString += CandidateUtilService.getUIStatusFromCandidateStatus(this.candidate.status);
    if (this.candidateProduct.itemCode) {
      infoString += ' | Item code: ' + this.candidateProduct.itemCode;
    }

    return infoString;
  }

  equals(caseUpc: any) {
    return (caseUpc === this.selectedCasePack);
  }

  /**
   * Returns the current candidate product.
   */
  public getCurrentCandidateProduct(): CandidateProduct {
    return this.candidate.candidateProducts[this.currentCandidateProductIndex];
  }

  historyPanelOpen() {
    this.candidateHistoryService.historyPanelOpen();
    this.showHistoryPanel = true;
    this.pmReview.openDrawer();
  }

  historyPanelClose() {
    this.candidateHistoryService.historyPanelClose();
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }
}
