import {Component, Input, KeyValueDiffer, KeyValueDiffers, OnInit} from '@angular/core';
import {CandidateError} from 'pm-models';

@Component({
  selector: 'app-pia-new-invited-distributor-stepper',
  templateUrl: './pia-new-invited-distributor-stepper.component.html',
  styleUrls: ['./pia-new-invited-distributor-stepper.component.scss']
})
export class PiaNewInvitedDistributorStepperComponent implements OnInit {

  private errorCandidateDiffer: KeyValueDiffer<string, any>;

  private readonly errorToolTip = 'This page contains errors or is missing information in required fields.';
  private readonly errorIcon = 'warning';

  @Input()
  candidateErrors: CandidateError[];

  @Input()
  isSellable: boolean;

  @Input()
  stepIndex: number;

  @Input()
  supplierInitiated: boolean;

  steps;

  constructor(private differs: KeyValueDiffers) {}

  ngOnInit() {
    this.steps = [{text: 'UPC'}, {text: 'Case details'}, {text: 'Store authorization'}, {text: 'Review'}];
  }

  /**
   * Change detection that compares candidate errors with previous version.
   * If the candidate differ doesn't exist, it creates it
   * Runs apply errors if any changes have been detected
   * @memberof NewWarehouseProductStepperComponent
   */
  ngDoCheck(): void {
    let candidateHasChanges = false;

    if (this.errorCandidateDiffer) {
      candidateHasChanges = this.errorCandidateDiffer.diff(this.candidateErrors) ? true : false;
    } else if (this.candidateErrors) {
      this.errorCandidateDiffer = this.differs.find(this.candidateErrors).create();
    }

    if (candidateHasChanges) {
      this.applyErrors();
    }
  }

  /**
   * Runs through each step of menu and runs validation on each step
   *
   * @memberof NewWarehouseProductStepperComponent
   */
  applyErrors() {
    this.steps.forEach((x, i) => {
      switch (i) {
        case 0:
          this.validateUPC(x, this.candidateErrors[i]);
          break;
        case 1:
          this.validateCaseDetails(x, this.candidateErrors[i]);
          break;
        case 2:
          this.validateStoreAuthorization(x, this.candidateErrors[i]);
          break;
      }
    });
  }

  /**
   * Validation for first screen
   *
   * @param {*} stepItem
   * @memberof CandidateCreationStepperComponent
   */
  validateUPC(stepItem, candidateErrors) {
    const candidateKeys = ['description'];
    const candidateProductKeys = ['upc', 'upcCheckDigit'];
    const authGroupKeys = [];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, authGroupKeys, candidateErrors);
  }

  /**
   * Validation for first screen
   *
   * @param {*} stepItem
   * @memberof NewWarehouseProductStepperComponent
   */
  validateCaseDetails(stepItem, candidateErrors) {
    const candidateKeys = ['costOwner', 'topToTop', 'vendor', 'masterPack', 'masterListCost', 'buyer', 'pssDepartment', 'overrideSubDepartment'];
    const candidateProductKeys = ['vendorProductCode', 'countryOfOrigin', 'caseDescription'];
    const authGroupKeys = [];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, authGroupKeys, candidateErrors);
  }

  /**
   * Validates step with associated keys, applies icon and error message if found
   *
   * @private
   * @param {*} stepItem - Menu Items
   * @param {*} candidateKeys - Candidate Error Keys associated with this step
   * @param {*} candidateProductKeys - Candidate Product Error Keys associated with this step
   * @memberof NewWarehouseProductStepperComponent
   */
  private validateStep(stepItem, candidateKeys, candidateProductKeys, authGroupKeys,  candidateErrors) {
    if (!this.validateKeys(candidateKeys, candidateProductKeys, authGroupKeys, candidateErrors)) {
      stepItem.icon = this.errorIcon;
      stepItem.message = this.errorToolTip;
    } else {
      stepItem.icon = undefined;
      stepItem.message = undefined;
    }
  }

  /**
   * Checks each batch of keys to see if candidateErrors or candidate Product errors
   * contain any values. Immediate return false if error found for performance.
   *
   * @private
   * @param {*} candidateKeys
   * @param {*} candidateProductKeys
   * @returns
   * @memberof NewWarehouseProductStepperComponent
   */
  private validateKeys(candidateKeys, candidateProductKeys, authGroupKeys, candidateErrors) {
    for (let i = 0; i < candidateKeys.length; i++) {
      if (this.candidateErrors && this.fieldHasValue(candidateErrors[candidateKeys[i]])) {
        return false;
      }
    }
    for (let i = 0; i < candidateProductKeys.length; i++) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && this.fieldHasValue(value[candidateProductKeys[i]])) {
            return false;
          }
        }
      }
    }

    for (let i = 0; i < authGroupKeys.length; i++) {
      if (!candidateErrors.candidateProductErrors) {
        return true;
      }
      for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
        for (const [candidateProductKey, candidateProductValue] of Object.entries(value)) {
          if (candidateProductKey === 'locationGroupStoresErrors') {
            for (const [locationGroupKey, locationGroupValue] of Object.entries(candidateProductValue)) {
              if (locationGroupValue && this.fieldHasValue(locationGroupValue[authGroupKeys[i]])) {
                return false;
              }
            }
          }
        }
      }
    }

    return true;
  }

  /**
   * Runs validation for store authorization.
   *
   * @param {*} stepItem - Menu Item
   * @memberof NewWarehouseProductStepperComponent
   */
  validateStoreAuthorization(stepItem, candidateErrors) {
    const candidateKeys = [];
    const candidateProductKeys = [];
    const authGroupKeys = ['stores', 'listCost'];

    this.validateStep(stepItem, candidateKeys, candidateProductKeys, authGroupKeys, candidateErrors);
  }
  /**
   * Takes  a property and immediately checks if it has any sort of value
   * returns 'true' if any value is found or present.
   * @private
   * @param {*} field
   * @returns
   * @memberof NewWarehouseProductStepperComponent
   */
  private fieldHasValue(field) {
    return field !== undefined && field !== null && field !== '';
  }
}
