import { BaseAttribute } from './base-attribute';
import { CandidateProduct, AttributeUPCConfig, CandidateValidatorType, CandidateError, CandidateProductError } from 'pm-models';

// tslint:disable-next-line:max-line-length
import {
  AttributeDisplayUpcComponent,
  InputState
} from '../../shared/attribute-display/attribute-display-upc/attribute-display-upc.component';
import { UPCInputState } from '../../shared/upc-inputs/upc-input/upc-input.component';

export class CaseUPC implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayUpcComponent;
  }

  getAttributeConfig(overrides?): AttributeUPCConfig {
    return {
      label: 'Case UPC',
      description: `Enter the UPC and check digit for
      the case this product will arrive in. You can’t enter a UPC that already exists in our system.`,
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      placeholderText: 'Case UPC',
      checkDigitPlaceholderText: 'Check #',
      name: 'caseUpcId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayUpcComponent, candidate: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.caseUpc;
    componentInstance.modelChange.subscribe(x => {
      candidate.caseUpc = x;
    });
    componentInstance.checkDigitModel = candidate.caseUpcCheckDigit;
    componentInstance.checkDigitModelChange.subscribe(x => {
      candidate.caseUpcCheckDigit = x;
    });

    componentInstance.validateUpc.subscribe(s => {
      if (configOverrides.validationService) {
        componentInstance.state = InputState.loading;
        const candidateProductId = candidate.id;

        configOverrides.validationService
          .validateCandidate({ candidateProducts: [candidate] }, [CandidateValidatorType.CASE_UPC_VALIDATOR])
          .subscribe(
            () => {
              componentInstance.state = InputState.valid;
              componentInstance.attributeError = undefined;
            },
            error => {
              componentInstance.state = InputState.invalid;
              if (error.error.candidateErrors) {
                const candidateErrors: CandidateError = error.error.candidateErrors;
                const candidateProductError: CandidateProductError = candidateErrors.candidateProductErrors[candidateProductId];

                componentInstance.attributeError =
                  candidateProductError.caseUpc;
              }
            }
          );
      }
    });
  }

  updateModel(componentInstance: AttributeDisplayUpcComponent, candidate: CandidateProduct) {
    if (componentInstance.model !== candidate.caseUpc) {
      componentInstance.model = candidate.caseUpc;
    }

    if (componentInstance.checkDigitModel !== candidate.caseUpcCheckDigit) {
      componentInstance.checkDigitModel = candidate.caseUpcCheckDigit;
    }
  }
}
