import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeUPCConfig, CandidateError, CandidateProduct} from 'pm-models';
import {InputState} from '../attribute-display/attribute-display-upc/attribute-display-upc.component';
import {UPCInputState} from 'pm-components';

@Component({
  selector: 'pm-candidate-product-list',
  templateUrl: './candidate-product-list.component.html',
  styleUrls: ['./candidate-product-list.component.scss']
})
export class CandidateProductListComponent implements OnInit {

  @Output() deleteProductEmitted?: EventEmitter<any> = new EventEmitter<any>();
  @Output() addProductEmitted?: EventEmitter<any> = new EventEmitter<any>();
  @Output() validateSingleUpc?: EventEmitter<any> = new EventEmitter<any>();
  @Input() candidateProductsModel: CandidateProduct[];
  @Input() errorModel?: CandidateError;
  @Input() unitUpcConfiguration;
  @Input() state: InputState;
  @Input() isValidating: boolean;
  public hasItems: boolean = false;
  public isList: boolean = false;

  initialUnitUPCConfiguration: AttributeUPCConfig = {
    label: 'Unit UPC',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: 'Unit UPC',
    checkDigitPlaceholderText: 'Check #',
    name: 'associateUpcId'
  };

  genericUnitUPCConfiguration: AttributeUPCConfig = {
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: 'Unit UPC',
    checkDigitPlaceholderText: 'Check #',
    name: 'associateUpcId'
  };

  finalUnitUPCConfiguration: AttributeUPCConfig = {
    description: `Enter the UPC and check digit that appears on this unit's packaging.`,
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: 'Unit UPC',
    checkDigitPlaceholderText: 'Check #',
    name: 'associateUpcId'
  };
  @Input() featureFlag: boolean = true;

  constructor() { }

  ngOnInit() {
    this.setConfig();
    this.checkItems();

  }

  isAssociateUpc(product: CandidateProduct) {
    return (product.candidateProductType === CandidateProduct.ASSOCIATE_UPC);
  }

  deleteExistingAssociate(product: CandidateProduct) {
    this.deleteProductEmitted.emit(product);
    this.checkItems();
  }

  addAssociateUpc() {
    this.addProductEmitted.emit();
    this.checkItems();
  }

  getCandidateProductError(product: CandidateProduct) {
    if (this.errorModel && this.errorModel.candidateProductErrors &&
      this.errorModel.candidateProductErrors[product.id]) {
      if (this.errorModel.candidateProductErrors[product.id].upc) {
        return this.errorModel.candidateProductErrors[product.id].upc;
      } else if (this.errorModel.candidateProductErrors[product.id].upcCheckDigit) {
        return this.errorModel.candidateProductErrors[product.id].upcCheckDigit;
      }
    }
    return false;
  }

  getInputState(product) {
    if (this.isValidating) {
      return UPCInputState.loading;
    } else if (this.getCandidateProductError(product)) {
      return UPCInputState.invalid;
    } else if (!product.upc && !product.upcCheckDigit) {
      return UPCInputState.none;
    } else {
      return UPCInputState.valid;
    }
  }

  checkItems() {
    this.hasItems = (this.candidateProductsModel && (this.candidateProductsModel.length > 1));
    this.isList = (this.candidateProductsModel && (this.candidateProductsModel.length > 2));
  }

  validate(product: CandidateProduct) {
    this.validateSingleUpc.emit(product);
  }

  setConfig() {
    if (this.unitUpcConfiguration) {
      this.initialUnitUPCConfiguration.label = this.unitUpcConfiguration.label;
      this.finalUnitUPCConfiguration.description = this.unitUpcConfiguration.description;
      this.initialUnitUPCConfiguration.placeholderText = this.genericUnitUPCConfiguration.placeholderText
        = this.finalUnitUPCConfiguration.placeholderText = this.unitUpcConfiguration.placeholderText;
    }
  }

  getConfig(product: CandidateProduct) {
    const candidateProductIndex = this.candidateProductsModel.indexOf(product);
    let config;
    switch (candidateProductIndex) {
      case 1: config = this.initialUnitUPCConfiguration;
      break;
      case this.candidateProductsModel.length - 1: config = this.finalUnitUPCConfiguration;
      break;
      default: config = this.genericUnitUPCConfiguration;
      break;
    }
    return config;
  }
}
