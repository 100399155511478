<pm-editor (closeEvent)="onClose()">

  <pm-header>
    <pm-associate-upc-stepper [candidateErrors]="associateUpcService.getAllCandidateErrors()"
                              [stepIndex]="4"
                              (indexClick)="onClickStepper($event)">
    </pm-associate-upc-stepper>
  </pm-header>

  <pm-title>
    Extended attributes
  </pm-title>

  <div *ngIf="isLoadingData" class="col-md-8 spinner-row">
    <pm-progress-spinner [showSpinner]="isLoadingData" [strokeWidth]="'2'"
                         [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}">
    </pm-progress-spinner>
    <h5 class="ml-3">Fetching attributes...</h5>
  </div>
  <ng-container *ngIf="!isLoadingData">
    <div class="associate-attributes-container-wrapper">
      <div class="row header-divider"></div>
      <div *ngFor="let candidateProduct of associateUpcService.candidate.candidateProducts; let i = index">
        <div *ngIf="i !== 0" class="row">
          <div class="col-md-12 associate-attributes-container">
            <div class="row header-row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-9 header-text">Associate UPC: &nbsp;{{candidateProduct.upc}}</div>
                  <div class="col-md-2 header-error-column">
                    <div *ngIf="!!hasExtendedAttributesErrors(candidateProduct.id)" class="header-error">
                      <img src="/assets/images/triangle_exclamation.svg">
                      Errors
                    </div>
                  </div>
                  <div class="col-md-1 expand-collapse-column">
                    <i [ngClass]="candidateProduct.isViewing ? 'pi pi-caret-up' : 'pi pi-caret-down'" (click)="candidateProduct.isViewing = !candidateProduct.isViewing"></i>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="candidateProduct.isViewing" class="row associate-attributes-body">
              <div class="col-md-12">

                <pm-global-attributes-card [candidateModel]="associateUpcService.candidate"
                                           [candidateProductModel]="candidateProduct"
                                           [globalAttributes]="associateUpcService.globalAttributes"
                                           [candidateProductErrorModel]="candidateError?.candidateProductErrors[candidateProduct.id]">
                </pm-global-attributes-card>
              </div>
              <div class="col-md-12">
                <pm-mat-categories-card [candidateModel]="associateUpcService.candidate"
                                        [candidateProductModel]="candidateProduct"
                                        [hierarchyAttributes]="associateUpcService.hierarchyAttributes"
                                        [candidateProductErrorModel]="candidateError?.candidateProductErrors[candidateProduct.id]">
                </pm-mat-categories-card>
              </div>
            </div>
          </div>
          <div class="col-md-12 footer-divider"></div>
        </div>
      </div>
    </div>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="{{getActivateButtonTitle()}}" (onClick)="onClickActivate()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
