import {Injectable} from '@angular/core';
import {Candidate, CandidateHelper, CandidateProduct, File, Product, Task} from 'pm-models';
import {DecimalPipe} from '@angular/common';
import {UpcRequestType} from 'pm-models/lib/newUpcRequest';
import {ExistingInner} from 'pm-models/lib/existingInner';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {LookupService} from './lookup.service';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, map, switchMap, tap} from 'rxjs/operators';
import {ParamMap} from '@angular/router';
import {WorkflowService} from './workflow.service';
import {CandidateService} from './candidate.service';
import {GrowlService} from '../growl/growl.service';
import {ItemWeightTypeService} from './item-weight-type.service';
import {FreightTaxUpcRequest} from 'pm-models/lib/freightTaxUpcRequest';

@Injectable({
  providedIn: 'root'
})
export class CandidateUtilService {

  constructor(private decimalPipe: DecimalPipe, private lookupService: LookupService, private workflowService: WorkflowService,
              private candidateService: CandidateService, private growlService: GrowlService, private itemWeightTypeService: ItemWeightTypeService) { }

  public TERMS_AND_CONDITIONS_TEXT = 'Supplier T&C’s\n' +
    '\n' +
    'Terms and Conditions for Use of [________________ Portal] You are advised to print and keep a copy of this Agreement for your records\n' +
    '\n' +
    'This Agreement is entered into between H-E-B, LP (“H-E-B”), a Texas limited partnership and SUPPLIER (as defined below)\n' +
    'WHEREAS, H-E-B has procured or developed the website accessible at ______________________________ (the “Site”) to assist H-E-B suppliers in the submission and receipt of their product specifications and other electronic communications to and from H-E-B; \n' +
    'WHEREAS, H-E-B makes product life cycle application(s) and service(s) available via the Site (the “Service”). \n' +
    'WHEREAS, You represent to H-E-B that You are authorized to bind and enter into this Agreement on behalf of the company or other legal entity You identified on the self-registration screen of this Site during Your registration (“Supplier”); \n' +
    'WHEREAS, Supplier wishes to make use of the Site and Service in accordance with these terms and conditions (the “Agreement”); \n' +
    'NOW THEREFORE, for and in consideration of the mutual covenants hereinafter contained, and other valuable consideration, the receipt of which is hereby acknowledged, H-E-B and Supplier agree as follows: \n' +
    '1.\tSERVICE.\n' +
    '1.1 Terms of Service. This Agreement shall govern Supplier’s and its Authorized Users’ access and use of the Site and Service. In addition, Supplier agrees that, unless explicitly stated otherwise, any new features that augment or enhance the Site, Service or any new service(s) included in the Service will be subject to this Agreement. Capitalized terms which are not defined in the body of this Agreement have the meaning set forth in Section 20.\n' +
    '1.2 Security and Site Integrity. H-E-B agrees to use commercially reasonable efforts to utilize equipment and security measures in accordance with applicable industry standards. H-E-B will, as soon as reasonably practicable, notify Supplier of any security breach (physical or electronic) involving Supplier Data. Supplier acknowledges and agrees it is possible that third parties such as “hackers” may gain unauthorized access to the Site or Service and alter contents and that H-E-B will not be liable for any damages or loss arising out of or in connection with such unauthorized access. To the fullest extent permitted by law, H-E-B is not liable for any unauthorized access to the Site or Service.\n' +
    'For legitimate business purposes, H-E-B personnel may monitor the use of H-E-B networks or computer systems, including without limitation the Site, Service, phone-mail, E-mail and Internet use, and have the right to review any information stored in, transmitted to or received by any H-E-B network or computer system herein. By using network and computer systems owned or utilized, directly or indirectly, by H-E-B, Supplier consents to such inspection, review and/or disclosure.\n' +
    'Supplier acknowledges and agrees that the Site and Service are segmented by Accounts Payable Number (“AP#”). Accordingly, any Authorized User Supplier authorizes to access an AP# will have access to everything on the Site and System associated with such AP# that is not otherwise restricted by additional security measures, such additional security measures to be implemented and modified by H-E-B from time to time in its sole discretion. Accordingly, Supplier shall only permit an Authorized User to access an AP# when Supplier wants such Authorized User to be able to use, add to, modify or delete information and documents associated with such AP#. Similarly, Supplier shall promptly remove any employees, brokers or other Authorized Users from any AP# immediately after such Authorized User is no longer authorized to represent Supplier on such AP#(s).\n' +
    'Supplier further acknowledges and agrees that it shall ensure its Authorized Users will only use the Site and Services for the benefit of Supplier and not for their own use or benefit, or for the use or benefit of any third party (other than Supplier or, in the case of brokers, other H-E-B suppliers for which such broker is also authorized to represent on the Site or Service).  Supplier represents and warrants that Authorized Users (i) have specifically been authorized and empowered to act on behalf of Supplier, and (ii) will not access or attempt to access any other supplier information or documents.  Supplier will ensure that Authorized Users notify Supplier and H-E-B of any access to an AP# or supplier that such Authorized Users does not currently represent, and that any Authorized User involved will cease using the Site and Service immediately with respect to such non-permitted AP# or supplier.  If Supplier is an agent for other suppliers, it is acknowledged that an Authorized User may retain access to other AP#s or suppliers for which Supplier’s agency continues.\n' +
    '1.3 Passwords. To enable Supplier to submit Supplier Data securely, H-E-B will issue a password for an account on the Site (the “Password”) to Supplier. The Password is solely for the Supplier’s use in accordance with this Agreement. Supplier shall have authority to create Passwords and login credentials for Authorized Users. Under no circumstances will Supplier provide a Password to, or otherwise permit any access by any employee or agent of a competitor of H-E-B. If there is any question with regards to whether an entity is a competitor of H-E-B, it is Supplier’s responsibility to contact H-E-B and obtain written permission before granting such employee or agent access to the Site or Service. \n' +
    'Supplier is responsible for maintaining the confidentiality of the Passwords and other login credentials created for Authorized Users and for all activities and charges enabled by their use, including unauthorized use. Supplier therefore shall provide only carefully vetted, trusted parties with login credentials to become Authorized Users.  Supplier shall maintain the security of Passwords and login credentials of its Authorized Users and shall immediately notify H-E-B in writing and via email if Supplier discovers or suspects any breach of security or unauthorized use of such Passwords has occurred. Supplier shall immediately disable access to the Site and Service for any and all Authorized Users that are terminated, quit, or otherwise are no longer authorized by Supplier to access or use the Site or Service on Supplier’s behalf. \n' +
    'Supplier shall ensure that Authorized Users do not share passwords, and that no Authorized User attempts to access information of any other supplier or H-E-B partner that utilizes the Site or Service unless such Authorized User is also authorized to use the Site or Service on behalf of such other supplier. In no event may Supplier provide any third party (other than its Authorized Users) direct access to the Site or Service except as expressly authorized by H-E-B in writing.  Supplier is solely responsible for the acts and omissions of every Authorized User. By using this system, Supplier agrees to never reveal any Password to others or allow use of an H-E-B Site account by others. This includes co-workers, household members, and visitors when work is being done at home. Revealing a Password to an authorized H-E-B technician during troubleshooting procedures is not a violation of this policy. In such a situation, a new Password should be established as soon as possible after the problem is resolved. Violations will be reported and prosecuted.\n' +
    '1.4 Modification to Agreement or Service. H-E-B reserves the right at any time to modify the Service (or any part thereof). H-E-B may revise this Agreement from time to time. H-E-B will notify Supplier of any such changes or modifications by emailing Supplier at the address provided during registration. Any access to or use of this Site or Service following the notice of such changes or modifications will constitute Supplier’s acceptance of the revised Agreement. If Supplier does not accept the revised Agreement, it may terminate the Agreement by providing written notice to H-E-B and immediately ceasing all access and use of this Site and Service. \n' +
    '1.5 Transmission of Data. Supplier understands that the technical processing and transmission of Supplier Data is necessary to Supplier’s use of the Service. Supplier consents to H-E-B’s collection and storage of Supplier Data, and Supplier acknowledges that submitting or otherwise providing Supplier Data to this Site or Service will involve transmission of such data over the Internet and other networks that are not owned or operated by H-E-B. Supplier acknowledges and agrees that changes to Supplier Data may occur in order to conform and adapt such data to the technical requirements of connecting networks or devices. Supplier further acknowledges and agrees that Supplier Data may be accessed by unauthorized parties when communicated across the Internet, network communications facilities, telephone, or other electronic means. Supplier agrees that H-E-B is not responsible for any Supplier Data which is delayed, lost, altered, corrupted, intercepted or stored without authorization during the transmission of any such data whatsoever across any network, including without limitation the Internet. \n' +
    '1.6 Prohibited Data.  Supplier acknowledges and agrees that, unless otherwise (i) mutually agreed by the parties in a signed writing or (ii) expressly requested by H-E-B in writing or via the Site or Service, neither Supplier nor its Authorized Users shall upload, submit or otherwise transmit any content or information which is in the nature of “sensitive personal data;” such data includes but is not limited to social security number or other government identification, information pertaining to race or ethnic origin, political opinions, religious or other similar beliefs, trade union membership or physical or mental health or condition, sex life, or criminal history of individuals.  H-E-B shall not be liable for any loss, theft, destruction, or use by a third party of any sensitive personal data.\n' +
    '1.7 Prohibited Conduct.  Supplier will not violate any applicable laws, contract, intellectual property right or other third party right, and Supplier is solely responsible for its and its Authorized Users’ conduct while using the Site and Services.  Supplier further agrees that it will not (and it will not allow its Authorized Users or any other person to):\n' +
    '•\tSell, resell or commercially use H-E-B’s Site or Services;\n' +
    '•\tCopy (except as permitted in Section 11.2), reproduce, distribute, publicly perform or publicly display all or portions of our Services, except as expressly permitted by us or our licensors;\n' +
    '•\tModify the Site or Services, remove any proprietary rights notices or markings, or otherwise make any derivative works based upon the Site or Services;\n' +
    '•\tUse the Site or Services other than for their intended purpose and in any manner that could interfere with, disrupt, negatively affect or inhibit other users from fully enjoying the Site or Services or that could damage, disable, overburden or impair the functioning of the Site or Services in any manner;\n' +
    '•\tReverse engineer any aspect of the Site or Services or do anything that might discover source code or bypass or circumvent measures employed to prevent or limit access to any part of the Site or Services;\n' +
    '•\tUse any data mining, robots or similar data gathering or extraction methods designed to scrape or extract data from the Site or Services;\n' +
    '•\tDevelop or use any applications that interact with the Site or Services without H-E-B’s prior written consent; or\n' +
    '•\tUse the Site or Services for any illegal or unauthorized purpose, or engage in, encourage or promote any activity that violates the terms of this Agreement.\n' +
    '1.8 Trademark Information. H-E-B service marks, logos and product and service names (the “H-E-B Marks”) are the exclusive property of H-E-B. Supplier shall not display or use the H-E-B Marks in any manner without H-E-B’s express prior written permission.\n' +
    '1.9 Applications and Services. Other applications developed by H-E-B as well as third-party applications and services may be made available via the Site or Service. It is Supplier’s responsibility to review any and all terms and conditions issued with or otherwise applicable to any and all applications and services made available via the Site or Service. Any agreement or terms and conditions issued by a third party with respect to its application or service is an agreement between Supplier and such third party, not H-E-B. H-E-B is not responsible for any (i) breach of third-party terms and conditions by such third parties, nor (ii) claims arising from the actions or omissions of such third parties. Supplier accesses and otherwise uses such third-party applications and services at Supplier’s sole risk.\n' +
    '2.\tNO COMMITMENT TO PURCHASE PRODUCTS. \n' +
    'Supplier acknowledges that neither this Agreement, nor any aspect of the Site or the Service constitute any agreement by H-E-B to purchase any Product. Supplier agrees that H-E-B may only commit to purchasing Products pursuant to a separate written agreement signed by a vice-president or higher officer of H-E-B. Supplier is solely responsible for any costs it incurs in using the Site or Service or developing Products. \n' +
    '3.\tLICENSES.\n' +
    '3.1 License Grant. Subject to the terms of this Agreement, H-E-B grants to Supplier a non-exclusive, non-refundable, non-transferable right to permit Authorized Users to access and make use of the Site and Service solely (i) in the manner expressly authorized by this Agreement or any currently available User Help Guide, (ii) in accordance with any technical specifications provided by H-E-B to Supplier from time to time under this Agreement, and (iii) solely to track the life cycle of Products (the “License”). H-E-B retains all rights not expressly granted herein. \n' +
    '3.2 Supplier and its Authorized Users shall only access the Site and Service solely for purposes of processing Supplier’s own data and to track Products for the benefit of H-E-B. \n' +
    '3.3 Supplier grants to H-E-B the right to process the Supplier Data submitted by Supplier to the Site or Service for the purpose of performance of this Agreement. Supplier grants to H-E-B and its sublicensees and assigns a perpetual, royalty-free, non-exclusive, worldwide, transferable and sublicensable license to all Supplier Data. Supplier represents and warrants that all Supplier Data: (i) is and will be fit for display or use on product packaging, H-E-B’s proprietary eCommerce websites, advertising and marketing materials and any other ways required by H-E-B; (ii) does not and will not infringe on any third party‘s Intellectual Property Rights or right of publicity or privacy; (iii) does not and will not violate any applicable law, statute or regulation including without limitation laws regarding unfair competition, anti-discrimination or false advertising; (iv) is not and will not be defamatory or libellous; (v) does not and will not contain any virus; and (v) is true, accurate and complete, Trojan horse, worm, time bomb, cancelbot or other similar harmful or deleterious computer programming routine. Additionally, Supplier represents and warrants that it (a) has all rights to Supplier Data or other material it submits to the Site or Service necessary to comply with this Agreement and grant the rights and licenses herein granted; (b) will comply with all laws applicable to its use of the Site and Service; and (c) Supplier is not violating the rights of any third parties by uploading or otherwise submitting to the Site or Service the Supplier Data and other material it submits to the Site or Service. Supplier undertakes to maintain the Supplier Data uploaded to the Site or Service as true, accurate and complete at all times.\n' +
    '4.\tTERM. \n' +
    'This Agreement begins on the date that any Supplier representative (including without limitation any Authorized User(s)) electronically accepts this Agreement (“Effective Date”). Both the Agreement and License will commence on the Effective Date and will continue until terminated by either party pursuant to this Agreement. \n' +
    '5.\tTERMINATION. \n' +
    '5.1 Termination for Cause. Either party may terminate this Agreement (and the License) at any time immediately upon notice to the other if the other: \n' +
    '5.1.1 Commits a material breach of this Agreement which it fails to remedy within fourteen (14) days of receiving notice requiring it to do so; \n' +
    '5.1.2 sells all or substantially all of its assets, participates in a merger, or other reorganization in which it is not the surviving entity; or \n' +
    '5.1.3 ceases to do business, becomes unable to pay its debts when they fall due, becomes or is deemed insolvent, has a receiver, manager, administrator, administrative receiver or similar officer appointed in respect of the whole of any part of its assets or business, makes any composition or arrangement with its creditors, takes or suffers any similar action in consequence of debt, an order or resolution is made for its dissolution or liquidation (other than for the purpose of solvent amalgamation or reconstruction) or enters into liquidation whether compulsorily or voluntarily. \n' +
    '5.2 Termination for Convenience. H-E-B may terminate this Agreement (and the License) for convenience immediately upon written notice to Supplier (including via notice to any of Supplier’s Authorized Users). Supplier may terminate this Agreement for convenience by providing written notice to H-E-B in a manner that makes H-E-B aware that H-E-B may at such time revoke Supplier’s and its Authorized Users access to the Site and Service and delete the Supplier Data.  \n' +
    '5.3 Automatic Termination. This Agreement (and the License) shall immediately terminate in the event that Supplier ceases to be a supplier to H-E-B. \n' +
    '5.4 Survival of Rights. Termination shall be without prejudice to the rights of either party that have accrued prior to termination. \n' +
    '5.5 Effect of Termination. Upon termination for any reason: \n' +
    '5.5.1 all rights including the License granted to Supplier under this Agreement shall cease; \n' +
    '5.5.2 the Supplier shall cease all activities authorized by this Agreement; \n' +
    '5.5.3 H-E-B may delete all Supplier Data from the Site and Service. \n' +
    '6.\tELECTRONIC DATA FORMS. \n' +
    '6.1 Data Accuracy and Back-Up. Supplier shall be responsible for the accuracy, quality, legality, submission in a suitable form and keeping of any backup copies of all Supplier Data that it submits to the Site. \n' +
    '6.2 Right to Remove Data. H-E-B may remove part of or all of Supplier Data submitted to the Site or Service by the Supplier where, in H-E-B’s sole discretion, such Supplier Data submitted to the Site or Service may result in liability on the part of H-E-B or a third party. \n' +
    '6.3 Suspension. H-E-B may, at its sole discretion and without notice, immediately suspend Supplier’s rights to access and make use of the Site or Service under this Agreement where, in H-E-B’s sole discretion, (i) any Supplier Data submitted to the Site or Service by Supplier may result in liability on the part of H-E-B or a third party; (ii) any Supplier or Authorized User act or omission may adversely impact the Site or Service or constitute a breach of this Agreement. \n' +
    '7.\tCONFIDENTIALITY. \n' +
    '7.1 Confidential Information. Subject to any non-disclosure agreement entered into by the parties with respect to the subject matter hereof, the Receiving Party shall maintain the confidentiality of the Disclosing Party’s Confidential Information and shall not, without the prior written consent of the Disclosing Party, use, disclose, copy or modify the Disclosing Party’s Confidential Information (or permit others to do so) other than as necessary for the exercise and performance of the Receiving Party’s rights and obligations, respectively, under this Agreement. Nothing contained in this Agreement shall be construed as a grant by a Disclosing Party of any right or license to the Receiving Party, expressed or implied, in or to any of the Confidential Information of such Disclosing Party. The Confidential Information of a Disclosing Party shall remain the sole property of such Disclosing Party. \n' +
    '7.2 Notice. Each party shall give notice to the other of any unauthorized misuse, disclosure, theft or other loss of the other party’s Confidential Information immediately upon becoming aware of the same. \n' +
    '7.3 Exclusions. The provisions of this Section 7 shall not apply to information which: \n' +
    '7.3.1 is in or comes into the public domain through no fault of the Receiving Party, its officers, employees, agents or contractors; \n' +
    '7.3.2 is lawfully received by the Receiving Party from a third party free of any obligation of confidence at the time of its disclosure; \n' +
    '7.3.3 is independently developed by the Receiving Party, its officers, employees, agents or contractors; or \n' +
    '7.3.4 is required by law, by court or governmental order to be disclosed. \n' +
    '7.4 Survival. The obligations under this Section 7 shall survive the variation, expiry or termination of this Agreement. \n' +
    '7.5 Equitable Relief. Each party acknowledges that the restrictions set forth herein are fair and reasonable and are necessary in order to protect the business of the other party and the confidential nature of the Confidential Information of the other party. Each party further acknowledges that the Confidential Information of the other party is unique and valuable to the business of the other party and would not be revealed to it were it not for its willingness to agree to the restrictions set forth herein. The parties agree that a breach of this Agreement may cause irreparable harm and result in significant damages to the other party and that the harm and the damages may be difficult to ascertain. Therefore, the parties agree that in addition to any other remedies they may have at law and in equity, they may have the right to seek an immediate injunction enjoining any breach of this Agreement. \n' +
    '8.\tWARRANTY DISCLAIMER. \n' +
    'THE SITE AND SERVICE ARE PROVIDED “AS IS”. H-E-B EXPRESSLY DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTIES RELATED TO THE SITE OR THE SERVICE, INCLUDING, BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. H-E-B DOES NOT WARRANT THAT THE SITE OR THE SERVICE WILL BE UNINTERRUPTED OR ERROR FREE OR WILL MEET SUPPLIER’S REQUIREMENTS. \n' +
    '9.\tLIMITATION OF LIABILITIES. \n' +
    '9.1 Limitation of Liability. Notwithstanding anything to the contrary, in no event will H-E-B’s entire liability arising out of or in connection with this Agreement, the Site or the Service, whether in contract, tort or otherwise, and whether for direct, indirect or consequential loss or damage, exceed $5,000.\n' +
    '9.2 Disclaimer of Consequential Damages. In no event shall H-E-B be liable, whether in contract tort or otherwise, for any indirect, special, exemplary, punitive or consequential loss or damage, howsoever arising and of whatsoever nature suffered or incurred by Supplier or any third party, including loss of revenue, loss of profits, loss of contracts, loss of goodwill, loss or corruption of data, loss of operation time, or loss of use of any equipment or process suffered either directly or indirectly by Supplier or any third party, or any loss of anticipated earnings or savings, regardless of whether H-E-B has been advised of the possibility of such loss. \n' +
    '10.\tINDEMNITY. \n' +
    '11.\tSupplier shall indemnify, defend and hold harmless H-E-B from and against all claims, liabilities, proceedings, costs, damages, losses, or expenses including reasonable attorneys fees (collectively, “Losses”) incurred by H-E-B and its assigns caused by, or in any way connected with: (i) any use of the Site or Service by or on behalf of Supplier (regardless of whether the use giving rise to the Losses is by an Authorized User), (ii) Supplier’s breach of this Agreement, (iii) any negligent or wrongful act of the Supplier or its Authorized Users, or (iv) any claim that any Supplier Data or the upload or submission to the Site or Service of any Supplier Data infringes or misappropriates the Intellectual Property Rights of a third party. INTELLECTUAL PROPERTY RIGHTS. \n' +
    '11.1 Ownership of the Service and Site. Unless explicitly provided otherwise in this Agreement, all rights in and to the Service or the Site’s design, text, graphics, their selection and arrangement, software, generated HTML pages and all other material on or in this Site (the “Content”) (including all Intellectual Property Rights contained in such Content) are owned and reserved by H-E-B or its licensors. Any use of such Intellectual Property Rights or software or any reproduction of the Content is strictly prohibited except where expressly allowed under this Agreement.\n' +
    '11.2 Ownership of Information.  All information stored in, transmitted to or received by any H-E-B network or computer system used herein is or becomes the property of H-E-B, excluding Supplier Data. There is no right of privacy with respect to the use of these H-E-B networks or computer systems. \n' +
    '11.2 Permission to Copy. Permission is hereby granted to Supplier to copy and store electronically and print individual pages of the Content on paper from the Site only for the purpose of making use of the Service. Supplier may also permit its computer to make an electronically stored, transient copy of the Content for the purpose of viewing it. However, Supplier may only make one copy of the Content. Any other copying or use of the Content, including reproduction for purposes other than that hereby authorized, including without limitation the removal of copyright or trademark notices, the creation of an electronic or manual database by systematically downloading and storing the Content, distribution (whether electronically or not) or republication, by Supplier, is prohibited. For the avoidance of doubt, any permission granted under this Agreement or otherwise does not transfer ownership to Supplier or confer any other right on Supplier in the whole or any part of the Content. \n' +
    '11.2 Publicity. Supplier shall allow H-E-B, for the term of this Agreement, to make use of Supplier’s name for the purpose of marketing-related activities. Supplier shall promote the Site and H-E-B’s services as may be agreed between the parties from time to time. \n' +
    '12.\tNOTICES. \n' +
    'Any notices and other communications required or permitted to be given under this Agreement shall be in writing and shall be delivered or transmitted to the intended recipient’s address. For Supplier, such address shall be the information provided during the registration process. For H-E-B, the address for notice is: 646 South Flores Street, San Antonio, Texas 78204, Attention: Corporate Law. Any notice shall be treated as having been served on delivery if delivered by hand, four Business Days after posting if sent by pre-paid first-class post and on completion of transmission if sent by facsimile or email. \n' +
    '13.\tFORCE MAJEURE. \n' +
    'Neither party to this Agreement shall be liable for failure to perform any of its obligations under this Agreement if such failure is caused by an event outside of its reasonable control which shall include but not be limited to Act of God, war, natural disaster, fire, flood, explosion earthquake, epidemic or pandemic (‘an event of force majeure’). \n' +
    '14.\tSEVERABILITY; NO WAIVER. \n' +
    '14.1 Severability. The invalidity or unenforceability of any term of, or any right arising pursuant to, this Agreement shall not affect the validity or enforceability of the remaining terms or rights. \n' +
    '14.2 Waiver. No waiver, alteration or modification of any provision herein shall be binding upon either party unless made in writing and agreed to by a duly authorized officer of the party sought to be bound. Waiver by either party of any default hereunder shall not be deemed a waiver by such party of any default by either party which may thereafter occur.\n' +
    '15.\tASSIGNMENT AND SUBLICENSING. \n' +
    'Supplier may not assign, sub-license, sub-contract, mortgage or otherwise transfer or dispose of this Agreement or any of its rights or obligations under it (including, without limitation, for facilities management or outsourcing purposes) without the prior written consent of H-E-B. \n' +
    '16.\tSURVIVAL. \n' +
    'For the avoidance of doubt, any provisions of this Agreement which are either expressed so as to survive termination of this Agreement, or by their nature and context should so survive such termination, shall remain in force and effect notwithstanding such termination. \n' +
    '17.\tLAW. \n' +
    'This Agreement shall be governed by and construed in accordance with the laws of the State of Texas and the parties hereto submit to the non-exclusive jurisdiction of the courts in Bexar County, Texas with respect to all disputes relating to this Agreement. \n' +
    '18.\tENTIRE AGREEMENT. \n' +
    'This Agreement contains all the terms agreed between the parties regarding its subject matter and supersedes any prior agreement, understanding or arrangement between them whether oral or in writing. Notwithstanding the foregoing, the confidentiality obligations under this Agreement are in addition to and do not replace confidentiality provisions in any other agreements executed by the parties. This Agreement may only be amended as described in Section 1.4, or via a written amendment signed by both parties. Supplier shall have no remedy in respect of any untrue statement made to it upon which it relied in entering into this Agreement (unless such untrue statement was made knowing that it was untrue) other than any remedy it may have for breach of the express terms. \n' +
    '19.\tINDEPENDENT CONTRACTORS. \n' +
    'The relationship of the parties is that of independent contractors dealing at arm’s length and except as expressly provided in this Agreement. Nothing in this Agreement shall be construed so as to constitute the parties as partners, joint ventures or co-owners or empower either party to act for, bind or otherwise create or assume any obligation on behalf of the other and neither party shall hold itself out as entitled to do the same. Nothing in this Agreement shall create or be deemed to create the relationship of employer and employee. \n' +
    '20.\tDEFINITIONS. \n' +
    'Within this Agreement, the following terms shall have the following meanings unless the context indicates otherwise: \n' +
    '20.1 “Authorized User(s)” means Supplier’s employees, agents, representatives or contractors whom Supplier permits to access the Site or Service on Supplier’s behalf, provided that such users may not be employed by or an agent of any entity which competes with H-E-B. \n' +
    '20.2 “Business Day” means Monday to Friday excluding public holidays in the US. \n' +
    '20.3 “Confidential Information” means in relation to either party, information (whether in oral, written or electronic form) furnished or made available in connection with this Agreement by or on the behalf of a party (the “Disclosing Party”) to the other party (the “Receiving Party”) and belonging or relating to that Disclosing Party, its business affairs or activities which is not in the public domain and which: (i) the Disclosing Party has marked as confidential or proprietary, (ii) the Disclosing Party has, orally or in writing, advised the Receiving Party is of a confidential nature, or (iii) due to its character or nature, a reasonable person in a like position and under like circumstances would treat as confidential. The Content shall constitute the Confidential Information of H-E-B whether or not it is marked. \n' +
    '20.4 “Intellectual Property Rights” means all rights in trademarks and trade names, and all patents, rights in inventions, design rights, copyrights (including but not limited to rights in computer programs), database rights, confidential data, know-how and all other intellectual property rights of any nature whatsoever throughout the world, whether registered or unregistered and including all applications and rights to apply for any of the same. \n' +
    '20.5 “Product” means a product which is manufactured for or distributed by Supplier to H-E-B. \n' +
    '20.6 “Supplier Data” means that information, data, images, content and property of any kind uploaded or contributed by Supplier into  the Site or Service. Mere submission of data or other content does not establish ownership of such content or data by Supplier.\n' +
    '20.7 “You” means the individual entering into this Agreement on behalf of Supplier. \n';

  public static ITEM_WEIGHT_TYPE_NONE = 'None';
  public static ITEM_WEIGHT_TYPE_FIXED = 'Fixed Weight';
  public SHOPPABLE_FROM_ONE_SIDE_ONLY = 'Shoppable from only one side';
  public STOCK_FACING_BY_DEPTH = 'stock_facing_by_depth';
  public SHOPPABLE_FROM_ATLEAST_TWO_SIDES = 'Shoppable from at least 2 adjacent sides';
  public STOCK_FACING_BY_DEPTH_OR_FACING = 'stock_facing_by_depth_or_facing';
  public RETAIL_READY_PACKAGING = 'retail_ready_packaging';
  public DISPLAY_READY_PALLETS = 'display_ready_pallets';
  public static BOTH_ITEM_TYPE = 'BOTH';
  public static DSD_ITEM_TYPE = 'DSD';
  public static WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public static readonly OMI_TOTAL_RETAIL_UNITS_MAX = 999;
  public static readonly PENDING_ID: number = 999_999_999;
  public static readonly PENDING_ID_STRING = '999999999';
  public IMAGE_BASE_URL = 'https://images.heb.com/is/image/HEBGrocery/';

  /**
   * Returns the sales channel
   * @param candidate
   */
  getChannel(candidate: Candidate): string {
    if (candidate.dsdSwitch && candidate.warehouseSwitch) {
      return 'Both';
    } else if (candidate.dsdSwitch) {
      return 'DSD';
    } else {
      return 'Warehouse';
    }
  }

  /**
   * Returns item weight type. "None" is the only one that changes/
   * @param candidate
   */
  getItemWeightType(candidate: Candidate) {
    switch (candidate.itemWeightType) {
      case CandidateUtilService.ITEM_WEIGHT_TYPE_NONE: {
        return CandidateUtilService.ITEM_WEIGHT_TYPE_FIXED;
      }
      default: {
        return candidate.itemWeightType;
      }
    }
  }
  /**
   * Returns the number of days as a string. If empty, it returns 0 Days.
   * @param candidate
   * @param days
   */
  getDaysString(candidate: Candidate, days) {
    if (candidate.codeDate) {
      if (days) {
        return '' + days + ' Days';
      }
      return '0 Days';
    } else {
      return '';
    }
  }

  /**
   * Returns the number of days as a string. If empty, it returns 0 Days.
   * @param days
   */
  getDaysStringFromValue(days) {
    if (days) {
      return '' + days + ' Days';
    }
    return '0 Days';
  }


  /**
   * Returns the DRU orientation string.
   * @param candidate
   */
  getDRUOrientation(candidate: Candidate): string {
    switch (candidate.displayReadyUnitOrientation) {
      case this.STOCK_FACING_BY_DEPTH: {
        return this.SHOPPABLE_FROM_ONE_SIDE_ONLY;
      }
      case this.STOCK_FACING_BY_DEPTH_OR_FACING: {
        return this.SHOPPABLE_FROM_ATLEAST_TWO_SIDES;
      }
      default: {
        return '';
      }
    }
  }

  /**
   * Checks if a string is empty or just spaces
   */
  isEmptyOrSpaces(str) {
    return typeof str === 'undefined' || !str  || str.match(/^ *$/) !== null;
  }
  /**
   * Returns the 'Receipt: ' + receipt value.
   * @param productDesc
   */
  getReceipt(productDesc): string {
    return 'Receipt: ' + this.getReceiptString(productDesc);
  }

  /**
   * Returns the first 25 characters of the product description capitalized.
   * @param productDesc
   */
  getReceiptString(productDesc): string {
    if (productDesc) {
      if (productDesc.length > 25) {
        return productDesc.substring(0, 25).toUpperCase();
      } else {
        return productDesc.substring(0, productDesc.length).toUpperCase();
      }
    }
    return '';
  }

  /**
   * converts length, width, and height in inches to cubic feet
   * @param length
   * @param width
   * @param height
   * @param decimalCount
   */
  getCubicFeet(length, width, height, decimalCount) {
    // 1728 is the divider to calculate in cubic feet from inches.
    const vol = (length * width * height) / 1728;
    if (vol > 0) {
      return parseFloat(
        this.decimalPipe.transform(vol, '1.0-5' + decimalCount + '').replace(/,\s?/g, '')
      ).toFixed(4);
    } else {
      return 0;
    }
  }

  isSellable(candidate) {
    return candidate?.productType === Candidate.SELLABLE;
  }

  isRetailLink(candidate: Candidate): boolean {
    return candidate && candidate.retailType === Candidate.RETAIL_LINK;
  }

  isPriceRequired(candidate: Candidate): boolean {
    return candidate && candidate.retailType === Candidate.PRICE_REQUIRED;
  }

  isKeyRetail(candidate: Candidate): boolean {
    return candidate && candidate.retailType === Candidate.KEY_RETAIL;
  }

  static getUIStatusFromCandidateStatus(status: String) {
    if (!status) {
      return '';
    }
    if (status === Candidate.COMPLETED) {
      return 'Pending Activation';
    } else if (status === Candidate.DECLINED) {
      return 'Rejected';
    } else if (status === Candidate.ACTIVATED) {
      return 'Activated';
    } else if (status === Candidate.FAILED) {
      return 'Activation Failed';
    } else if (status === Candidate.IN_PROGRESS) {
      return 'Pending Review';
    } else if (status === Candidate.DELETED) {
      return 'Deleted';
    } else if (status === Candidate.APPROVED) {
      return 'Approved - awaiting MRT activation';
    } else if (status === Candidate.PARTIAL_FAILURE) {
      return 'Partial Failure';
    } else {
      return 'Unknown (' + status + ')';
    }
  }

  /**
   * Returns the candidate item type.
   */
  static getItemType(candidate: Candidate): string {
    if (!candidate) {
      return null;
    }
    if (candidate.warehouseSwitch && candidate.dsdSwitch) {
      return this.BOTH_ITEM_TYPE;
    } else if (candidate.warehouseSwitch) {
      return this.WAREHOUSE_ITEM_TYPE;
    } else if (candidate.dsdSwitch) {
      return this.DSD_ITEM_TYPE;
    } else {
      return null;
    }
  }

  /**
   * Returns whether a buyer is tied to departments that are variable or catch weight departments (2, 6, 9, 23).
   */
  public isTiedToCatchOrVariableWeightBuyer(candidate): boolean {
    if (candidate?.commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(candidate.commodity.departmentId);
    } else {
      return false;
    }
  }

  /**
   * Returns product dimensions string.
   * @param candidate the candidate.
   */
  public getProductDimensionsString(candidate: Candidate) {
    if (!candidate) {
      return;
    }
    return ''.concat(candidate.productLength ? candidate.productLength : 0)
      .concat('in x ')
      .concat(candidate.productWidth ? candidate.productWidth : 0)
      .concat('in x ')
      .concat(candidate.productHeight ? candidate.productHeight : 0)
      .concat('in');
  }

  /**
   * Returns a user friendly formatted weight string for the provided value.
   * @param weight
   */
  getWeightString(weight) {
    if (!weight) {
      return '0lbs';
    } else {
      return weight + 'lbs';
    }
  }

  /**
   * Returns a user friendly formatted weight string for the provided value.
   * @param weight
   */
  getProductWeightString(weight) {
    if (!weight) {
      return '0oz';
    } else {
      return weight + 'oz';
    }
  }

  /**
   * Return a user friendly formatted  master dimensions string.
   * @param candidate
   */
  getMasterDimensions(candidate: Candidate) {
    if (!candidate) {
      return '';
    }
    const masterLength = candidate.masterLength ? candidate.masterLength : '0';
    const masterWidth = candidate.masterWidth ? candidate.masterWidth : '0';
    const masterHeight = candidate.masterHeight ? candidate.masterHeight : '0';
    return masterLength + 'in x ' + masterWidth + 'in x ' + masterHeight + 'in';
  }

  /**
   * Return a user friendly formatted  inner dimensions string.
   * @param candidate
   */
  getInnerDimensions(candidate: Candidate) {
    const innerLength = candidate.innerLength ? candidate.innerLength : '0';
    const innerWidth = candidate.innerWidth ? candidate.innerWidth : '0';
    const innerHeight = candidate.innerHeight ? candidate.innerHeight : '0';
    return innerLength + 'in x' + innerWidth + 'in x ' + innerHeight + 'in';
  }

  /**
   * Returns user friendly DRU type.
   * @param candidate
   */
  getDruType(candidate: Candidate): string {
    switch (candidate.displayReadyUnitType) {
      case this.RETAIL_READY_PACKAGING: {
        return 'Retail Ready packaging';
      }
      case this.DISPLAY_READY_PALLETS: {
        return 'Display Ready Pallets';
      }
      default: {
        return '';
      }
    }
  }
  /**
   * Returns the candidate product with data;
   * @param candidate
   */
  public static getCurrentCandidateProduct(candidate: Candidate): CandidateProduct {
    if (!candidate) {
      return null;
    } else {
      return candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(candidate)];
    }
  }

  /**
   * Returns the candidate product index with data;
   * @param candidate
   */
  public static getCurrentCandidateProductIndex(candidate: Candidate): number {
    if (!candidate) {
      return 0;
    }
    switch (candidate.candidateType) {
      case Candidate.NEW_PRODUCT:
      case Candidate.MRT:
      case Candidate.MRT_INNER:
      case Candidate.PLU:
      case Candidate.ADDITIONAL_DISTRIBUTOR:
      case Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR: {
        return 0;
      }
      case Candidate.ADDITIONAL_CASE_PACK:
      case Candidate.BONUS_SIZE:
      case Candidate.REPLACEMENT_UPC:
      case Candidate.ASSOCIATE_UPC: {
        return 1;
      }

      default: {
        return 0;
      }
    }
  }


  /**
   * Whether or not the candidates is a scale or checker and scale product.
   * @param candidate the caniddate.
   */
  isPlu(candidate: Candidate) {
    return candidate?.candidateProducts[0]?.candidateProductType &&
      candidate.candidateProducts[0].candidateProductType === CandidateProduct.PLU;
  }
  /**
   * Whether or not the candidates is a scale or checker and scale product.
   * @param candidate the caniddate.
   */
  isScaleProduct(candidate: Candidate) {
    if (!this.isPlu(candidate)) {
      return false;
    }

    return candidate.candidateProducts[0] && (+candidate.candidateProducts[0].pluType === UpcRequestType.CHECKER_AND_SCALE ||
      +candidate.candidateProducts[0].pluType === UpcRequestType.SCALE);
  }
  /**
   * Whether or not the candidates is a scale or checker and scale product.
   * @param candidate the caniddate.
   */
  isCheckerProduct(candidate: Candidate) {
    if (!this.isPlu(candidate)) {
      return false;
    }

    return candidate.candidateProducts[0] && (+candidate.candidateProducts[0].pluType === UpcRequestType.CHECKER ||
      +candidate.candidateProducts[0].pluType === UpcRequestType.CHECKER_AND_SCALE);
  }

  /**
   * Calculates the MRT margin percent.
   * @param pennyProfit the penny profit.
   * @param masterSuggestedRetail the masterSuggestedRetail.
   */
  getMRTMarginPercent(pennyProfit, masterSuggestedRetail) {
    return 100 * (pennyProfit / masterSuggestedRetail);
  }

  /**
   * Calculates the MRT penny profit.
   * @param masterSuggestedRetail the masterSuggestedRetail.
   * @param masterListCost the master list cost.
   */
  getMRTPennyProfit(masterSuggestedRetail, masterListCost) {
    return masterSuggestedRetail - masterListCost;
  }

  /**
   * Calculates the MRT master Suggested Retail.
   * @param mrtExistingInners the existing inners.
   * @param mrtCandidateInners the candidate inners.
   */
  getMRTMasterSuggestedRetail(mrtExistingInners: ExistingInner[], mrtCandidateInners: CandidateInner[]) {
    let masterSuggestedRetail = 0;

    if (mrtExistingInners && mrtExistingInners.length > 0) {
      for (const mrtExistingInner of mrtExistingInners) {
        let existingInnerItems;

        if (!!mrtExistingInner.product?.items?.length) {
          existingInnerItems = mrtExistingInner.product.items;
        } else if (!!mrtExistingInner.product?.dsdItems?.length) {
          existingInnerItems = mrtExistingInner.product.dsdItems;
        }
        if (!existingInnerItems) {
          continue;
        }
        for (const existingInnerItem of existingInnerItems) {
          if (existingInnerItem.mrt || !existingInnerItem.containedUpc) {
            continue;
          }
          // find the item containing the upc and set the retail of the primary.
          if ((existingInnerItem.containedUpc.upc.scanCodeId === mrtExistingInner.upc) ||
            (!!existingInnerItem.innerUpcs?.find(inner => inner.upc === mrtExistingInner.upc)) ||
            (!!existingInnerItem.containedUpc.associatedUpcs?.find(upc => upc === mrtExistingInner.upc))) {
            masterSuggestedRetail = masterSuggestedRetail + (existingInnerItem.containedUpc.upc.retailPrice * mrtExistingInner.quantity);
            break;
          }
        }
      }
    }
    if (mrtCandidateInners && mrtCandidateInners.length > 0) {
      for (let x = 0; x < mrtCandidateInners.length; x++) {
        masterSuggestedRetail = masterSuggestedRetail +
          ((mrtCandidateInners[x].candidate.retailPrice / mrtCandidateInners[x].candidate.retailXFor) *
            mrtCandidateInners[x].quantity);
      }
    }
    return masterSuggestedRetail;
  }

  /**
   * Gets the array of cost owners.
   * @param brandId
   */
  getCostOwners(brandId): Observable<any> {
    return this.lookupService.findBrandsById(brandId).pipe(
      switchMap((data) => {
        for (let index = 0; index < data.length; index++) {
          if (brandId === data[index].brandId) {
            return of(data[index].costOwners);
          }
        }
        return of([]);
      }));
  }

  /**
   * Returns a task id from the task, and task id (may not be present). If neither are present, returns null.
   * @param taskId taskId.
   * @param task the task.
   */
  public static getTaskIdFromTaskAndTaskId(taskId, task: Task) {
    return taskId ? taskId : task && task.id ? task.id : null;
  }

  /**
   * Whether or not to refetch candidate by task params.
   *
   * If there's not a params task id, you cannot fetch by task id.
   * If there's a params task id, and not a current service id or they don't match, refetch the candidate.
   * @param params the params map.
   * @param currentServiceTaskId the current service task id.
   */
  public shouldRefetchCandidateByTaskParams(params: ParamMap, currentServiceTaskId): boolean {

    // if there's not a params task id, you cannot fetch by task id.
    if (!params.has('taskId')) {
      return false;
    }
    // if there's a params task id, and not a current service id or these don't match, refetch the candidate.
    return !currentServiceTaskId || params.get('taskId') !== currentServiceTaskId;
  }

  /**
   * Returns an observable boolean of whether or not there's valid candidate and task data.
   * If no candidate, candidate id, or task id, return false.
   * If the task doesn't exist for user, returns false (error state).
   * If the task ids don't match, returns false.
   * Else returns true.
   *
   * @param taskId
   * @param candidate
   */
  public isValidCandidateAndTaskData(taskId, candidate: Candidate): Observable<boolean> {

    if (!taskId || !candidate || !candidate.candidateId) {
      return of(false);
    }

    return this.workflowService.getTaskByCandidateIdWithVariables(candidate.candidateId).pipe(
      concatMap((task) => {
        // if the task found isn't the same as the current task then reset the data, else cont
        if (+task.id !== +taskId) {
          return of(false);
        } else {
          return of(true);
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
  /**
   * Returns an observable boolean of whether or not there's valid candidate and task data.
   * If no task id, candidate, inner candidate, candidate id, inner candidate id, or the candidate doesn't belong to
   * the inner candidate, return false.
   * If the task doesn't exist for user, returns false (error state).
   * If the task ids don't match, returns false.
   * Else returns true.
   *
   * @param taskId
   * @param candidate
   * @param innerCandidate
   */
  public isValidMrtCandidateInnerAndTaskData(taskId, candidate: Candidate, innerCandidate: Candidate): Observable<boolean> {

    if (!taskId || !candidate || !candidate.candidateId || !innerCandidate || !candidate.mrtInfo || !candidate.mrtInfo.candidateInners ||
      !candidate.mrtInfo.candidateInners.find((inner) => inner.candidateId === +innerCandidate.candidateId)) {
      return of(false);
    }

    return this.workflowService.getTaskByCandidateIdWithVariables(candidate.candidateId).pipe(
      concatMap((task) => {
        // if the task found isn't the same as the current task then reset the data, else cont
        if (+task.id !== +taskId) {
          return of(false);
        } else {
          return of(true);
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * Returns if the params aren't provided, e.g. no task or candidate ids.
   * @param params
   */
  public static isMissingInnerCandidateParams(params: ParamMap) {
    return params.keys.length === 0 || !params.has('taskId') || !params.has('candidateId');
  }

  /**
   * Returns whether or not the MRT and candidate should be refetched. If the task Ids don't match, or there's no current candidate inner
   * id, or the candidate id doesn't match the current candidate id.
   * @param params
   * @param currentServiceTaskId
   * @param currentInnerCandidateId
   */
  public static shouldRefetchMrtAndInnerCandidate(params: ParamMap, currentServiceTaskId, currentInnerCandidateId) {
    return +params.get('taskId') !== +currentServiceTaskId || !currentInnerCandidateId ||
      +params.get('candidateId') !== currentInnerCandidateId;
  }

  /**
   * Returns whether or not the candidate is an mrt and contains the inner candidate id.
   * @param candidate
   * @param innerCandidateId
   */
  public static isCandidateAnMrtAndContainsInner(candidate: Candidate, innerCandidateId) {
    return candidate.candidateType === Candidate.MRT && candidate.mrtInfo && candidate.mrtInfo.candidateInners.find(
      (inner) => inner.candidateId === innerCandidateId);
  }

  /**
   * Sets the vertex tax category on a candidate. Returns new object to trigger change detection.
   * @param candidate candidate.
   */
  setVertexTaxCategoryModel(candidate: Candidate | FreightTaxUpcRequest): Observable<any> {
    const vertexId = CandidateHelper.getVertexTaxCategoryNumber(candidate);
    if (!vertexId) {
      candidate.vertexTaxCategory = null;
      candidate.vertexTaxCategoryModel = null;
      // trigger change detection for vertex tax category.
      return of(JSON.parse(JSON.stringify(candidate)));
    } else {
      return this.lookupService.findVertexTaxCategoryById(vertexId).pipe(
        map((vertexCategory) => {
          candidate.vertexTaxCategoryModel = vertexCategory;
          candidate.vertexTaxCategory = null;
          // trigger change detection for vertex tax category.
          return JSON.parse(JSON.stringify(candidate));
        })
      );
    }
  }

  /**
   * Returns the product image url for a product.
   * @param productData
   */
  getProductImageUrl(productData: Product): string {
    return this.IMAGE_BASE_URL + productData.productId.toString().padStart(9, '0');
  }

  /**
   * Sets available warehouse suppliers.
   */
  public setWarehouseSupplier(candidate: Candidate): Observable<any> {
    if (!candidate || !candidate.vendor || !candidate.vendor.apNumber) {
      return of({});
    }
    return this.lookupService.findSingleVendor(candidate.vendor.apNumber).pipe(
      tap(data => {
        candidate.vendor.lanes = data.lanes;
      }));
  }

  /**
   * Returns the image in a viewable format.
   * @param imageLinks
   */
  getImages(imageLinks: File[]): any[] {

    const images = [];
    if (!imageLinks || imageLinks.length === 0) {
      return images;
    }
    for (let x = 0; x < imageLinks.length; x++) {
      this.candidateService.retrieveCandidateImage(imageLinks[x].uuid).subscribe(result => {
          const fileReader = new FileReader();
          fileReader.onloadend = () => {
            if (!imageLinks[x].type.includes('application/pdf')) {
              images.push(fileReader.result.toString().replace('application/json', imageLinks[x].type));
            } else {
              imageLinks[x].data = fileReader.result.toString().replace('application/json', imageLinks[x].type);
              images.push(imageLinks[x]);
            }
          };
          fileReader.readAsDataURL(result);
        }, error => {
          this.growlService.addError(error);
        }
      );
    }
    return images;
  }

  hasStores(candidate: Candidate) {
    return candidate?.candidateProducts[0]?.locationGroupStores &&
      (candidate.candidateProducts[0].locationGroupStores.length > 0);
  }

  public static areAllCandidatesDsdOnly(candidates: Candidate[]): boolean {
    if (!candidates?.length) {
      return false;
    }
    for (const candidate of candidates) {
      if (candidate.warehouseSwitch || !candidate.dsdSwitch) {
        return false;
      }
    }
    return true;
  }

  public static areAllCandidatesWarehouseOnly(candidates: Candidate[]): boolean {
    if (!candidates?.length) {
      return false;
    }
    for (const candidate of candidates) {
      if (!candidate.warehouseSwitch || candidate.dsdSwitch) {
        return false;
      }
    }
    return true;
  }

  public static isCostLinkedByItem(candidate: Candidate): boolean {
    return candidate.costLinked && candidate.costLinkBy === 'ITEM';
  }

  public setMrtInnerCandidateInfoFromMrtCandidate(mrtInnerCandidate: Candidate, mrtInnerCandidateProduct: CandidateProduct, mrtCandidate) {
    mrtInnerCandidate.codeDate = mrtCandidate.codeDate;
    mrtInnerCandidate.maxShelfLife = mrtCandidate.maxShelfLife;
    mrtInnerCandidate.inboundSpecDays = mrtCandidate.inboundSpecDays;
    mrtInnerCandidate.masterLength = mrtCandidate.masterLength;
    mrtInnerCandidate.masterWidth = mrtCandidate.masterWidth;
    mrtInnerCandidate.masterLength = mrtCandidate.masterLength;
    mrtInnerCandidate.masterWeight = mrtCandidate.masterWeight;
    mrtInnerCandidate.masterPack = 1;
    mrtInnerCandidate.innerPack = 1;

    mrtInnerCandidateProduct.caseUpc = mrtInnerCandidateProduct.upc;
    mrtInnerCandidateProduct.caseUpcCheckDigit = mrtInnerCandidateProduct.upcCheckDigit;
    mrtInnerCandidateProduct.caseDescription =  mrtCandidate.candidateProducts[0].caseDescription;
    mrtInnerCandidateProduct.vendorProductCode =  mrtCandidate.candidateProducts[0].vendorProductCode;
    mrtInnerCandidateProduct.countryOfOrigin =  mrtCandidate.candidateProducts[0].countryOfOrigin;
  }

  public isInProgressCandidate(candidate: Candidate): boolean {
    if (!candidate?.status) {
      return false;
    }
    return candidate.status === Candidate.IN_PROGRESS;
  }

  handleChannelChange(candidate: Candidate): Candidate {
    candidate.merchandiseType = null;
    if (candidate.warehouseSwitch && candidate.dsdSwitch) {
      // TODO: resolve when allowing both.
    } else if (candidate.warehouseSwitch) {
      // TODO: This should be resolved when we resolve allowing user to switch from dsd to both
      candidate.dsdSwitch = false;
      candidate.masterPack = null;
      candidate.candidateProducts[0].caseUpc = null;
      candidate.candidateProducts[0].caseUpcCheckDigit = null;
    } else if (candidate.dsdSwitch) {
      candidate.codeDate = false;
      candidate.warehouseReactionDays = null;
      candidate.guaranteeToStoreDays = null;
      candidate.maxShelfLife = null;
      candidate.inboundSpecDays = null;
      candidate.masterPack = null;
      candidate.masterLength = null;
      candidate.masterWidth = null;
      candidate.masterHeight = null;
      candidate.masterWeight = null;
      candidate.masterListCost = null;
      candidate.innerPack = null;
      candidate.innerLength = null;
      candidate.innerWidth = null;
      candidate.innerHeight = null;
      candidate.innerWeight = null;
      candidate.innerListCost = null;
      candidate.masterListCost = null;
      candidate.innerPackSelected = null;
      candidate.unitCost = null;
      candidate.cubeAdjustedFactor = null;
      candidate.season = null;
      candidate.seasonYear = null;
      candidate.maxShip = null;
      candidate.lane = null;
      candidate.displayReadyUnit = null;
      candidate.displayReadyUnitOrientation = null;
      candidate.displayReadyUnitRowsDeep = null;
      candidate.displayReadyUnitRowsFacing = null;
      candidate.displayReadyUnitRowsHigh = null;
      candidate.displayReadyUnitType = null;
      candidate.candidateProducts[0].warehouses = [];

      candidate.candidateProducts[0].caseUpc =
        candidate.candidateProducts[0].upc;

      if (this.isPlu(candidate)) {
        candidate.candidateProducts[0].caseUpcCheckDigit = null;
      } else {
        candidate.candidateProducts[0].caseUpcCheckDigit =
          candidate.candidateProducts[0].upcCheckDigit;
      }

      if (CandidateUtilService.isCostLinkedByItem(candidate)) {
        candidate.costLinkBy = 'LINK';
        candidate.costLink = null;
        candidate.costLinkFromServer = null;
      }
    }
    return candidate;
  }
}
