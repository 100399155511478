import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InvitedDistributorService} from '../../service/invited-distributor.service';
import {CostService} from '../../service/cost.service';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {WorkflowService} from '../../service/workflow.service';
import {
  AttributeTextInputConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  LocationGroupStores,
  Store,
  TextInputType
} from 'pm-models';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateErrorUtilService} from '../../service/candidate-error-util.service';

@Component({
  selector: 'app-pia-new-invited-distributor-setup-stores',
  templateUrl: './pia-new-invited-distributor-setup-stores.component.html',
  styleUrls: ['./pia-new-invited-distributor-setup-stores.component.scss']
})
export class PiaNewInvitedDistributorSetupStoresComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public invitedDistributorService: InvitedDistributorService,
              private costService: CostService, private candidateService: CandidateService, private growlService: GrowlService,
              private workflowService: WorkflowService, private candidateUtilService: CandidateUtilService,
              private candidateErrorUtilService: CandidateErrorUtilService) { }

  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public canClickSubmit = true;
  public noStoresSelected = true;
  public distributorName: any;
  private taskSubscription$: any;


  listCostConfiguration: AttributeTextInputConfig = {
    label: 'List cost',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    textInputType: TextInputType.decimal,
    placeholderText: '$',
    numberCount: 4,
    decimalCount: 2
  };

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.invitedDistributorService.getTaskId(),
        this.invitedDistributorService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.invitedDistributorService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.ADDITIONAL_DISTRIBUTOR ||
            candidate.candidateType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
            this.candidateError = this.invitedDistributorService.getStoreAuthError();
            this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
            this.initializeData();
            this.invitedDistributorService.initializeProductData().subscribe();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.invitedDistributorService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.invitedDistributorService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.distributorName = this.invitedDistributorService.getCandidate().vendor.description;

    // if there's no auth selected auth groups, initialize the data.
    if (!this.invitedDistributorService.getSelectedAuthGroups()) {
      // if there's auth groups and no candidate data, set the selected to the auth groups data, else initialize the data
      // prefilled w/ candidate info.
      if (this.invitedDistributorService.getAuthGroups()) {
        if (!this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.invitedDistributorService.setSelectedAuthGroups(this.invitedDistributorService.getAuthGroups());

          this.invitedDistributorService.openAuthGroups();
        } else {
          this.invitedDistributorService.setSelectedAuthGroupsFromCandidate();
        }

        // else Fetch auth groups and set SelectedAuthGroups
      } else {
        this.invitedDistributorService.findAllAuthGroups().subscribe((authGroups) => {

          this.invitedDistributorService.setAuthGroups(authGroups);

          // if there's no candidate data, set the selected to the auth groups data, else initialize the data
          // prefilled w/ candidate info.
          if (!this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores ||
            this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
            this.invitedDistributorService.setSelectedAuthGroups(this.invitedDistributorService.getAuthGroups());

            this.invitedDistributorService.openAuthGroups();
          } else {
            this.invitedDistributorService.setSelectedAuthGroupsFromCandidate();
          }
        });
      }
    }
  }

  onClose() {
    this.applySelectedStoresToCandidate();
    this.invitedDistributorService.saveCandidateAndNavigate('/tasks', false);
  }


  /**
   * Sets the selected stores onto the candidate model.
   */
  applySelectedStoresToCandidate() {
    const authGroups: LocationGroupStores[] = [];
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      let authGroup: LocationGroupStores = null;
      const stores: Store[] = [];

      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].checked) {
          stores.push(JSON.parse(JSON.stringify(this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y])));
        }
      }
      if (stores.length > 0) {
        authGroup =  JSON.parse(JSON.stringify(this.invitedDistributorService.getSelectedAuthGroups()[x]));
        authGroup.stores = stores;
        authGroups.push(authGroup);
      }
    }
    this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores = authGroups;
  }



  onClickSubmit() {
    // validate the candidate for this page and send errors to components to display, if any
    this.canClickSubmit = false;
    this.applySelectedStoresToCandidate();

    this.candidateService.validateCandidate(this.invitedDistributorService.getCandidate(),
      [CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR]).toPromise().then(() => {
      this.invitedDistributorService.saveCandidateAndNavigate('/piaAddDistributorReview', false);
    }, (error) => {
      this.invitedDistributorService.scrollToTop();
      if (error.error.candidateErrors.hasErrors) {
        this.invitedDistributorService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.invitedDistributorService.getStoreAuthError();
        this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canClickSubmit = true;
    });

  }

  onClickBack() {
    this.applySelectedStoresToCandidate();
    this.invitedDistributorService.saveCandidateAndNavigate('/piaAddDistributorCaseDetails', false);
  }

  onClickBackToStoreSelection() {
    this.applySelectedStoresToCandidate();
    this.invitedDistributorService.saveCandidateAndNavigate('/piaAddDistributorStoreAuth', false);
  }

  onClickAuthGroupCaret(authGroup) {
    authGroup.isViewing = !authGroup.isViewing;
  }

  getSelectedStoresCountString(authGroup) {
    if (!authGroup.stores || authGroup.stores.length === 0) {
      return '';
    }
    let count = 0;
    for (let x = 0; x < authGroup.stores.length; x++) {
      if (authGroup.stores[x].checked) {
        count++;
      }
    }
    return count + ' of ' + authGroup.stores.length + ' stores selected.';
  }


  onStoreChecked(event, store, authGroup) {
    const checked = event.checked;

    if (!checked) {
      authGroup.checked = checked;
    } else {
      let areAllStoresChecked = true;
      for (let x = 0; x < authGroup.stores.length; x++) {
        if (!authGroup.stores[x].checked) {
          areAllStoresChecked = false;
        }
      }
      if (areAllStoresChecked) {
        authGroup.checked = true;
      }
    }
  }

  onListCostChange(event, authGroup) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].listCost = event;
    }
  }


  listCostFocus(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }


  onClickApplyToAllGroups(listCost, listCostOverlay) {
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      this.invitedDistributorService.getSelectedAuthGroups()[x].listCost = listCost;
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].listCost = listCost;
      }
    }
    listCostOverlay.hide();
  }

  onClickApplyToAllRemainingGroups(listCost, listCostOverlay) {
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      if (this.invitedDistributorService.getSelectedAuthGroups()[x].listCost) {
        continue;
      }
      this.invitedDistributorService.getSelectedAuthGroups()[x].listCost = listCost;
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].listCost = listCost;
      }
    }
    listCostOverlay.hide();
  }

  getListCostOverlayStyle(index) {
    if (index === 0) {
      return {margin: '-1.375rem 0 0 16rem'};
    } else {
      return {margin: '-2rem 0 0 16rem'};
    }
  }

  getAllStoreAuthCountsString() {
    if (!this.invitedDistributorService.getSelectedAuthGroups()) {
      return '';
    }
    let totalCount = 0;
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].checked) {
          totalCount++;
        }
      }
    }
    return '' + totalCount + ' stores selected';
  }

  getStoreAuthCountsString(authGroup: LocationGroupStores) {
    if (!authGroup) {
      return '';
    }
    let storeCount = 0;
    for (let y = 0; y < authGroup.stores.length; y++) {
      if (authGroup.stores[y].checked) {
        storeCount++;
      }
    }
    return  'Group ' + authGroup.splrLocationGroupId + ' | ' +
      storeCount + ' of ' + authGroup.stores.length + ' selected';
  }

  displayShowAllStores(authGroup: LocationGroupStores) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      if (authGroup.stores[x].isHidden) {
        return true;
      }
    }
    return false;
  }

  onClickShowAllStores(authGroup: LocationGroupStores) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].isHidden = false;
    }
  }


  hasNoStoresSelected () {
    if (!this.invitedDistributorService.getSelectedAuthGroups() || this.invitedDistributorService.getSelectedAuthGroups().length === 0) {
      return true;
    }

    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].checked) {
          return false;
        }
      }
    }
    return true;
  }
}
