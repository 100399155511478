import {Component, OnInit, ViewChild} from '@angular/core';
import {
  Attribute,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  EmailMessage, MatHierarchy,
  Product,
  Task,
  TaskDecision
} from 'pm-models';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LookupService} from '../../service/lookup.service';
import {WorkflowService} from '../../service/workflow.service';
import {GrowlService} from '../../growl/growl.service';
import {FileService} from '../../service/file.service';
import {AttributeTypes, ReviewComponent, UPCInputState} from 'pm-components';
import {EditCandidateModalService} from '../../service/edit-candidate-modal.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateHistoryService} from '../../service/candidate-history.service';
import {Observable} from 'rxjs';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import {MatUtilService} from '../../service/mat-util.service';
import {RequestNewMatAttributeOverrideWrapper} from 'pm-components/lib/attributes/attribute-type';

@Component({
  selector: 'app-associate-upc-procurement-review',
  templateUrl: './associate-upc-procurement-review.component.html',
  styleUrls: ['./associate-upc-procurement-review.component.scss']
})
export class AssociateUpcProcurementReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public ASSOCIATE_UPC_TASK_NAME = 'Review Associate UPC';

  constructor(private route: ActivatedRoute, private workflowService: WorkflowService, private lookupService: LookupService,
              private router: Router, private candidateService: CandidateService, private fileService: FileService,
              private growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public candidateUtilService: CandidateUtilService, public candidateHistoryService: CandidateHistoryService,
              public permissionService: NgxPermissionsService, public matUtilService: MatUtilService) {
  }

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public associateCandidateProduct: CandidateProduct;
  public originalCandidate: any = {};
  public candidateError: CandidateError;
  public candidateProductErrors: Map<string, CandidateProductError>;
  private currentCandidateProductIndex = 1;
  private searchedCandidateProductIndex = 0;
  private taskSubscription$: any;
  public productData = [];
  public productImageUrl: string = null;
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  private task: Task;
  public isViewingPage = true;
  public canEditSelectedCases = false;
  public upcState: UPCInputState;
  public selectedCasePack: number;
  private selectedCasePackItemCode: number;
  private product: Product;
  public IMAGE_BASE_URL = 'https://images.heb.com/is/image/HEBGrocery/';
  public isSubmitDisabled = false;
  private isCloseDisabled = false;
  public isRejectDisabled = false;
  private commodity: any;
  private buyer: any;
  private commodityId;
  public lastSupplierChangedCandidate: Candidate;
  public lastSupplierChangedCandidateProduct: CandidateProduct;
  public showHistoryPanel: boolean = false;
  public currentHistoryResults: any = undefined;
  public candidateAudits: any = undefined;
  public candidateProductAudits: any = undefined;
  showMatAttributes = false;
  isLoadingMatData = true;

  public requestNewMatAttributeOverrideWrapper = new RequestNewMatAttributeOverrideWrapper();
  public hierarchyNumberToAttributesMap: Map<number, Attribute[]> = new Map();
  public hierarchyAttributes: Attribute[] = [];
  public globalAttributes: Attribute[] = [];
  public upcAttributes: Attribute[] = [];


  ngOnInit(): void {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
            .subscribe((task) => {
              this.task = task;
              if (this.task.name !== this.ASSOCIATE_UPC_TASK_NAME) {
                this.router.navigate(
                    ['/tasks'], { queryParams: { growlMessage: 'Candidate is not in ' + this.ASSOCIATE_UPC_TASK_NAME +
                            ' status.', growlToUse: GrowlService.SEVERITY_ERROR }
                    }).then();
              }
              this.candidateService.getCandidate(task.candidateId)
                  .subscribe((candidate) => {
                    this.candidate = candidate;
                    if (candidate.candidateType !== Candidate.ASSOCIATE_UPC) {
                      this.router.navigate(['/tasks'], {
                        queryParams: {growlMessage: 'Invalid task type.', growlToUse: GrowlService.SEVERITY_ERROR}
                      }).then();
                    }
                    this.candidateHistoryService.setInitialValues(candidate);
                    this.setInitialValues(candidate);
                  });

            }, (error) => {
              // if there was an error retrieving task, route back to tasks page with the error
              this.router.navigate(['/tasks'], {
                queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
              }).then();
            });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.isViewingPage = false;
        this.router.navigate(['/tasks']);
      });
    } else {
      this.isViewingPage = false;
      this.router.navigate(['/tasks']);
    }
  }
  showRejectPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }

  reject(event, panel, target) {
    this.isRejectDisabled = true;
    this.candidate.vendorComment = event;
    this.candidate.status = Candidate.DECLINED;
    this.workflowService.addProcessVariableViewed(this.task.processInstanceId, false).subscribe(() => {
      this.saveAndCompleteTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE,
          TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION,
          'Successfully rejected candidate');
      panel.hide();
    });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
    });
  }

  onEditExistingCase() {
    this.canEditSelectedCases = true;
  }

  onSaveExistingCase() {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.canEditSelectedCases = false;
    });
  }

  private setInitialValues(primaryCandidate: Candidate) {
    this.candidateError = new CandidateError();
    this.productImageUrl = null;
    this.setOriginalAndCurrentSearchedCandidate(primaryCandidate);
    this.setProductData().subscribe(() => {
          this.setupMatAttributes();
        }, (error) => {
          this.growlService.addError(error.message);
        }
    );
    if (primaryCandidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      this.lookupService.getUpc(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc).subscribe(
          (upc) => {
            this.product = upc.product;
            this.setUpcData(upc);
            this.candidateProductErrors = this.candidateError.candidateProductErrors;
            this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.product);
          });
    } else if (primaryCandidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
        CandidateProduct.SEARCHED_ITEM) {
      this.lookupService.getItem(
          primaryCandidate.candidateProducts[this.searchedCandidateProductIndex].itemCode)
          .subscribe((productData) => {
            // using the the upc from the item information to get all the needed fields
            this.lookupService.getUpc(productData.containedUpc.upc.scanCodeId).subscribe(
                (upc) => {
                  this.product = upc.product;
                  this.setUpcData(upc);
                  this.candidateProductErrors = this.candidateError.candidateProductErrors;
                  this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.product);
                });
          });
    }
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      return;
    }
    this.showMatAttributes = true;
    this.matUtilService.updateCandidateProductsMatHierarchyFromProduct(this.candidate.candidateProducts, this.product).pipe(
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate, this.globalAttributes, this.hierarchyAttributes)),
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.hierarchyAttributes, this.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.globalAttributes, [], [], this.upcAttributes);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })).subscribe();
  }

  private setProductData(): Observable<any> {
    if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc, []).pipe(
          tap((productData) => {
            this.productData = productData;
            this.candidate.productId = productData.productId;
            this.setCaseUpcData(productData);
          })
      );
    } else if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, []).pipe(
          tap((productData) => {
            if (productData) {
              this.productData = productData;
              this.candidate.productId = productData.productId;
              this.setCaseUpcData(productData);
              this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc = productData.primaryScanCodeId;
            }
          })
      );
    }
  }

  /**
   * This takes the search term and uses that to filter the data for all matching caseUpc, upc, or itemCode.
   *
   * @param data Data to be filtered.
   * @param {Number} term The search term to filter by. Checks against caseUpc, Upc, and item code properties.
   */
  filterData(data) {
    // first go through and filter the data by search term
    return data.filter(c => c.altPack === false);
  }

  /**
   * Sets the original and current associateCandidate objects. The original represents the original state of the associateCandidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentSearchedCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];
    if (!this.candidateProduct.upc) {
      this.lookupService.getUpc(this.candidateProduct.itemCode).subscribe(upc =>
          this.candidateProduct.upc = upc.searchedUpc);
    }
    this.associateCandidateProduct = this.candidate.candidateProducts[1];
  }

  onClickActivate() {
    this.validateAndActivate();
  }

  validateAndActivate() {
    for (const candidateProduct of this.candidate.candidateProducts.slice(1)) {
      candidateProduct.caseUpc = this.getSelectedCasePack();
      candidateProduct.itemCode = this.getItemCode();
    }
    this.isSubmitDisabled = true;
    this.isCloseDisabled = true;
    this.candidate.candidateProducts[0] = this.candidateProduct;
    this.candidate.candidateProducts[1].itemCode = this.getItemCode();
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.ASSOCIATE_FINAL_REVIEW_VALIDATOR])
        .subscribe((candidate) => {
          this.workflowService.addProcessVariableViewed(this.task.processInstanceId, false).subscribe(() => {
            this.candidateService.activateCandidate(this.candidate).subscribe(() => {
              this.sendBuyerEmail();
              this.completeTaskAndRouteToTasksPage(
                  WorkflowService.ACTION_COMPLETE,
                  TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
                  'Successfully activated candidate.'
              );
            }, (error) => {
              this.growlService.addError(error);
              this.isSubmitDisabled = false;
              this.isCloseDisabled = false;
            });
          });
        }, (error) => {
          if (error.error.candidateErrors) {
            this.candidateError = error.error.candidateErrors;
            this.candidateProductErrors = this.candidateError.candidateProductErrors;
            if (this.candidateProductErrors[this.associateCandidateProduct.id].caseUpc) {
              this.growlService.addError(this.candidateProductErrors[this.associateCandidateProduct.id].caseUpc);
            }
          } else {
            this.growlService.addError(error.message);
          }
          this.isSubmitDisabled = false;
          this.isCloseDisabled = false;
        });
  }

  getUpcErrors() {
    if (!this.candidateProductErrors) {
      return;
    }
    const upcError: Array<string> = [];
    this.candidate.candidateProducts.forEach(cp => {
      if (this.candidateError.candidateProductErrors[cp.id]?.upc) {
        upcError.push(cp.upc + ' ' + this.candidateError.candidateProductErrors[cp.id].upc + ' ');
      }
    });
    return upcError;
  }

  /**
   * Returns the current candidate product.
   */
  public getCurrentCandidateProduct(): CandidateProduct {
    return this.candidate.candidateProducts[this.currentCandidateProductIndex];
  }

  selectedCaseChange(event, casePack) {
    if (!event) {
      this.selectedCasePack = undefined;
    } else {
      this.selectedCasePack = casePack.caseUpc;
      this.associateCandidateProduct.caseUpc = casePack.caseUpc;
      for (const candidateProduct of this.candidate.candidateProducts.slice(1)) {
        candidateProduct.caseUpc = this.getSelectedCasePack();
        candidateProduct.itemCode = this.getItemCode();
      }
      this.candidate.candidateProducts[this.currentCandidateProductIndex].caseUpc = casePack.caseUpc;
    }
  }

  editAssociateUpc() {
    this.showEditCandidateProductModal(AttributeTypes.AssociateUpc, {
      validationService: this.candidateService
    });
  }

  showEditCandidateProductModal(type: AttributeTypes,  overrides?: any) {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidate));
    this.editCandidateModalService.openModal(type, tempCandidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        if (type === AttributeTypes.AssociateUpc) {
          this.handleHierarchUpdates(response);
        }
        this.candidate = response;
      }
    });
  }

  handleHierarchUpdates(candidate: Candidate) {
    let hierarchyList: MatHierarchy[] = null;
    let missingHierarchy = false;
    for (let x = 1; x < candidate.candidateProducts.length; x++) {
      if (!candidate.candidateProducts[x].matHierarchyList?.length) {
        missingHierarchy = true;
      } else if (!hierarchyList?.length) {
        hierarchyList = candidate.candidateProducts[x].matHierarchyList;
      }
    }
    if (hierarchyList?.length && missingHierarchy) {
      hierarchyList = JSON.parse(JSON.stringify(hierarchyList));
      hierarchyList.forEach(hierarchy =>  {
        hierarchy.attributes = [];
      });
      for (let x = 1; x < candidate.candidateProducts.length; x++) {
        if (!candidate.candidateProducts[x]?.matHierarchyList?.length) {
          candidate.candidateProducts[x].matHierarchyList = JSON.parse(JSON.stringify(hierarchyList));
        }
      }
    }
  }

  /**
   * Sets the data on a retail info object to be displayed on a table.
   * @param upc
   */
  setUpcData(upc) {
    this.candidate.masterHeight = upc.height;
    this.candidate.masterWidth = upc.width;
    this.candidate.masterLength = upc.length;

    this.candidate.retailSize = upc.size;
    this.candidate.unitOfMeasure = upc.unitOfMeasure;
    this.candidate.candidateProducts[0].subBrand = upc.subBrand;
    this.candidate.totalVolume = upc.sellingSizeOne;
  }

  setCaseUpcData(productData: any) {
    this.productData = productData.items;
    this.setCasePack();
  }

  setCasePack() {
    for (let x = 0; x < this.productData.length; x++) {
      if (this.productData[x].caseUpc === this.associateCandidateProduct.caseUpc) {
        this.selectedCasePack = this.associateCandidateProduct.caseUpc;
        this.commodityId = this.productData[x].commodity;
        this.findCommodity();
      }
    }
  }

  equals(caseUpc: any) {
    return (this.canEditSelectedCases || (caseUpc === this.selectedCasePack));
  }

  getSelectedCasePack() {
    return this.selectedCasePack;
  }

  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
        .subscribe(() => {
          this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then(data => {
            this.isSubmitDisabled = false;
            this.isCloseDisabled = false;
            this.isRejectDisabled = false;
          });
        }, (error) => {
          this.growlService.addError(error);
        });
  }

  getItemCode() {
    for (let x = 0; x < this.productData.length; x++) {
      if (this.productData[x].caseUpc === this.selectedCasePack) {
        this.selectedCasePackItemCode = this.productData[x].itemCode;
        return this.selectedCasePackItemCode;
      }
    }
  }

  /**
   * Sends the buyer a notification via email.
   */
  sendBuyerEmail() {
    this.findBuyer();
    const cc = '';

    let body = 'Hello!' + '<br />';
    body += 'Procurement Support initiated the activation of one or more associate UPCs from your suppliers in PAM today! ' +
        'Below is a summary of the new items (you can view the full item details in PM).<br />';

    body += 'Existing product: ' + this.candidate.candidateProducts[0].upc + '<br />';
    body += 'New items UPC: ' + this.candidate.candidateProducts[1].upc + '<br />';
    body += 'Case: ' + this.selectedCasePack + '<br />';
    body += 'Created by: ' + this.candidate.createdBy + ', ' + this.candidate.contactEmail + '<br />';

    if (this.buyer) {
      const buyerEmail = this.buyer.email;
    }
    const emailMessage = new EmailMessage(this.buyer[0].email, '', 'do-not-reply@heb.com',
        '', 'New Associate Upc Activated', body, cc, '');
    this.lookupService.sendEmail(emailMessage).subscribe();

  }

  /**
   * To find the commodity.
   */
  findCommodity() {
    this.lookupService.findCommodity(this.commodityId).subscribe( data => {
      this.commodity = data;

      this.findBuyer();
    });
  }

  /**
   * To find buyer info attached to the commodity.
   */
  findBuyer() {
    this.lookupService.findBuyer(this.commodity[0].buyer.buyerId).subscribe( buyerData => {
      this.buyer = buyerData;
    });
  }

  getActivateButtonTitle() {
    if (this.isSubmitDisabled) {
      return 'Activating';
    } else {
      return 'Activate';
    }
  }

  historyPanelOpen() {
    this.candidateHistoryService.historyPanelOpen();
    this.showHistoryPanel = true;
    this.pmReview.openDrawer();
  }

  historyPanelClose() {
    this.candidateHistoryService.historyPanelClose();
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  addAttachments(event) {
    this.candidate.attachments = event;
    this.candidateService.saveCandidate(this.candidate, true).subscribe(savedCandidate => {
      this.setOriginalAndCurrentSearchedCandidate(savedCandidate);
    });
  }

  getCandidateProductError(candidateProduct: CandidateProduct, fieldId: string) {
    if (!candidateProduct || !this.candidateError?.candidateProductErrors) {
      return;
    }

    const candidateProductError = this.candidateError?.candidateProductErrors[candidateProduct.id];
    return this.matUtilService.getAttributeError(fieldId, candidateProductError);
  }
}
