import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError, CandidateHelper,
  CandidateProduct,
  CandidateProductError, CandidateValidatorType, Commodity,
  HEBDetailsCardPermissions,
  SupplierDetailsCardPermissions
} from 'pm-models';
import {AuthService} from '../../auth/auth.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateService} from '../../service/candidate.service';
import {OwnBrandService} from '../../service/ownbrand.service';
import {PharmProductService} from '../../service/pharm-product.service';
import {LookupService} from '../../service/lookup.service';

@Component({
  selector: 'app-pharm-new-product-supplier-details',
  templateUrl: './pharm-new-product-supplier-details.component.html',
  styleUrls: ['./pharm-new-product-supplier-details.component.scss']
})
export class PharmNewProductSupplierDetailsComponent implements OnInit {

  public commodities: any;

  hebDetailsCardPermissions: HEBDetailsCardPermissions = {
    isReadOnly: false,
    productType: {
      isHidden: true
    },
    merchandiseType: {
      isHidden: true
    }
  };

  readonly supplierDetailsCardPermissions: SupplierDetailsCardPermissions = {
    isReadOnly: false,
  };

  public isViewingPage = true;
  private taskSubscription$: any;

  public originalCandidate: Candidate;

  // any errors for this page will be here
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public canClickNext = true;
  public subCommodities: any;

  constructor(public route: ActivatedRoute, public router: Router, public pharmProductService: PharmProductService,
              public authService: AuthService, public candidateService: CandidateService,
              public candidateUtilService: CandidateUtilService, public ownBrandService: OwnBrandService,
              private lookupService: LookupService) {
  }

  ngOnInit() {

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      let taskId;
      if (this.pharmProductService.getTaskId()) {
        taskId = this.pharmProductService.getTaskId();
      } else if (this.pharmProductService.getTask() && this.pharmProductService.getTask().id) {
        taskId = this.pharmProductService.getTask().id;
      }
      if (params.keys.length > 0 && (!(taskId && params.has('taskId') && params['params']['taskId'] === taskId))) {
        this.pharmProductService.resetService();
        this.pharmProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT || candidate.candidateType === Candidate.PLU) {
            this.pharmProductService.setCandidate(candidate);
            this.originalCandidate = JSON.parse(JSON.stringify(candidate));
            this.candidateError = this.pharmProductService.getSupplierDetailsError();
            this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
            if (this.pharmProductService.getCandidate().buyer) {
              this.setCommoditiesAndSubCommodities(this.pharmProductService.getCandidate().buyer);
            }
            if (this.pharmProductService.getAuthGroups()) {
              this.pharmProductService.validateHasNoStores();
            } else if (this.pharmProductService.candidate.vendor &&
              this.pharmProductService.candidate.commodity) {
              this.pharmProductService.findAllAuthGroups().subscribe((authGroups) => {
                this.pharmProductService.setAuthGroups(authGroups);
                this.pharmProductService.setSelectedAuthGroups(null);
                this.pharmProductService.validateHasNoStores();
                // default to all groups since no UI page to set them
                this.pharmProductService.candidate.candidateProducts[0].locationGroupStores = authGroups;
              });
            }
          } else {
            this.router.navigate(['/tasks']).then();
          }
        });
      } else if (this.pharmProductService.getOriginalCandidate() && this.pharmProductService.getCandidate()) {
        // this.initializeData();
        this.originalCandidate = this.pharmProductService.getOriginalCandidate();
        this.candidateError = this.pharmProductService.getSupplierDetailsError();
        this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
        this.pharmProductService.candidate.contactName = this.authService.getUser();

        if (this.pharmProductService.getCandidate().buyer) {
          this.setCommoditiesAndSubCommodities(this.pharmProductService.getCandidate().buyer);
        }
        if (!this.pharmProductService.getAuthGroups()) {
          if (this.pharmProductService.candidate.vendor && this.pharmProductService.candidate.commodity) {
            this.pharmProductService.findAllAuthGroups().subscribe((authGroups) => {
              this.pharmProductService.setAuthGroups(authGroups);
              this.pharmProductService.setSelectedAuthGroups(null);
              this.pharmProductService.validateHasNoStores();
              // default to all groups since no UI page to set them
              this.pharmProductService.candidate.candidateProducts[0].locationGroupStores = authGroups;
            });
          }
        } else {
          this.pharmProductService.validateHasNoStores();
        }
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  getCommodityConfiguration(): AttributeTypeaheadConfig {
    return {
      label: 'Commodity',
      description: 'Choose an appropriate category.',
      isRequired: this.pharmProductService.getCandidate() && this.pharmProductService.getCandidate().dsdSwitch,
      isDisabled: () => !(this.commodities && this.commodities.length > 0),
      isReadOnly: () => false,
      name: 'commodityId',
      displayRef: 'commodityName',
      placeholderText: 'Select a commodity...',
      collections: this.commodities
    };
  }

  getSubCommodityConfiguration(): AttributeTypeaheadConfig {
    return {
      label: 'Sub-commodity',
      description: 'Choose an appropriate subcategory.',
      isRequired: this.pharmProductService.getCandidate() && this.pharmProductService.getCandidate().dsdSwitch,
      isDisabled: () => !(this.subCommodities && this.subCommodities.length > 0),
      isReadOnly: () => false,
      name: 'subcommodityId',
      displayRef: 'displayName',
      placeholderText: 'Select a sub-commodity...',
      collections: this.subCommodities
    };
  }

  onClose() {
    this.isViewingPage = false;
    this.pharmProductService.saveCandidateAndClose();
  }

  onMerchandiseTypeChange(event) {
  }

  onBuyerChange(event) {
    this.pharmProductService.updateBuyerCallBack(event);
    this.pharmProductService.getCandidate().commodity = null;
    this.pharmProductService.getCandidate().subCommodity = null;
    if (event && event.buyerId) {
      this.setCommoditiesAndSubCommodities(event);
    }
    this.pharmProductService.setAuthGroups(null);
    this.pharmProductService.setSelectedAuthGroups(null);
    this.pharmProductService.getCandidate().candidateProducts[0].locationGroupStores = [];
  }

  onSupplierChange(event) {
    this.pharmProductService.getCandidate().vendor = event;
    if (!this.pharmProductService.isDsdOnly()) {
      return;
    }
    if (this.pharmProductService.candidate.vendor && this.pharmProductService.candidate.commodity) {
      this.pharmProductService.findAllAuthGroups().subscribe((authGroups) => {
        this.pharmProductService.setAuthGroups(authGroups);
        this.pharmProductService.setSelectedAuthGroups(null);
        this.pharmProductService.getCandidate().candidateProducts[0].locationGroupStores = [];
        this.pharmProductService.validateHasNoStores();
        // default to all groups since no UI page to set them
        this.pharmProductService.candidate.candidateProducts[0].locationGroupStores = authGroups;
      });
    } else {
      this.pharmProductService.setAuthGroups(null);
      this.pharmProductService.setSelectedAuthGroups(null);
      this.pharmProductService.getCandidate().candidateProducts[0].locationGroupStores = [];
    }
  }

  onCommodityChange(event) {
    this.pharmProductService.getCandidate().commodity = event;
    this.pharmProductService.getCandidate().pssDepartment = this.pharmProductService.getCandidate().commodity.pssDepartment;
    this.pharmProductService.getCandidate().subCommodity = null;

    if (this.pharmProductService.candidate.vendor && this.pharmProductService.candidate.commodity) {
      this.pharmProductService.findAllAuthGroups().subscribe((authGroups) => {
        this.pharmProductService.setAuthGroups(authGroups);
        this.pharmProductService.setSelectedAuthGroups(null);
        this.pharmProductService.validateHasNoStores();
        // default to all groups since no UI page to set them
        this.pharmProductService.candidate.candidateProducts[0].locationGroupStores = authGroups;
      });
    } else {
      this.pharmProductService.setAuthGroups(null);
      this.pharmProductService.setSelectedAuthGroups(null);
      this.pharmProductService.getCandidate().candidateProducts[0].locationGroupStores = [];
    }
    this.getSubCommodities(event);
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
  }

  onSubCommodityChange(event) {
    this.pharmProductService.getCandidate().subCommodity = event;

    if (event) {
      this.pharmProductService.getCandidate().foodStamp = CandidateHelper.isFoodStamp(this.pharmProductService.getCandidate());
      this.pharmProductService.getCandidate().taxable = CandidateHelper.isTaxCode(this.pharmProductService.getCandidate());
    }
    // Sets the vertex tax category model.
    this.candidateUtilService.setVertexTaxCategoryModel(this.pharmProductService.candidate).subscribe((candidate) => {
      this.pharmProductService.candidate = candidate;
    });
  }

  onClickNext() {
    this.canClickNext = false;
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.pharmProductService.candidate,
      [CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR]).toPromise().then(() => {
      this.candidateError = this.pharmProductService.setSupplierDetailsError(new CandidateError());
      if (this.pharmProductService.getCurrentCandidateProduct().generatedUpc) {
        this.ownBrandService.updateReservation(this.pharmProductService.candidate).subscribe();
      }
      if (this.pharmProductService.validateHasNoStores()) {
        const error = new CandidateError();
        error.noStoresError = true;
        this.candidateError = this.pharmProductService.setSupplierDetailsError(error);
      } else {
        this.candidateError = this.pharmProductService.setSupplierDetailsError(new CandidateError());
      }
      this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductDetails', this.hasVendorChanged());
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        const candidateErrors = error.error.candidateErrors;
        if (this.pharmProductService.validateHasNoStores()) {
          candidateErrors.noStoresError = true;
        }
        this.candidateError = this.pharmProductService.setSupplierDetailsError(candidateErrors);
        this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
      }
      if (this.pharmProductService.getCurrentCandidateProduct().generatedUpc) {
        this.ownBrandService.updateReservation(this.pharmProductService.candidate).subscribe();
      }
      this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductDetails', this.hasVendorChanged());
    });
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyer the buyer.
   */
  private setCommoditiesAndSubCommodities(buyer) {
    if (!buyer || !buyer.buyerId) {
      this.commodities = [];
      this.subCommodities = [];
    }
    this.lookupService.findAllCommoditiesByBuyerId(buyer.buyerId).subscribe(data => {
      this.commodities = data;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
      } else {
        this.subCommodities = [];
      }
    });
  }

  onClickBack() {
    this.pharmProductService.saveCandidateAndNavigate('/pharmSetupNewProduct', this.hasVendorChanged());
  }


  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    return JSON.stringify(this.originalCandidate.vendor) !== JSON.stringify(this.pharmProductService.candidate.vendor);
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.pharmProductService.getCandidate().commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.pharmProductService.getCandidate().commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

}
