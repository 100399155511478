import {File} from './file';
import {QualifyingCondition} from 'pm-models/lib/qualifyingCondition';
import {ImportInfo} from 'pm-models/lib/importInfo';
import {
  Attribute,
  CountryOfOrigin,
  InvitedSupplier,
  LocationGroupStores,
  MatHierarchy,
  PluRange,
  ScaleInformation,
  WarehouseSupplierItem
} from 'pm-models';
import {UpcRequestType} from 'pm-models/lib/newUpcRequest';

export class CandidateProduct {

  public static SEARCHED_ITEM = 'SEARCHED_ITEM';
  public static SEARCHED_UPC = 'SEARCHED_UPC';
  public static PLU = 'PLU';
  public static NEW_ITEM = 'NEW_ITEM';
  public static BONUS_SIZE = 'BONUS_SIZE';
  public static ASSOCIATE_UPC = 'ASSOCIATE_UPC';
  public static MRT_ITEM = 'MRT_ITEM';
  public static ADDITIONAL_DISTRIBUTOR = 'ADDITIONAL_DISTRIBUTOR';
  public static REPLACEMENT_UPC = 'REPLACEMENT_UPC';


  id: string;
  upc?: number;
  upcCheckDigit?: number;
  caseUpc?: number;
  caseUpcCheckDigit?: number;
  description?: string;
  caseDescription?: string;
  customerFriendlyDescription1?: string;
  customerFriendlyDescription2?: string;
  romanceCopy?: string;
  vendorProductCode?: string;
  imageLinks?: File[];
  labelInsightsImageLinks?: File[];
  termsAndConditions?: boolean;
  images?: string[] = []; // transient- for UI display purposes only
  labelInsightsImages?: string[] = []; // transient- for UI display purposes only
  countryOfOrigin?: CountryOfOrigin;
  subBrand?: any;
  warehouses?: any[];
  itemCode?: number;
  remark1?: string;
  remark2?: string;
  candidateProductType?: string;
  existingUpc?: Boolean;
  pamCandidateUpc?: Boolean;
  availableUpc?: Boolean;
  qualifyingCondition?: QualifyingCondition;
  generatedUpc?: boolean = false;
  factory?: any;
  pluRange?: PluRange;
  pluType?: UpcRequestType;

  /**
   * import fields
   */
  imported?: boolean;
  importInfo?: ImportInfo;
  scaleInformation?: ScaleInformation;

  locationGroupStores?: LocationGroupStores[];

  invitedSuppliers?: InvitedSupplier[];
  warehouseSupplierItems?: WarehouseSupplierItem[];
  depositScanCodeId?: number;
  depositScanCodeCheckDigit?: number;

  matHierarchyList?: MatHierarchy[];
  globalAttributes?: Attribute[];

  keepPlu?: boolean;
  labelInsightOmissionReasonCode?: any;
  labelInsightOmissionReasonDesc?: any;
  labelInsightOmissionReason?: any;

  isViewing?: boolean;
}
