import { Injectable } from '@angular/core';
import {WorkflowService} from './workflow.service';
import {CandidateService} from './candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../growl/growl.service';
import {Attribute, Candidate, CandidateError, CandidateProduct, CandidateProductError, Commodity, Task, TaskDecision} from 'pm-models';
import {UUID} from 'angular2-uuid';
import {catchError, switchMap, tap} from 'rxjs/operators';
import { EMPTY, from, Observable, throwError as observableThrowError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssociateUpcService {

  constructor(private workflowService: WorkflowService, private candidateService: CandidateService, private route: ActivatedRoute,
              private router: Router, private growlService: GrowlService) { }

  private taskId = undefined;
  private task: Task;
  private VENDOR_TASK_NAME = 'New Associate UPC';
  private VENDOR_REVISION_TASK_NAME = 'Revise Vendor Data';
  private CLOSED_VENDOR_TASK_NAME = 'Closed Vendor Task';
  private NEW_ASSOCIATE_UPC_WORKFLOW = 'associateUpcProcess';
  private PIA_NEW_PRODUCT_WORKFLOW = 'piaNewProductProcess';
  private isClosedTask = false;
  candidate: Candidate;
  private originalCandidate: Candidate = undefined;
  private currentCandidateProductIndex = 0;
  private commodities: Commodity[] = undefined;
  private setupAssociateUpcError: CandidateError = undefined;
  private associateUpcDetailsError: CandidateError = undefined;
  private associateCasePackDetailsError: CandidateError = undefined;
  private associateExtendedAttributesError: CandidateError = undefined;

  public hierarchyNumberToAttributesMap: Map<number, Attribute[]> = new Map();
  public hierarchyAttributes: Attribute[] = [];
  public globalAttributes: Attribute[] = [];
  public upcAttributes: Attribute[] = [];

  /**
   * Resets default value.
   */
  resetService() {
    this.taskId = undefined;
    this.task = undefined;
    this.isClosedTask = false;
    this.candidate = undefined;
    this.originalCandidate = undefined;
    this.currentCandidateProductIndex = 0;
    this.commodities = null;
    this.resetErrors();
    this.resetMatHierarchyFields();
  }

  resetMatHierarchyFields() {
    this.globalAttributes = [];
    this.hierarchyAttributes = [];
    this.upcAttributes = [];
    this.hierarchyNumberToAttributesMap = new Map();
  }

  /**
   * Returns the current candidate.
   */
  public getCandidate() {
    return this.candidate;
  }

  /**
   * Returns the original candidate.
   */
  public getOriginalCandidate() {
    return this.originalCandidate;
  }

  /**
   * Returns the task.
   */
  public getTask() {
    return this.task;
  }

  /**
   * Returns task id.
   */
  public getTaskId() {
    return this.taskId;
  }

  public resetErrors() {
    this.setupAssociateUpcError = new CandidateError();
    this.associateUpcDetailsError = new CandidateError();
    this.associateCasePackDetailsError = new CandidateError();
    this.associateExtendedAttributesError = new CandidateError();
  }


  public setupNewCandidate() {
    this.candidate = new Candidate();
    this.candidate.productType = Candidate.SELLABLE;
    this.candidate.candidateType = Candidate.ASSOCIATE_UPC;
    this.candidate.candidateProducts = [];
    this.candidate.candidateProducts.push({id: UUID.UUID()});
    this.candidate.candidateProducts.push({id: UUID.UUID(),
      candidateProductType: CandidateProduct.ASSOCIATE_UPC, upc: null, upcCheckDigit: null});
    this.originalCandidate = JSON.parse(JSON.stringify(this.candidate));
    this.resetErrors();
  }

  /**
   * Set the candidate.
   * @param candidate
   */
  setCandidate(candidate: Candidate) {
    this.candidate = candidate;
  }

  /**
   * Creates the candidate and navigates to the supplier details.
   * @param candidate
   */
  public createCandidateAndNavigate(candidate: Candidate, url: string, updateApNumber: boolean, piaOnly?: boolean) {
    this.candidateService.createNewCandidate(candidate).subscribe((newCandidate: Candidate) => {
      this.originalCandidate = newCandidate;
      this.candidate = JSON.parse(JSON.stringify(newCandidate));
      this.resetErrors();
      let workflow;
      if (piaOnly) {
        workflow = this.PIA_NEW_PRODUCT_WORKFLOW;
      } else {
        workflow = this.NEW_ASSOCIATE_UPC_WORKFLOW;
      }
      this.createProcessInstanceWithCandidateId(newCandidate.candidateId, url, updateApNumber, workflow);
    });
  }


  /**
   * Create process instance with newly created candidate id and routes to the supplier details page.
   */
  private createProcessInstanceWithCandidateId(candidateId: number, url: string, updateApNumber: boolean, workflow: string) {
    this.workflowService.createProcessInstanceWithCandidateId(candidateId, workflow)
      .subscribe(taskId => {
        if (taskId) {
          this.taskId = taskId;
          this.workflowService.getTaskByIdWithVariables(taskId).subscribe((task) => {
            this.task = task;

            if (updateApNumber) {
              let apNumber;
              if (!this.candidate.vendor) {
                apNumber = null;
              } else {
                apNumber = this.candidate.vendor.apNumber;
              }
              this.workflowService.updateApNumber(apNumber, this.task.processInstanceId).subscribe(() => {
                this.router.navigate([url], {queryParams: {taskId: taskId}});
              });
            } else {
              this.router.navigate([url], {queryParams: {taskId: taskId}});
            }
          });
        } else {
          this.growlService.addError('Problem creating process instance.');
        }
      });
  }

  /**
   * Saves candidate.
   */
  saveCandidate() {
    if (this.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          this.setOriginalAndCurrentCandidate(savedCandidate);
        });
      }
    }
  }

  saveCandidateAndNavigateTo(url: string, updateApNumber: boolean): Observable<any> {
    if (this.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
      return EMPTY;
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        return this.candidateService.saveCandidate(this.candidate).pipe(switchMap(savedCandidate => {
          if (updateApNumber) {
            let apNumber;
            if (!this.candidate.vendor) {
              apNumber = null;
            } else {
              apNumber = this.candidate.vendor.apNumber;
            }
            return this.workflowService.updateApNumber(apNumber, this.task.processInstanceId).pipe(switchMap(_ => {
              this.setOriginalAndCurrentCandidate(savedCandidate);
              return from(this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}}));
            }));
          } else {
            this.setOriginalAndCurrentCandidate(savedCandidate);
            return from(this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}}));
          }
        }));
      } else {
        return from(this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}}));
      }
    }
  }

  /**
   * Saves candidate and navigates to the url path provided.
   */
  saveCandidateAndNavigate(url: string, updateApNumber: boolean) {
    if (this.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          if (updateApNumber) {
            let apNumber;
            if (!this.candidate.vendor) {
              apNumber = null;
            } else {
              apNumber = this.candidate.vendor.apNumber;
            }
            this.workflowService.updateApNumber(apNumber, this.task.processInstanceId).subscribe(() => {
              this.setOriginalAndCurrentCandidate(savedCandidate);
              this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}});
            });
          } else {
            this.setOriginalAndCurrentCandidate(savedCandidate);
            this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}});
          }
        });
      } else {
        this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}});
      }
    }
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user to URL with candidate ID
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param candidateID to include in query params of URL.
   */
  public saveAndCompleteTaskAndRouteToUrlWithCandidateId(action: string, taskDecision: TaskDecision, url: string, candidateID: number) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
        .subscribe(() => {
          this.router.navigate([url], {queryParams: {candidateId : candidateID}}).then();
        });
    });
  }

  /**
   * Checks if a string is empty or just spaces
   */
  isEmptyOrSpaces(str) {
    return typeof str === 'undefined' || !str  || str.match(/^ *$/) !== null;
  }

  /**
   * Sets and returns the original and current.
   * @param params
   */
  public setCandidateByUrlParameters(params): Observable<any> {
    if (params.has('taskId')) {
      return this.workflowService.getTaskByIdWithVariables(params['params']['taskId']).pipe(
        switchMap(
          (task) => {
            this.task = task;
            this.taskId = this.task.id;
            if (!(this.task.name === this.VENDOR_TASK_NAME || this.task.name === this.VENDOR_REVISION_TASK_NAME ||
              this.task.name === Task.PIA_NEW_PRODUCT_FLOW)) {
              this.router.navigate(['/tasks'], {
                queryParams: {
                  growlMessage: 'Candidate is not in available for supplier revision.',
                  growlToUse: GrowlService.SEVERITY_ERROR
                }
              });
              return observableThrowError('Candidate is not in available for supplier revision.');
            }
            return this.getCandidateByCandidateId(task.candidateId);
          }
        ));
    } else if (params.has('candidateId')) {
      this.isClosedTask = true;
      const candidateId =  params['params']['candidateId'];
      this.candidateService.getCandidate(candidateId)
        .subscribe((candidate) => {
          this.setOriginalAndCurrentCandidate(candidate);
        });
    }
  }

  /**
   * Returns a candidate by the candidate id.
   * @param candidateId
   */
  private getCandidateByCandidateId(candidateId): Observable<any> {
    return this.candidateService.getCandidate(candidateId).pipe(
      tap(
        (candidate) => {
          if (candidate.candidateType && candidate.candidateType === Candidate.ASSOCIATE_UPC) {
            this.setOriginalAndCurrentCandidate(candidate);
            this.resetErrors();
          } else {
            const message = 'Invalid Candidate Task Flow : "' + candidate.candidateType + '" expected "' +
              Candidate.ASSOCIATE_UPC + '"';
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: message, growlToUse: GrowlService.SEVERITY_ERROR}
            });
            return observableThrowError(message);
          }
        }
      ),
      catchError(
        (error) => {
          // if there was an error retrieving task, route back to tasks page with the error
          this.router.navigate(['/tasks'], {
            queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
          });
          return observableThrowError(error);

        })
    );
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = JSON.parse(JSON.stringify(candidate));
    this.candidate = JSON.parse(JSON.stringify(candidate));
  }

  public getSetupAssociateUpcError(): CandidateError {
    return this.setupAssociateUpcError;
  }

  public setSetupAssociateUpcError(candidateError: CandidateError): CandidateError {
    this.setupAssociateUpcError = candidateError;
    return this.setupAssociateUpcError;
  }

  public getAssociateUpcBasicDetailError(): CandidateError {
    return this.associateUpcDetailsError;
  }

  public setAssociateUpcBasicDetailError(candidateError: CandidateError): CandidateError {
    this.associateUpcDetailsError = candidateError;
    return this.associateUpcDetailsError;
  }

  public getAssociateCasePackDetailsError(): CandidateError {
    return this.associateCasePackDetailsError;
  }

  public setAssociateCasePackDetailsError(candidateError: CandidateError): CandidateError {
    this.associateCasePackDetailsError = candidateError;
    return this.associateCasePackDetailsError;
  }

  public getAssociateExtendedAttributesError(): CandidateError {
    return this.associateExtendedAttributesError;
  }

  public setAssociateExtendedAttributesError(candidateError: CandidateError): CandidateError {
    this.associateExtendedAttributesError = candidateError;
    return this.associateExtendedAttributesError;
  }

  getAllCandidateErrors(): CandidateError[] {
    return [this.getSetupAssociateUpcError(), this.getAssociateUpcBasicDetailError(),
      this.getAssociateCasePackDetailsError(), this.getAssociateExtendedAttributesError()];
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param growlMessage Message to display after routing to task page.
   */
  public saveAndCompleteTaskAndRouteToUrl(action: string, taskDecision: TaskDecision, url: string, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.completeTaskAndRouteToTasksPage(action, taskDecision, url, growlMessage);
    });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param growlMessage Message to display after routing to task page.
   */
  public completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, url: string, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate([url], { queryParams: { growlMessage: growlMessage } });
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  /**
   * Saves candidate.
   */
  saveCandidateAndClose() {
    if (this.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          this.router.navigate(['/tasks']);
        });
      } else {
        this.router.navigate(['/tasks']);
      }
    }
  }

  scrollToTop() {
    document.getElementsByClassName('pm-editor-header')[0].scrollIntoView();
  }

  getCandidateProductsLength() {
    return this.candidate.candidateProducts.length;
  }

  addCandidateProduct(product: CandidateProduct) {
    this.candidate.candidateProducts.push(product);
  }

  deleteCandidateProduct(product: CandidateProduct) {
    for (let x = 0; x < this.candidate.candidateProducts.length; x++) {
      if (this.candidate.candidateProducts[x].upc &&
        this.candidate.candidateProducts[x].upc === product.upc) {
        this.candidate.candidateProducts.splice(x, 1);
        break;
      } else if (!this.candidate.candidateProducts[x].upc) {
        this.candidate.candidateProducts.splice(x, 1);
        break;
      }
    }
  }
  /**
   * sets task id.
   */
  public setTaskId(taskId) {
    this.taskId = taskId;
  }

  public updatePageErrors(candidateError) {
    this.setupAssociateUpcError = JSON.parse(JSON.stringify(candidateError));
    this.associateUpcDetailsError = JSON.parse(JSON.stringify(candidateError));
    this.associateCasePackDetailsError = JSON.parse(JSON.stringify(candidateError));
    this.associateExtendedAttributesError = JSON.parse(JSON.stringify(candidateError));
  }

  public initializeExtendedAttributesIsViewing() {
    if (!this.candidate?.candidateProducts?.length) {
      return;
    }
    // set the first none searched candidate product to true, and the rest to false;
    for (let x = 0; x < this.candidate.candidateProducts.length; x++) {
      this.candidate.candidateProducts[x].isViewing = x === 1;
    }
  }
}
