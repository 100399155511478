import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeTypes} from 'pm-components';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';
import {CostService} from '../../../../../../src/app/2.0.0/service/cost.service';
import {EditCandidateModalService} from '../../../../../../src/app/2.0.0/service/edit-candidate-modal.service';
import {Candidate, CandidateError, CandidateProduct} from 'pm-models';
import {UploadCandidateService} from '../../../../../../src/app/2.0.0/service/upload-candidate.service';
import {UploadCandidateCaseConstants} from '../../../../../../src/app/2.0.0/utils/constants/upload-candidate-case-constants';
import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';
import {CandidateErrorUtilService} from '../../../../../../src/app/2.0.0/service/candidate-error-util.service';
import {EditCandidateUtilService} from '../../../../../../src/app/2.0.0/service/edit-candidate-util.service';

@Component({
  selector: 'pm-upload-case-table',
  templateUrl: './upload-case-table.component.html',
  styleUrls: ['./upload-case-table.component.scss']
})
export class UploadCaseTableComponent implements OnInit {

  @Input() candidates: Candidate[];
  @Input() candidateType: string;
  @Input() pageSize: number = 50;
  @Input() totalNumRecords: number;
  @Input() candidateProductIndex;
  @Input() isBuyer: boolean = false;
  @Input() isSca: boolean = false;
  @Input() workflowState: string;
  @Input() isWarehouse = false;
  @Input() candidateIdToCandidateErrorMap: Map<number, CandidateError>;

  @Output() loadData = new EventEmitter<any>();
  @Output() candidatesChange = new EventEmitter<any>();


  caseHeaderColumns: any[];

  public COST_COLUMN_GROUP_LENGTH;
  public MASTER_PACK_COLUMN_GROUP_LENGTH;
  public INNER_PACK_COLUMN_GROUP_LENGTH;
  public CASE_DETAILS_COLUMN_GROUP_LENGTH;
  public DISPLAY_READY_UNIT_COLUMN_GROUP_LENGTH;
  public OMI_REMARKS_COLUMN_GROUP_LENGTH;

  constructor(public candidateUtils: CandidateUtilService, public costService: CostService,
              private editCandidateModalService: EditCandidateModalService, private candidateService: CandidateService,
              public growlService: GrowlService, private uploadCandidateService: UploadCandidateService,
              public candidateErrorUtils: CandidateErrorUtilService, private editCandidateUtilService: EditCandidateUtilService) { }



  ngOnInit() {
    if (this.candidateType) {
      this.caseHeaderColumns = this.uploadCandidateService.getCaseColumnsFromCandidateType(this.candidateType, this.isWarehouse);
    } else if (this.workflowState) {
      this.caseHeaderColumns = this.uploadCandidateService.getCaseColumnsFromWorkflowState(this.workflowState);
    }
    // initialize columns group lengths.
    this.COST_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.caseHeaderColumns,
      UploadCandidateCaseConstants.COST_COLUMN_GROUP);

    this.MASTER_PACK_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.caseHeaderColumns,
      UploadCandidateCaseConstants.MASTER_PACK_COLUMN_GROUP);

    this.INNER_PACK_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.caseHeaderColumns,
      UploadCandidateCaseConstants.INNER_PACK_COLUMN_GROUP);

    this.CASE_DETAILS_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.caseHeaderColumns,
      UploadCandidateCaseConstants.CASE_DETAILS_COLUMN_GROUP);

    this.DISPLAY_READY_UNIT_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.caseHeaderColumns,
      UploadCandidateCaseConstants.DISPLAY_READY_UNIT_COLUMN_GROUP);

    this.OMI_REMARKS_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.caseHeaderColumns,
      UploadCandidateCaseConstants.OMI_REMARKS_COLUMN_GROUP);

  }


  editProductUPC(index) {
    if (!this.isSca) {
      this.showEditCandidateModal(index, AttributeTypes.ProductUPC, {
        validationService: this.candidateService
      });
    }
  }

  editCaseUPC(index) {
    if (!this.isSca) {
      this.showEditCandidateProductModal(index, AttributeTypes.CaseUPC, {
        validationService: this.candidateService
      });
    }
  }

  showEditCandidateModal(index, type: AttributeTypes,  overrides?: any) {
    if (!this.isSca && type !== this.attributeType.OrderFactor) {
      const tempCandidate = JSON.parse(JSON.stringify(this.candidates[index]));
      this.editCandidateModalService.openModal(type, tempCandidate, overrides).subscribe(response => {
        if (response) {
          // Dispatch Update
          this.candidates[index] = response;
        }
      });
    }
  }

  get attributeType() {
    return AttributeTypes;
  }

  editCostLink(index, isEditAll?: boolean) {
    this.editCandidateModalService.openModal(AttributeTypes.CostLinkedItem, isEditAll ? new Candidate() : this.candidates[index],
      {
        validationService: this.candidateService,
        isApplyToAll: isEditAll
      })
      .subscribe(response => {
        if (response) {
          if (isEditAll) {
            this.candidates.forEach((candidate) => {
              candidate.costLinkFromServer = response.costLinkFromServer;
              candidate.costLink = response.costLink;
              candidate.costLinkBy = response.costLinkBy;
              this.costService.setInnerListCost(candidate);
            });
          } else {
            this.candidates[index] = response;
            this.costService.setInnerListCost(this.candidates[index]);
          }
        }
      });
  }

  areMasterPackValuesSet(candidate: Candidate): boolean {
    return this.isNumberSetAndNotZero(candidate.masterLength) &&
      this.isNumberSetAndNotZero(candidate.masterWidth) &&
      this.isNumberSetAndNotZero(candidate.masterHeight) &&
      this.isNumberSetAndNotZero(candidate.masterWeight);
  }

  isNumberSetAndNotZero (value: any) {
    return value !== undefined && value !== null && value !== 0 && value !== '' && value !== '0';
  }

  showEditCandidateProductModal(index, type: AttributeTypes,  overrides?: any) {
    if (!this.isSca) {
      const candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(this.candidates[index]);
      this.editCandidateModalService.openModal(type, this.candidates[index].candidateProducts[candidateProductIndex], overrides)
        .subscribe(response => {
          if (response) {
            // Dispatch Update
            this.candidates[index].candidateProducts[candidateProductIndex] = response;
          }
        });
    }
  }

  editIsDru(index, isEditAll?: boolean) {
    if (!this.isSca) {
      this.editCandidateModalService.openModal(AttributeTypes.DisplayReadyUnit, isEditAll ? new Candidate() :
        JSON.parse(JSON.stringify(this.candidates[index])), isEditAll ? {isApplyToAll: isEditAll} : null).subscribe(
        response => {
          if (response) {
            if (isEditAll) {
              this.candidates.forEach((candidate) => {
                candidate.displayReadyUnit = response.displayReadyUnit;
                candidate.displayReadyUnitOrientation = response.displayReadyUnitOrientation;
                candidate.displayReadyUnitRowsHigh = 0;
                candidate.displayReadyUnitRowsFacing = 0;
                candidate.displayReadyUnitRowsDeep = 0;
                candidate.displayReadyUnitType = response.displayReadyUnitType;
              });
            } else {
              // Dispatch Update
              this.candidates[index] = response;
              this.candidates[index].displayReadyUnitRowsHigh = 0;
              this.candidates[index].displayReadyUnitRowsFacing = 0;
              this.candidates[index].displayReadyUnitRowsDeep = 0;
            }
          }
        });
    }
  }

  editWeightType(index, itemWeightType, isEditAll?: boolean) {
    if (!this.isBuyer && !this.isSca) {
      if (isEditAll) {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, itemWeightType,  ['itemWeightType'], this.isSca);
      } else {
        this.showEditCandidateModal(index, itemWeightType);
      }
    }
  }

  get uploadCandidateCaseConstants() {
    return UploadCandidateCaseConstants;
  }

  hasColumn(column): boolean {
    return this.caseHeaderColumns.includes(column);
  }

  onClickHeader(columnHeader) {
    switch (columnHeader) {
      case UploadCandidateCaseConstants.COST_LINKED: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.CostLinked, ['costLinked', 'costLink', 'costLinkBy'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.COST_LINKED_ITEM: {
        this.editCostLink(null, true);
        break;
      }
      case UploadCandidateCaseConstants.MASTER_LIST_COST: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.MasterListCost, ['masterListCost'], this.isSca);
        break;
      }

      case UploadCandidateCaseConstants.SOLD_BY_WEIGHT: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.SoldByWeight, ['weightSwitch'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.TotalRetail, ['masterPack'], false);
        break;
      }
      case UploadCandidateCaseConstants.MASTER_PACK_WEIGHT: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.Weight, ['masterWeight'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.MASTER_PACK_DIMENSIONS: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.Dimensions, ['masterWidth', 'masterHeight', 'masterLength'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.ORDER_FACTOR: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.OrderFactor, ['cubeAdjustedFactor'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.BOXES_ON_PALLET: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.BoxesOnPallet, ['vendorTie', 'vendorTier'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.INNER_PACKS: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.InnerPackSelected, ['innerPackSelected'], false);
        break;
      }
      case UploadCandidateCaseConstants.INNER_PACK_QUANTITY: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.InnerPackQuantity, ['innerPack'], false);
        break;
      }
      case UploadCandidateCaseConstants.INNER_PACK_DIMENSIONS: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.InnerPackDimensions,
          ['innerWidth', 'innerHeight', 'innerLength'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.INNER_PACK_WEIGHT: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.InnerPackWeight, ['innerWeight'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.CASE_DESCRIPTION: {
        this.editCandidateUtilService.showBulkEditAllCandidateProductModal(this.candidates, this.attributeType.CaseDescription, ['caseDescription'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE: {
        this.editCandidateUtilService.showBulkEditAllCandidateProductModal(this.candidates, this.attributeType.VendorProductCode, ['vendorProductCode'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN: {
        this.editCandidateUtilService.showBulkEditAllCandidateProductModal(this.candidates, this.attributeType.CountryOfOrigin, ['countryOfOrigin'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.WEIGHT_TYPE: {
        this.editWeightType(null, this.attributeType.ItemWeightType, true);
        break;
      }
      case UploadCandidateCaseConstants.CODE_DATE: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.CodeDate, ['codeDate', 'maxShelfLife', 'inboundSpecDays'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.MAX_SHELF_LIFE: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.MaxShelfLife, ['maxShelfLife'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.INBOUND_SPEC_DAYS: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.InboundSpecDays, ['inboundSpecDays'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.REACTION_DAYS: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.ReactionDays, ['warehouseReactionDays'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.GUARANTEE_TO_STORE_DAYS: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.GuaranteeDays, ['guaranteeToStoreDays'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.DISPLAY_READY_UNIT: {
        this.editIsDru(null, true);
        break;
      }
      case UploadCandidateCaseConstants.DRU_RETAIL_UNITS: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.RetailUnitCount,
          ['displayReadyUnitRowsFacing', 'displayReadyUnitRowsHigh', 'displayReadyUnitRowsDeep'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.DRU_ORIENTATION: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.Orientation, ['displayReadyUnitOrientation'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.DRU_TYPE: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.DisplayReadyUnitType, ['displayReadyUnitType'], this.isSca);
        break;
      }
      case UploadCandidateCaseConstants.OMI_REMARK_1: // none atm;
      case UploadCandidateCaseConstants.OMI_REMARK_2: // none atm;
      case UploadCandidateCaseConstants.CASE_UPC:
      case UploadCandidateCaseConstants.UNIT_UPC:
      case UploadCandidateCaseConstants.INNER_LIST_COST:
      case UploadCandidateCaseConstants.DEAL_OFFERED: // none atm;
      case UploadCandidateCaseConstants.UNIT_COST:
      case UploadCandidateCaseConstants.HEB_MARGIN:
      case UploadCandidateCaseConstants.HEB_PENNY_PROFIT: {
        console.log('Invalid edit all selection: ' + columnHeader.header);
        break;
      }
      default: {
        this.growlService.addError('Edit all column not implemented: ' + columnHeader.header);
      }
    }
  }
}
