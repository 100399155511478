import {Component, OnInit, ViewChild} from '@angular/core';
import {SetUpStoresOverlayComponent} from '../../set-up-stores-overlay/set-up-stores-overlay.component';
import {ReviewComponent} from 'pm-components';
import {
  AttributeConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError, CandidateProduct,
  CandidateProductError, CandidateValidatorType, InvitedSupplier,
  LocationGroupStores,
  Product, Store,
  Task, TaskDecision,
  Upc,
  Vendor
} from 'pm-models';
import {WorkflowService} from '../../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {ProductService} from '../../service/product.service';
import {CostService} from '../../service/cost.service';
import {GrowlService} from '../../growl/growl.service';
import {SupplierProductService} from '../../service/supplier-product.service';
import {EmailService} from '../../service/email.service';
import {switchMap, tap} from 'rxjs/operators';
import {UUID} from 'angular2-uuid';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateErrorUtilService} from '../../service/candidate-error-util.service';
import {InvitedDistributorService} from '../../service/invited-distributor.service';

@Component({
  selector: 'app-pia-new-invited-distributor-review',
  templateUrl: './pia-new-invited-distributor-review.component.html',
  styleUrls: ['./pia-new-invited-distributor-review.component.scss']
})
export class PiaNewInvitedDistributorReviewComponent implements OnInit {

  @ViewChild(SetUpStoresOverlayComponent) setupStoreOverlay: SetUpStoresOverlayComponent;

  @ViewChild(ReviewComponent) pmReview;

  public PIA_TASK_NAME = 'PIA Review Dsd Invited Suppliers';
  private PIA_NEW_PRODUCT_TASK_NAME = 'PIA New Product Flow';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  private DSD_INVITED_SUPPLIER_WORKFLOW = 'dsdInvitedSupplierProcess';

  private taskSubscription$: any;
  private task: Task;
  public canClickSave: boolean = true;
  public isRejectDisabled = false;
  public isApproveDisabled = false;
  public isShowingStorePanel = false;

  public isViewingNotSubmittedCandidates = false;
  public isViewingSubmittedCandidates = false;
  public isViewingApprovedCandidates = false;
  public isViewingActivatedCandidates = false;

  selectedConfiguration: AttributeConfig = {
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'allGroupsId',
    defaultValue: false
  };

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute, public router: Router,
              public candidateService: CandidateService, private lookupService: LookupService,
              private productService: ProductService, public costService: CostService, private growlService: GrowlService,
              public supplierProductService: SupplierProductService, public emailService: EmailService,
              public candidateErrorUtils: CandidateErrorUtilService,
              public invitedDistributorService: InvitedDistributorService) { }


  ngOnInit() {
    this.invitedDistributorService.resetService();
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.PIA_TASK_NAME &&
              this.task.name !== this.PIA_NEW_PRODUCT_TASK_NAME) {
              this.router.navigate(
                ['/tasks'],
                {
                  queryParams:
                    {
                      growlMessage: 'Candidate is not in ' + this.PIA_TASK_NAME + ' status.',
                      growlToUse: GrowlService.SEVERITY_ERROR
                    }
                }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                this.setInitialValues(candidate);
              });
          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }


  private async setInitialValues(candidate: Candidate) {
    this.invitedDistributorService.setOriginalAndCurrentCandidate(candidate);
    await this.invitedDistributorService.initializeProductData().toPromise();
    await this.invitedDistributorService.initializeActivatedDistributorData();
    this.invitedDistributorService.createVendorToAuthGroupsMap();
    this.invitedDistributorService.candidateError = new CandidateError();
    this.invitedDistributorService.candidateProductError =
      this.invitedDistributorService.candidateError.candidateProductErrors[this.invitedDistributorService.candidate.candidateProducts[0].id];

    this.candidateService.findDistributorCandidatesByUpc(this.invitedDistributorService.candidate.candidateProducts[0].upc).subscribe((candidates) => {
      if (!candidates || candidates.length === 0) {
        this.growlService.addError('Unable to locate parent candidate.');
        return;
      }
      this.invitedDistributorService.allDsdCandidates = candidates;
      this.invitedDistributorService.findOtherInvitedCandidates();
    });
  }

  onClose() {
    this.candidateService.saveCandidate(this.invitedDistributorService.candidate, true).subscribe(() => {
      this.router.navigate(['/tasks']).then();
    });
  }

  showStorePanel(event, panel, target, candidate: Candidate) {
    event.stopPropagation();
    panel.show(event, target);
    this.isShowingStorePanel = true;
    this.supplierProductService.resetService();
    this.supplierProductService.setTaskId(this.task.id);
    this.supplierProductService.setOriginalAndCurrentCandidate(candidate);
    this.isShowingStorePanel = true;
    this.setupStoreOverlay.initializeData();
  }

  saveClicked(candidate: Candidate, panel) {
    this.canClickSave = false;
    this.invitedDistributorService.vendorApToAuthGroupsMap.delete(candidate.vendor.apNumber);
    this.invitedDistributorService.findAndAddToVendorToAuthGroupsMapByCandidate(candidate);
    this.candidateService.validateCandidate(candidate,
      [CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR]).toPromise().then(() => {
      this.invitedDistributorService.candidateError = new CandidateError();
      this.invitedDistributorService.candidateProductError =
        this.invitedDistributorService.candidateError.candidateProductErrors[this.invitedDistributorService.candidate.candidateProducts[0].id];
      this.candidateService.saveCandidate(candidate).subscribe(savedCandidate => {

        this.invitedDistributorService.setSavedCandidate(savedCandidate);
        panel.hide();
        this.canClickSave = true;
      });
    }, (error) => {
      this.canClickSave = true;
      this.supplierProductService.scrollToTop();
      if (error.error.candidateErrors.hasErrors) {
        this.supplierProductService.updatePageErrors(error.error.candidateErrors);
        this.invitedDistributorService.candidateError = this.supplierProductService.getStoreAuthError();
        this.invitedDistributorService.candidateProductError =
          this.supplierProductService.getCurrentCandidateProductError(this.invitedDistributorService.candidateError);
      }
    });
  }

  removeInvitedSupplier(candidate: Candidate) {
    this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId).subscribe((task) => {
      if (task.name === 'Dsd Invited Supplier') {
        this.removeNotSubmittedCandidate(candidate, task);
      } else {
        this.growlService.addError('Cannot delete task: ' + task.name + '. Please refresh and try again.');
      }
    });
  }

  /**
   * Saves the current state of a candidate with reject reason, and completes the task.
   */
  private removeNotSubmittedCandidate(candidate: Candidate, task) {
    candidate.vendorComment = 'Declined by Buyer.';
    candidate.status = Candidate.DECLINED;
    this.candidateService.saveCandidate(candidate, true).subscribe(() => {
      this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE, TaskDecision.INVITED_DISTRIBUTOR_VENDOR_REJECT)
        .subscribe(() => {
          this.invitedDistributorService.removeNotSubmittedCandidateFromLists(candidate.candidateId,
            this.invitedDistributorService.notSubmittedCandidates, this.invitedDistributorService.allDsdCandidates);
        }, (error) => {
          this.growlService.addError(error);
        });
    }, (error) => {
      this.growlService.addError(error);
    });
  }

  async rejectSelected() {

    const candidateIds = this.invitedDistributorService.getSelectedCandidateIds();

    if (this.invitedDistributorService.hasAllSelectedSuppliers()) {
      this.rejectAll();
      return;
    }

    this.isRejectDisabled = true;
    this.isApproveDisabled = true;
    const message = 'Declined by procurement support';
    let removingParentCandidate = false;

    // remove from invited candidates list (displayed invites)
    for (let x = 0; x < candidateIds.length; x++) {
      let candidate;
      for (let y = 0; y < this.invitedDistributorService.invitedCandidates.length; y++) {
        if (candidateIds[x] === this.invitedDistributorService.invitedCandidates[y].candidateId) {
          candidate = this.invitedDistributorService.invitedCandidates[y];
          candidate.vendorComment = message;
          candidate.status = Candidate.DECLINED;
          if (candidateIds[x] !== this.invitedDistributorService.candidate.candidateId) {
            await this.saveRejectedChildCandidateAndRejectWorkflow(candidate);
          } else {
            removingParentCandidate = true;
            const parentCandidate = await this.candidateService.saveCandidate(candidate).toPromise();
            this.invitedDistributorService.setOriginalAndCurrentCandidate(parentCandidate);
          }
          this.invitedDistributorService.invitedCandidates.splice(y, 1);
          break;
        }
      }
    }
    // remove from all candidates list.
    for (let x = 0; x < candidateIds.length; x++) {
      for (let y = 0; y < this.invitedDistributorService.allDsdCandidates.length; y++) {
        if (candidateIds[x] === this.invitedDistributorService.allDsdCandidates[y].candidateId) {
          this.invitedDistributorService.allDsdCandidates.splice(y, 1);
          break;
        }
      }
    }

    // // remove from invitees.
    for (let x = 0; x < candidateIds.length; x++) {
      if (candidateIds[x] === this.invitedDistributorService.candidate.candidateId) {
        continue;
      }
      for (let y = 0; y < this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.length; y++) {
        if (candidateIds[x] === this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[y].candidateId) {
          this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.splice(y, 1);
          break;
        }
      }
    }

    // // if we're removing the parent candidate, reassign workflow and remaining invitees to a new candidate.
    if (removingParentCandidate) {
      const invitedSupplier = this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.pop();

      let candidate;
      for (let x = 0; x < this.invitedDistributorService.invitedCandidates.length; x++) {
        if (invitedSupplier.candidateId === this.invitedDistributorService.invitedCandidates[x].candidateId) {
          candidate = this.invitedDistributorService.invitedCandidates[x];
          break;
        }
      }

      candidate.candidateProducts[0].invitedSuppliers
        = JSON.parse(JSON.stringify(this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers));

      // find old task associated with the candidate to be the new parent, set it to the old parent and reject.
      // the current task will get the new parent's candidate id.
      this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId).subscribe((task) => {
        this.workflowService.updateCandidateId(this.invitedDistributorService.candidate.candidateId, task.processInstanceId).subscribe(async () => {
          await this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
            TaskDecision.DISTRIBUTOR_WAITING_REJECT).toPromise();
          // update current task with new candidate id
          this.workflowService.updateCandidateId(candidate.candidateId, this.task.processInstanceId).subscribe(() => {
            this.candidateService.saveCandidate(candidate, true).subscribe((savedCandidate) => {
              this.isRejectDisabled = false;
              this.isApproveDisabled = false;
              this.invitedDistributorService.isSelectingDistributors = false;
              this.invitedDistributorService.setOriginalAndCurrentCandidate(savedCandidate);
            });
          });
        });
      });
    } else {
      this.candidateService.saveCandidate(this.invitedDistributorService.candidate, true).subscribe((savedCandidate) => {
        this.isRejectDisabled = false;
        this.isApproveDisabled = false;
        this.invitedDistributorService.isSelectingDistributors = false;
        this.invitedDistributorService.setOriginalAndCurrentCandidate(savedCandidate);
      });
    }
  }



  approveSelected() {
    this.isApproveDisabled = true;
    this.isRejectDisabled = true;
    if (this.invitedDistributorService.hasAllSelectedSuppliers()) {
      this.approveAll();
      return;
    }
    if (this.invitedDistributorService.isParentSelected()) {
      const candidatesToValidate = [];
      const validationCandidate: Candidate = JSON.parse(JSON.stringify(this.invitedDistributorService.candidate));
      for (let x = 0; x < this.invitedDistributorService.invitedCandidates.length; x++) {
        if (this.invitedDistributorService.invitedCandidates[x].selected) {
          candidatesToValidate.push(this.invitedDistributorService.invitedCandidates[x]);
          continue;
        }
        // find and remove not selected from validation candidate.
        for (let y = 0; y < validationCandidate.candidateProducts[0].invitedSuppliers.length; y++) {
          if (validationCandidate.candidateProducts[0].invitedSuppliers[y].candidateId ===
            this.invitedDistributorService.invitedCandidates[x].candidateId) {
            validationCandidate.candidateProducts[0].invitedSuppliers.splice(y, 1);
            break;
          }
        }
      }
      this.candidateService.validateBulkCandidate(candidatesToValidate,
        [CandidateValidatorType.ADDITIONAL_DISTRIBUTOR_REVIEW_VALIDATOR]).subscribe(() => {
        this.candidateService.activateCandidate(this.invitedDistributorService.candidate).subscribe(() => {
          this.submitCurrentParentCreateNewParentCandidateAndMoveToPia();
        }, (error) => {
          this.growlService.addError(error);
          this.isApproveDisabled = false;
          this.isRejectDisabled = false;
        });
      }, (error) => {
        if (error.error.candidateErrors) {
          this.invitedDistributorService.candidateError = error.error.candidateErrors;
          this.invitedDistributorService.candidateProductError =
            this.invitedDistributorService.candidateError.candidateProductErrors[validationCandidate.candidateProducts[0].id];
          this.isApproveDisabled = false;
          this.isRejectDisabled = false;
          this.invitedDistributorService.setCandidateIdToGroupStoreListMap();
        }
      });
    } else {
      const childCandidatesToValidate = [];
      let validationCandidate: Candidate;
      for (let x = 0; x < this.invitedDistributorService.invitedCandidates.length; x++) {
        if (this.invitedDistributorService.invitedCandidates[x].candidateId !== this.invitedDistributorService.candidate.candidateId) {
          validationCandidate = JSON.parse(JSON.stringify(this.invitedDistributorService.invitedCandidates[x]));
          break;
        }
      }
      const selectedIds = this.invitedDistributorService.getSelectedCandidateIds();
      for (let x = 0; x < this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.length; x++) {
        for (let y = 0; y < selectedIds.length; y++) {
          if (this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[x].candidateId !== validationCandidate.candidateId &&
            this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[x].candidateId === selectedIds[y]) {
            validationCandidate.candidateProducts[0].invitedSuppliers.push(
              this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[x]);
            childCandidatesToValidate.push(this.invitedDistributorService.candidate);
          }

        }
      }
      this.candidateService.validateBulkCandidate(childCandidatesToValidate,
        [CandidateValidatorType.ADDITIONAL_DISTRIBUTOR_REVIEW_VALIDATOR]).subscribe(() => {

        this.candidateService.activateCandidate(this.invitedDistributorService.candidate).subscribe(() => {

          this.createNewParentCandidateAndMoveWaitingTaskToComplete();
        }, (error) => {
          this.growlService.addError(error);
          this.isApproveDisabled = false;
          this.isRejectDisabled = false;
        });
      }, (error) => {
        if (error.error.candidateErrors) {
          this.invitedDistributorService.candidateError = error.error.candidateErrors;
          this.invitedDistributorService.candidateProductError =
            this.invitedDistributorService.candidateError.candidateProductErrors[validationCandidate.candidateProducts[0].id];
          this.isApproveDisabled = false;
          this.isRejectDisabled = false;
          this.invitedDistributorService.setCandidateIdToGroupStoreListMap();
        }
      });
    }
  }



  async createNewParentCandidateAndMoveWaitingTaskToComplete() {
    const selectedIds = this.invitedDistributorService.getSelectedCandidateIds();
    let newParent: Candidate;
    let invitedSuppliers = [];
    for (let x = 0; x < selectedIds.length; x++) {
      for (let y = 0; y < this.invitedDistributorService.invitedCandidates.length; y++) {
        if (selectedIds[x] === this.invitedDistributorService.invitedCandidates[y].candidateId) {
          newParent = this.invitedDistributorService.invitedCandidates.slice(y)[0];
          break;
        }
      }
      if (newParent) {
        break;
      }
    }
    for (let x = 0; x < selectedIds.length; x++) {
      for (let y = 0; y < this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.length; y++) {
        // simply remove the parent from invited suppliers, and the rest of selected add to the new list for the candidate.
        if (this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[y].candidateId === newParent.candidateId) {
          this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.splice(y, 1);
        } else if (selectedIds[x] === this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[y].candidateId) {
          invitedSuppliers = invitedSuppliers.concat(this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.splice(y, 1));
          break;
        }
      }
    }
    newParent.candidateProducts[0].invitedSuppliers = invitedSuppliers;

    // update current display by removing from current, and putting selected into the pia submitted.
    this.invitedDistributorService.approvedCandidates = this.invitedDistributorService.approvedCandidates.concat(
      this.invitedDistributorService.removeSelectedInvitedCandidates());

    // save current parent candidate.
    this.candidateService.saveCandidate(this.invitedDistributorService.candidate).subscribe((savedCandidate) => {
      this.invitedDistributorService.setOriginalAndCurrentCandidate(savedCandidate);
    });

    for (let x = 0; x < newParent.candidateProducts[0].invitedSuppliers.length; x++) {
      await this.saveAndCompleteCurrentChildCandidates(newParent.candidateProducts[0].invitedSuppliers[x]);
    }

    // save new parent to be submitted
    this.candidateService.saveCandidate(newParent).subscribe(() => {
      this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(newParent.candidateId).subscribe((task) => {
        this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.DISTRIBUTOR_WAITING_APPROVE)
          .subscribe(() => {
            this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.invitedDistributorService.candidate.candidateId)
              .subscribe((newCurrentTask) => {
                this.workflowService.updateApNumber(this.invitedDistributorService.candidate.vendor.apNumber, newCurrentTask.processInstanceId).subscribe();
                this.isApproveDisabled = false;
                this.isRejectDisabled = false;
                this.invitedDistributorService.isSelectingDistributors = false;
              });
          });
      });
    });
  }

  approveAll() {
    this.isApproveDisabled = true;
    this.isRejectDisabled = true;

    this.candidateService.validateCandidate(this.invitedDistributorService.candidate,
      [CandidateValidatorType.INVITED_DISTRIBUTOR_REVIEW_VALIDATOR]).subscribe(() => {
      this.candidateService.saveCandidate(this.invitedDistributorService.candidate).subscribe(savedCandidate => {
        this.candidateService.activateCandidate(this.invitedDistributorService.candidate).subscribe(() => {
          this.invitedDistributorService.setOriginalAndCurrentCandidate(savedCandidate);
          this.completeTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE, TaskDecision.INVITED_DISTRIBUTOR_PIA_APPROVE_DECISION, 'Successfully completed task.');
        }, (error) => {
          this.growlService.addError(error);
          this.isApproveDisabled = false;
          this.isRejectDisabled = false;
        });
      });
    }, (error) => {
      if (error.error.candidateErrors) {
        this.invitedDistributorService.updatePageErrors(error.error.candidateErrors);
        this.invitedDistributorService.candidateError = error.error.candidateErrors;
        this.invitedDistributorService.candidateProductError =
          this.invitedDistributorService.candidateError.candidateProductErrors[this.invitedDistributorService.candidate.candidateProducts[0].id];
        this.isApproveDisabled = false;
        this.isRejectDisabled = false;
        this.invitedDistributorService.setCandidateIdToGroupStoreListMap();
      }
    });
  }

  async rejectAll() {
    this.isApproveDisabled = true;
    this.isRejectDisabled = true;
    const message = 'Declined by Procurement Support';
    let candidate;
    for (let x = 0; x < this.invitedDistributorService.invitedCandidates.length; x++) {
      candidate = this.invitedDistributorService.invitedCandidates[x];
      candidate.vendorComment = message;
      candidate.status = Candidate.DECLINED;

      // if is the parent candidate, ony update the status for now (not the workflow till the end)
      if (this.invitedDistributorService.candidate.candidateId === candidate.candidateId) {
        await this.candidateService.saveCandidate(candidate).toPromise();
      } else {
        await this.saveRejectedChildCandidateAndRejectWorkflow(candidate);
      }

      if (x === this.invitedDistributorService.invitedCandidates.length - 1) {

        this.workflowService.completeTaskWithAction(this.task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.INVITED_DISTRIBUTOR_PIA_REJECT_DECISION).subscribe(() => {
          this.router.navigate(['/tasks'], { queryParams: { growlMessage: 'Successfully rejected candidate.' }
          }).then(() => {
            this.isApproveDisabled = false;
            this.isRejectDisabled = false;
            this.invitedDistributorService.isSelectingDistributors = false;
          });
        }, (error) => {
          this.growlService.addError(error);
          this.isRejectDisabled = false;
          this.isApproveDisabled = false;
          this.invitedDistributorService.isSelectingDistributors = false;
        });
      }
    }
  }

  async saveRejectedChildCandidateAndRejectWorkflow(candidate: Candidate) {
    this.candidateService.saveCandidate(candidate).subscribe(() => {
      this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId).subscribe((task) => {
        return this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.DISTRIBUTOR_WAITING_REJECT).toPromise();
      });
    });
  }

  /**
   * if the parent candidate is selected, remove non selected candidates from the parent candidate and complete current task,
   * and remaining child tasks.
   * Pick the first non selected as the new parent, assigning remaining as the children, and move parents task to buyer.
   */
  async submitCurrentParentCreateNewParentCandidateAndMoveToPia() {
    const notSelectedCandidates: Candidate[] = this.invitedDistributorService.getNotSelectedCandidates();
    let newParent: Candidate;
    for (let x = 0; x < notSelectedCandidates.length; x++) {
      if (notSelectedCandidates[x].candidateId !== this.invitedDistributorService.candidate.candidateId) {
        newParent = notSelectedCandidates.slice(x, 1)[0];
        break;
      }
    }

    let notSelectedInvitedSuppliers = [];

    //  find not selected invited suppliers to be associated w/ new task.
    for (let x = 0; x < notSelectedCandidates.length; x++) {
      for (let y = 0; y < this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.length; y++) {
        if (notSelectedCandidates[x].candidateId === this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[y].candidateId) {
          notSelectedInvitedSuppliers = notSelectedInvitedSuppliers.concat(
            this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.splice(y, 1));
          break;
        }
      }
    }
    // remove parent from invited suppliers list.
    for (let x = 0; x < notSelectedInvitedSuppliers.length; x++) {
      if (notSelectedInvitedSuppliers[x].candidateId === newParent.candidateId) {
        notSelectedInvitedSuppliers.splice(x, 1);
        break;
      }
    }
    newParent.candidateProducts[0].invitedSuppliers = notSelectedInvitedSuppliers;

    // update current display by removing from current, and putting selected into the approved.
    this.invitedDistributorService.approvedCandidates = this.invitedDistributorService.approvedCandidates.concat(
      this.invitedDistributorService.removeSelectedInvitedCandidates());

    // save and complete current selected child candidates.
    for (let x = 0; x < this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.length; x++) {
      await this.saveAndCompleteCurrentChildCandidates(this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[x]);
    }
    // save and complete current candidate
    await this.candidateService.saveCandidate(this.invitedDistributorService.candidate).toPromise();
    await this.workflowService.completeTaskWithAction(this.task, WorkflowService.ACTION_COMPLETE,
      TaskDecision.INVITED_DISTRIBUTOR_PIA_APPROVE_DECISION).toPromise();


    this.candidateService.saveCandidate(newParent).subscribe((newCandidate) => {
      this.invitedDistributorService.setOriginalAndCurrentCandidate(newCandidate);
      this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.invitedDistributorService.candidate.candidateId).subscribe((task) => {
        this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.DISTRIBUTOR_WAITING_CONTINUE_TO_PIA)
          .subscribe(() => {
            this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.invitedDistributorService.candidate.candidateId)
              .subscribe((newCurrentTask) => {
                this.task = newCurrentTask;
                this.workflowService.updateApNumber(this.invitedDistributorService.candidate.vendor.apNumber, this.task.processInstanceId).subscribe();
                this.isApproveDisabled = false;
                this.isRejectDisabled = false;
                this.invitedDistributorService.isSelectingDistributors = false;
                // update url
                window.history.replaceState({}, 'Title', '#/piaInvitedDistributorsReview?taskId=' + this.task.id);
              });
          });
      });
    });
  }

  async saveAndCompleteCurrentChildCandidates(invitedSupplier: InvitedSupplier) {

    const task = await this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(invitedSupplier.candidateId).toPromise();
    return this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
      TaskDecision.DISTRIBUTOR_WAITING_APPROVE).toPromise();
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then(() => {
          this.isApproveDisabled = false;
          this.isRejectDisabled = false;
          this.invitedDistributorService.isSelectingDistributors = false;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.isRejectDisabled = false;
        this.isApproveDisabled = false;
        this.invitedDistributorService.isSelectingDistributors = false;
      });
  }
}
