<pm-editor (closeEvent)="onClose()" *ngIf="pharmProductService.candidate && isViewingPage">

  <pm-header>
    <app-pharm-new-product-flow-stepper *ngIf="pharmProductService.candidate && !pharmProductService.isDsdOnly()"
                                        [candidateErrors]="pharmProductService.getAllCandidateErrors()"
                                        [stepIndex]="2"
                                        [isSellable]="candidateUtilService.isSellable(pharmProductService.candidate)">
    </app-pharm-new-product-flow-stepper>
    <app-pharm-new-dsd-product-flow-stepper *ngIf="pharmProductService.candidate && pharmProductService.isDsdOnly()"
                                            [candidateErrors]="pharmProductService.getAllCandidateErrors()"
                                            [stepIndex]="2"
                                            [isSellable]="candidateUtilService.isSellable(pharmProductService.candidate)">
    </app-pharm-new-dsd-product-flow-stepper>
  </pm-header>

  <!-- Page Titles -->
  <pm-title>Provide supplier and buyer details.</pm-title>
  <!-- Page Cards -->
  <!--
    We're using  an ng-container to not add additional
    DOM elements and only show the cards when the candidate is loaded
  -->
  <ng-container *ngIf="pharmProductService.candidate">
    <pm-heb-details-card [(candidateProductModel)]="pharmProductService.candidate.candidateProducts[0]"
                         [(model)]="pharmProductService.candidate"
                         (merchandiseTypeChange)="onMerchandiseTypeChange($event)"
                         (buyerChange)="onBuyerChange($event)"
                         (commodityChange)="onCommodityChange($event)"
                         (subCommodityChange)="onSubCommodityChange($event)"
                         [permissions]="hebDetailsCardPermissions"
                         [errorModel]="candidateError"
                         [commodities]="commodities"
                         [commodityConfiguration]="getCommodityConfiguration()"
                         [isDsd]="pharmProductService.candidate.dsdSwitch"
                         [subCommodityConfiguration]="getSubCommodityConfiguration()"
                         [candidateProductErrorModel]="candidateProductError">

    </pm-heb-details-card>

    <pm-supplier-details-card [(candidateModel)]="pharmProductService.candidate"
                              [candidateProductModel]="pharmProductService.candidate.candidateProducts[0]"
                              [permissions]="supplierDetailsCardPermissions"
                              [candidateErrorModel]="candidateError"
                              (supplierChange)="onSupplierChange($event)">
    </pm-supplier-details-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canClickNext"></p-button>
  </pm-footer>
</pm-editor>
