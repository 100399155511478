import {Component, OnInit} from '@angular/core';
import {
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProductError,
  CandidateValidatorType,
  MerchandisingInfoCardPermissions,
  ProductImagesCardPermissions,
  ProductPackagingCardPermissions
} from 'pm-models';
import {UPCInputState} from 'pm-components';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {BonusSizeService} from '../../service/bonus-size.service';
import {CostService} from '../../service/cost.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {PiaBonusStepperComponent} from '../pia-bonus-stepper/pia-bonus-stepper.component';

@Component({
  selector: 'app-pia-bonus-details',
  templateUrl: './pia-bonus-details.component.html',
  styleUrls: ['./pia-bonus-details.component.scss']
})
export class PiaBonusDetailsComponent implements OnInit {

  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public KEY_RETAIL = 'Key Retail';
  public isViewingPage = true;

  readonly productPackagingCardPermissions: ProductPackagingCardPermissions = {
    isReadOnly: false
  };

  readonly merchandisingInfoCardPermissions: MerchandisingInfoCardPermissions = {
    isReadOnly: false,
    merchandiseType: {
      isHidden: true
    },
    commodity: {
      isHidden: true
    },
    subCommodity: {
      isHidden: true
    },
    pssDepartment: {
      isHidden: true
    }
  };
  readonly productImagesCardPermissions: ProductImagesCardPermissions = {
    isReadOnly: false
  };

  private taskSubscription$: any;
  public unitsOfMeasures: any;
  public packageTypes: any;

  upcState: UPCInputState;
  canNavigate = true;

  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public bonusSizeService: BonusSizeService, public costService: CostService,
              private candidateUtilService: CandidateUtilService) { }

  ngOnInit() {
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.bonusSizeService.taskId, this.bonusSizeService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.bonusSizeService.resetService();
        this.bonusSizeService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.BONUS_SIZE || candidate.candidateType === Candidate.REPLACEMENT_UPC) {
            this.bonusSizeService.currentCandidate = candidate;
            this.bonusSizeService.originalCandidate = JSON.parse(JSON.stringify(candidate));
            this.bonusSizeService.setProductData().subscribe();
          } else {
            this.router.navigate(['/tasks']).then();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.bonusSizeService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.bonusSizeService.setProductData().subscribe();
            } else {
              this.bonusSizeService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  onClickNext() {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR]).toPromise().then(() => {
      this.bonusSizeService.bonusDetailsError = new CandidateError();
      this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaCaseDetailsUrl(), false);
    }, (error) => {
      this.bonusSizeService.scrollToTop();

      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.bonusSizeService.bonusDetailsError = error.error.candidateErrors;
      }
      this.canNavigate = true;
    });
  }

  onClickBack() {
    this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaSetupUpcUrl(), false);
  }

  onClose() {
    this.isViewingPage = false;
    this.bonusSizeService.saveCandidateAndNavigate('/tasks', false);
  }

  subBrandChange(selectedValue: any) {
    this.bonusSizeService.currentCandidate.candidateProducts[this.bonusSizeService.currentCandidateProductIndex].subBrand = selectedValue;
  }

  validateUPC() {
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.bonusSizeService.getCurrentCandidateProduct().id;

    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.bonusSizeService.candidateProductError.upc = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {
        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError: CandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.bonusSizeService.candidateProductError.upc =
          returnedCandidateProductError.upc;
        this.bonusSizeService.candidateProductError.upcCheckDigit =
          returnedCandidateProductError.upcCheckDigit;
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  public setDefaultVertexTaxCategory(candidate: Candidate) {
    // initialize it to the default category if it's not set
    if (!candidate.vertexTaxCategory) {
      candidate.vertexTaxCategory = CandidateHelper.getVertexTaxCategory(candidate);
    }
  }


  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    let urlToNavigate;
    let validators;
    switch (stepperItem.text) {
      case PiaBonusStepperComponent.UPC_ITEM_CODE:
        this.onClickBack();
        break;
      case PiaBonusStepperComponent.CASE_DETAILS:
        this.onClickNext();
        break;
      case PiaBonusStepperComponent.WAREHOUSE_SELECTION:
        urlToNavigate = this.bonusSizeService.getPiaWarehouseUrl();
        validators = [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR,
          CandidateValidatorType.PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR];
        this.jumpToPage(urlToNavigate, validators);
        break;
      case PiaBonusStepperComponent.EXTENDED_ATTRIBUTES:
        urlToNavigate = this.bonusSizeService.getPiaExtendedAttributesUrl();
        validators = [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR,
          CandidateValidatorType.PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR,  CandidateValidatorType.WAREHOUSE_VALIDATOR, CandidateValidatorType.BICEP_VALIDATOR];
        this.jumpToPage(urlToNavigate, validators);
        break;
    }
  }

  jumpToPage(urlToNavigate, validators: CandidateValidatorType[]) {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate, validators).subscribe(() => {
      this.bonusSizeService.candidateError = this.bonusSizeService.bonusDetailsError = new CandidateError();
      this.bonusSizeService.candidateProductError = new CandidateProductError();
      this.bonusSizeService.saveCandidateAndNavigate(urlToNavigate, false);
    }, (error) => {
      this.bonusSizeService.scrollToTop();

      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.bonusSizeService.candidateError = error.error.candidateErrors;
        this.bonusSizeService.candidateProductError =
          this.bonusSizeService.candidateError.candidateProductErrors[this.bonusSizeService.getCurrentCandidateProduct().id];
        this.bonusSizeService.bonusDetailsError = this.bonusSizeService.candidateError;
      }
      this.canNavigate = true;
    });
  }
}
