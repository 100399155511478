<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-header>
    <app-pia-bonus-stepper *ngIf="bonusSizeService.getCurrentCandidate()" [candidateErrors]="bonusSizeService.getAllCandidateErrors()"
                           [isReplacementUpc]="bonusSizeService.isReplacementUpc" [stepIndex]="2"
                           (indexClick)="onClickStepper($event)"
                           [steps]="bonusSizeService.getPiaStepperSteps()">
    </app-pia-bonus-stepper>
  </pm-header>

  <pm-title>
    <div *ngIf="bonusSizeService.getCandidate()">{{bonusSizeService.getBonusDetailsPageTitle()}}</div>
  </pm-title>

  <div class="row" style="margin-top: 25px;">
  </div>

  <div *ngIf="bonusSizeService.currentCandidate" class="col-md-8">
    <div class="row">
      <img class="product-image" src="{{bonusSizeService?.productImageUrl ? bonusSizeService?.productImageUrl : bonusSizeService?.DEFAULT_NO_PRODUCT_IMAGE}}">
      <div class="col">
        <div class="upc-header">
          UPC: {{bonusSizeService?.productData?.primaryScanCodeId}}
        </div>
        <div class="attribute-description">
          <p>{{bonusSizeService?.productData?.productDescription}}</p>
          <p>{{bonusSizeService?.productInfoString}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div *ngIf="bonusSizeService.currentCandidate" class="col-12 col-md-8">
      <pm-card title="Basics" subtitle="{{bonusSizeService.getBasicSubTitle()}}">
        <ng-container>
          <pm-attribute-display-upc [attribute]="bonusSizeService.caseUPCConfiguration"
                                    [(model)]="bonusSizeService.currentCandidate?.candidateProducts[bonusSizeService.currentCandidateProductIndex].upc"
                                    [attributeError]="bonusSizeService.candidateProductError?.upc"
                                    [(checkDigitModel)]="bonusSizeService.currentCandidate?.candidateProducts[bonusSizeService.currentCandidateProductIndex].upcCheckDigit"
                                    (validateUpc)="validateUPC()"
                                    [state]="upcState">
          </pm-attribute-display-upc>

          <pm-attribute-display-typeahead [attribute]="bonusSizeService.subBrandConfiguration"
                                          [(model)]="bonusSizeService.currentCandidate?.candidateProducts[bonusSizeService.currentCandidateProductIndex].subBrand"
                                          [attributeError]="bonusSizeService.candidateProductError?.subBrand"
                                          (selection)="subBrandChange($event)">
          </pm-attribute-display-typeahead>
        </ng-container>
      </pm-card>
    </div>
  </div>

  <div *ngIf="bonusSizeService.currentCandidate">
    <pm-product-packaging-card  [(model)]="bonusSizeService.currentCandidate"
                                [permissions]="productPackagingCardPermissions"
                                [packageTypes]="packageTypes"
                                [unitOfMeasure]="unitsOfMeasures"
                                [errorModel]="bonusSizeService.bonusDetailsError">
    </pm-product-packaging-card>

    <pm-product-images-card [(model)]="bonusSizeService.currentCandidate?.candidateProducts[bonusSizeService.currentCandidateProductIndex]"
                            [permissions]="productImagesCardPermissions"
                            [errorModel]="bonusSizeService.candidateProductError">
    </pm-product-images-card>

    <pm-merchandising-info-card [(candidateModel)]="bonusSizeService.currentCandidate"
                                [(candidateProductModel)]="bonusSizeService.currentCandidate.candidateProducts[bonusSizeService.currentCandidateProductIndex]"
                                [permissions]="merchandisingInfoCardPermissions"
                                (taxableChange)="setDefaultVertexTaxCategory(bonusSizeService.currentCandidate)"
                                [candidateErrorModel]="bonusSizeService.bonusDetailsError"
                                [candidateProductErrorModel]="bonusSizeService.candidateProductError">
    </pm-merchandising-info-card>

  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
