import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AttributeTypeaheadConfig, Candidate, CandidateProduct, DsdItem, Item, Product, Upc} from 'pm-models';
import {GrowlService} from '../growl/growl.service';
import {BonusSizeService} from '../service/bonus-size.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {CandidateService} from '../service/candidate.service';
import {CostService} from '../service/cost.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {FileService} from '../service/file.service';
import {LookupService} from '../service/lookup.service';
import {ProductService} from '../service/product.service';
import {WorkflowService} from '../service/workflow.service';
import {calculateCheckDigit} from '../shared/upc.utils';
import {LabelInsightService} from '../service/label-insight.service';
import {ReviewComponent} from 'pm-components';
import {CandidateHistoryService} from '../service/candidate-history.service';
import {Observable} from 'rxjs';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {MatUtilService} from '../service/mat-util.service';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-pia-bonus-size-review-closed',
  templateUrl: './pia-bonus-size-review-closed.component.html',
  styleUrls: ['./pia-bonus-size-review-closed.component.scss']
})
export class PiaBonusSizeReviewClosedComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public candidate: Candidate;
  public currentCandidateProduct: CandidateProduct;
  public isViewingPage = true;
  public productData: Product;
  public upc: Upc;
  public item: Item;
  public dsdItem: DsdItem;
  public productInfoString: string;
  public productImageUrl: string = null;
  public candidateProductImages = [];
  public labelInsightImages = [];
  public warehouseData = [];
  public warehouses: any = undefined;
  public orderRestrictions: any[];
  public unitsOfMeasures: any;
  public decimalCount = 1;
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public UPC = 'UPC';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  private searchedCandidateProductIndex = 0;
  public upcCheckDigit: number;
  public departmentId = undefined;
  public isReplacementUpc: boolean = false;
  showHistoryPanel: boolean = false;
  showMatAttributes = false;
  isLoadingMatData = true;


  constructor(private workflowService: WorkflowService, private route: ActivatedRoute,
              private router: Router, private candidateService: CandidateService, private lookupService: LookupService,
              private growlService: GrowlService, public costService: CostService, private productService: ProductService,
              public fileService: FileService, public editCandidateModalService: EditCandidateModalService, public bonusSizeService: BonusSizeService,
              public candidateUtilService: CandidateUtilService, public labelInsightService: LabelInsightService,
              public candidateHistoryService: CandidateHistoryService, public matUtilService: MatUtilService,
              public permissionService: NgxPermissionsService) { }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  ngOnInit() {
    this.bonusSizeService.resetService();
    this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.BONUS_SIZE && candidate.candidateType !== Candidate.REPLACEMENT_UPC) {
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.setInitialValues(candidate);
            this.candidateHistoryService.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  private setInitialValues(candidate: Candidate) {
    this.candidate = candidate;
    this.currentCandidateProduct = this.candidate.candidateProducts[1];
    this.isViewingPage = true;
    this.productImageUrl = null;
    this.setProductData().subscribe(() => {
        this.setupMatAttributes();
      }, (error) => {
        this.growlService.addError(error.message);
      }
    );
    this.getOrderRestrictions();
    this.candidateProductImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[1].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[1].labelInsightsImageLinks);
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }
    this.bonusSizeService.resetMatHierarchyFields();
    // show attributes in context of live attributes while candidate is in progress.
    if (this.candidateUtilService.isInProgressCandidate(this.candidate)) {
      this.showMatAttributes = true;
      this.matUtilService.updateMatHierarchyFromProduct(this.currentCandidateProduct, this.productData).pipe(
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
          this.bonusSizeService.globalAttributes, this.bonusSizeService.hierarchyAttributes)),
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.bonusSizeService.hierarchyAttributes,
            this.bonusSizeService.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.bonusSizeService.globalAttributes,
            [], this.bonusSizeService.warehouseItemAttributes, this.bonusSizeService.upcAttributes);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })).subscribe();

    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.currentCandidateProduct?.globalAttributes, [],
        this.bonusSizeService.warehouseItemAttributes, this.bonusSizeService.upcAttributes);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  setProductData(): Observable<any> {
    if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(productData);
          this.getCheckDigit();
        })
      );
    } else if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(productData);
          this.getCheckDigit();
        })
      );
    }
  }

  private setInformationFromProductData(productData: Product) {
    this.candidate.productId = productData.productId;
    this.item = this.productService.getPrimaryItem(this.candidate.candidateProducts[0], productData);
    this.dsdItem = this.productService.getPrimaryDsdItem(this.candidate.candidateProducts[0], productData);
    this.productService.getUpcBySearchedValue(this.candidate.candidateProducts[0], productData).subscribe(upc => {
      this.upc = upc;
      this.setProductRetailInfo();
    });
    this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
    this.departmentId = this.productService.getDepartment(productData);
  }

  setProductRetailInfo() {
    const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
    tempCandidate.unitCost = this.costService.getUnitCost(this.candidate);
    tempCandidate.retailPrice = this.upc.retailPrice;
    tempCandidate.retailXFor = this.upc.xfor;
    if (!this.productData.priceRequired) {
      tempCandidate.retailType = 'KEY_RETAIL';
    }
    this.productInfoString = '' + this.upc.size +
      ' | Inner case cost: '  +  this.costService.getCaseCostAsCurrency(this.candidate) +
      ' | Unit cost: '  +  this.costService.toCurrencyForCost(tempCandidate.unitCost) +
      ' | Retail: '  +  this.costService.toCurrency(this.upc.retailPrice);
  }

  getUpcCheckDigit() {
    if (this.upc && this.upc.scanCodeId) {
      return calculateCheckDigit(this.upc.scanCodeId);
    } else {
      return '';
    }
  }

  onClickBackToHome() {
    this.resetInitialValues();
    this.router.navigate(['/tasks'], { queryParams: { tabIndex: 1 } }).then();
  }

  onClickPrint() {
    window.print();
  }

  private resetInitialValues() {
    this.isViewingPage = false;
    this.candidate = undefined;
    this.currentCandidateProduct = undefined;
    this.productImageUrl = null;
  }

  onClose() {
    this.resetInitialValues();
    this.router.navigate(['/tasks']).then();
  }

  getTitleSubHeading(): String {
    if (!this.candidate) {
      return '';
    }
    let infoString = '';

    infoString += 'Status: ';
    infoString += CandidateUtilService.getUIStatusFromCandidateStatus(this.candidate.status);
    if (this.currentCandidateProduct.itemCode) {
      infoString += ' | Item code: ' + this.currentCandidateProduct.itemCode;
    }

    return infoString;
  }

  getCheckDigit() {
    this.upcCheckDigit = calculateCheckDigit(this.productData.primaryScanCodeId);
  }

  getHebPennyProfit() {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(this.candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.getHebPennyProfit(tempCandidate);
    }
  }

  getHebMargin() {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(this.candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.getHebMargin(tempCandidate);
    }
  }

  getPricingType() {
    return this.productService.getPricingType(this.productData, this.upc);
  }

  getNumRetailUnits() {
    if (!this.candidate.displayReadyUnit || !this.candidate.displayReadyUnitRowsDeep ||
      !this.candidate.displayReadyUnitRowsFacing || !this.candidate.displayReadyUnitRowsHigh) {
      return 0;
    } else {
      return +this.candidate.displayReadyUnitRowsDeep *
        +this.candidate.displayReadyUnitRowsFacing *
        +this.candidate.displayReadyUnitRowsHigh;
    }
  }

  getUpcDetailLabel() {
    if (this.candidate.candidateType === Candidate.REPLACEMENT_UPC) {
      this.isReplacementUpc = true;
      return 'Replacement UPC details';
    } else {
      return 'Bonus UPC Details';
    }
  }

  historyPanelOpen() {
    this.candidateHistoryService.historyPanelOpen();
    this.showHistoryPanel = true;
    this.pmReview.openDrawer();
  }

  historyPanelClose() {
    this.candidateHistoryService.historyPanelClose();
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.piaComment;
  }
}
