import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AttributeTypes, ReviewComponent} from 'pm-components';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  DsdItem,
  Item,
  Product,
  Task,
  TaskDecision,
  TextInputType,
  Upc,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {AuthService} from '../auth/auth.service';
import {GrowlService} from '../growl/growl.service';
import {BonusSizeService} from '../service/bonus-size.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {CandidateService} from '../service/candidate.service';
import {CostService} from '../service/cost.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {FileService} from '../service/file.service';
import {LookupService} from '../service/lookup.service';
import {ProductService} from '../service/product.service';
import {WorkflowService} from '../service/workflow.service';
import {calculateCheckDigit} from '../shared/upc.utils';
import {ItemWeightTypeService} from '../service/item-weight-type.service';
import {LabelInsightService} from '../service/label-insight.service';
import {CandidateHistoryService} from '../service/candidate-history.service';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {MatUtilService} from '../service/mat-util.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {RequestNewMatAttributeOverrideWrapper} from 'pm-components/lib/attributes/attribute-type';

@Component({
  selector: 'app-pia-bonus-size-review',
  templateUrl: './pia-bonus-size-review.component.html',
  styleUrls: ['./pia-bonus-size-review.component.scss']
})
export class PiaBonusSizeReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public PROCUREMENT_SUPPORT_TASK_NAME = 'PIA Final Review';
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public UPC = 'UPC';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  private currentCandidateProductIndex = 1;
  private searchedCandidateProductIndex = 0;
  public upcCheckDigit: number;

  isLoadingMatData = true;
  showMatAttributes = false;
  private openCommentDrawer: boolean;
  public tempCommentHolder: string;

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute,
              public router: Router, public candidateService: CandidateService, public lookupService: LookupService,
              public growlService: GrowlService, public costService: CostService, public productService: ProductService,
              public fileService: FileService, public editCandidateModalService: EditCandidateModalService, public bonusSizeService: BonusSizeService,
              public candidateUtilService: CandidateUtilService, public authService: AuthService,
              private itemWeightTypeService: ItemWeightTypeService, public labelInsightService: LabelInsightService,
              public candidateHistoryService: CandidateHistoryService, public permissionService: NgxPermissionsService,
              public matUtilService: MatUtilService) { }

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: any = {};
  public taskSubscription$: any;
  public task: Task;
  public isViewingPage = true;
  public productData: Product;
  public candidateProductImages = [];
  public labelInsightImages = [];
  public upc: Upc;
  public item: Item;
  public dsdItem: DsdItem;
  public productInfoString: string;
  public displayingExistingCasesDrawer = false;
  public displayingWarehouseInfo = false;
  public departmentId = undefined;
  public commodities: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public warehouseCandidateError: CandidateError;
  public warehouseCandidateProductError: CandidateProductError;
  public productImageUrl: string = null;
  public warehouseData = [];
  public warehouses: any = undefined;
  public orderRestrictions: any[];
  public areButtonsDisabled = false;
  public unitsOfMeasures: any;
  public decimalCount = 1;
  public lastSupplierChangedCandidate: Candidate;
  public lastSupplierChangedCandidateProduct: CandidateProduct;
  public showHistoryPanel: boolean = false;
  public showWarehousePanel: boolean = false;
  public currentHistoryResults: any = undefined;
  public candidateAudits: any = undefined;
  public candidateProductAudits: any = undefined;
  public isWarehouseMissingResolved = false;
  public isReplacementUpc: boolean;
  public costOwners = [];
  public requestNewMatAttributeOverrideWrapper = new RequestNewMatAttributeOverrideWrapper();

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => true,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  piaComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'piaCommentId',
    maxLength: 4000
  };

  expandCollapseMissingWarehouseButtonText: string = 'Collapse';
  isExpandedWarehouse: boolean = true;

  ngOnInit() {
    this.bonusSizeService.resetService();
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.PROCUREMENT_SUPPORT_TASK_NAME) {
              this.router.navigate(
                ['/tasks'], { queryParams: { growlMessage: 'Candidate is not in ' + this.PROCUREMENT_SUPPORT_TASK_NAME +
                      ' status.', growlToUse: GrowlService.SEVERITY_ERROR }
                }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                if (candidate.candidateType !== Candidate.BONUS_SIZE && candidate.candidateType !== Candidate.REPLACEMENT_UPC) {
                  this.router.navigate(['/tasks'], {
                    queryParams: {growlMessage: 'Invalid task type.', growlToUse: GrowlService.SEVERITY_ERROR}
                  }).then();
                }
                this.costService.updateCostLink(candidate).subscribe((updatedCandidate) => {
                  this.candidateHistoryService.setInitialValues(updatedCandidate);
                  this.setInitialValues(updatedCandidate);
                });
              });

          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
  }


  private setInitialValues(candidate: Candidate) {
    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
    this.productImageUrl = null;
    this.setProductData().subscribe(() => {
        this.setupMatAttributes();
      }, (error) => {
        this.growlService.addError(error.message);
      }
    );
    this.getOrderRestrictions();
    this.isReplacementUpc = candidate.candidateType === Candidate.REPLACEMENT_UPC;
    this.candidateProductImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[1].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[1].labelInsightsImageLinks);
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }

    this.showMatAttributes = true;
    this.matUtilService.updateMatHierarchyFromProduct(this.candidateProduct, this.productData).pipe(
      switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
        this.bonusSizeService.globalAttributes, this.bonusSizeService.hierarchyAttributes)),
      tap(() => {
        this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.bonusSizeService.hierarchyAttributes,
          this.bonusSizeService.hierarchyNumberToAttributesMap);
        this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.bonusSizeService.globalAttributes,
          [], this.bonusSizeService.warehouseItemAttributes, this.bonusSizeService.upcAttributes);
      }),
      finalize(() => {
        this.isLoadingMatData = false;
      })).subscribe();

  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }
  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[this.currentCandidateProductIndex];
  }

  setProductData() {
    if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc,
        []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.getCheckDigit();
          this.setInformationFromProductData(productData);
          this.candidateUtilService.getCostOwners(this.productData.brand.brandId).subscribe(costOwners => {
            this.costOwners = costOwners;
          });
        })
      );
    } else if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.getCheckDigit();
          this.setInformationFromProductData(productData);
          this.candidateUtilService.getCostOwners(this.productData.brand.brandId).subscribe(costOwners => {
            this.costOwners = costOwners;
          });
        })
      );
    }
  }

  private setInformationFromProductData(productData: Product) {
    this.candidate.productId = productData.productId;
    this.item = this.productService.getPrimaryItem(this.candidate.candidateProducts[0], productData);
    this.dsdItem = this.productService.getPrimaryDsdItem(this.candidate.candidateProducts[0], productData);
    this.productService.getUpcBySearchedValue(this.candidate.candidateProducts[0], productData).subscribe(upc => {
      this.upc = upc;
      this.setProductRetailInfo();
    });
    this.productService.setBuyerAndHierarchyInformation(this.candidate, productData);
    this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
    this.departmentId = this.productService.getDepartment(productData);
  }


  setProductRetailInfo() {
    if (this.candidate.candidateType === Candidate.REPLACEMENT_UPC) {
      this.productInfoString =
        'Retail size: ' + this.candidate.retailSize +
        ' | Original size: ' + this.upc.size +
        ' | Suggested retail: ' + this.costService.toCurrency(this.upc.retailPrice);
    } else {
      this.productInfoString =
        'Bonus size: ' + this.candidate.retailSize +
        ' | Original size: ' + this.upc.size +
        ' | Suggested retail: ' + this.costService.toCurrency(this.upc.retailPrice);
    }
  }

  showExistingCasePacks() {
    this.displayingExistingCasesDrawer = true;
    this.displayingWarehouseInfo = false;
    this.pmReview.openDrawer();
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
      this.candidate.candidateProducts[this.currentCandidateProductIndex] = this.candidateProduct;
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.isViewingPage = false;
        this.router.navigate(['/tasks']);
      });
    } else {
      this.isViewingPage = false;
      this.router.navigate(['/tasks']);
    }
  }
  getPricingType() {
    return this.productService.getPricingType(this.productData, this.upc);
  }

  editWarehouse(warehouse, type: AttributeTypes,  overrides?: any) {
    this.editCandidateModalService.openModal(type, warehouse, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        const warehouseIndex = this.candidateProduct.warehouses.findIndex(x => x.warehouseId === warehouse.warehouseId);
        this.candidateProduct.warehouses[warehouseIndex] = response;
      }
    });
  }

  get attributeType() {
    return AttributeTypes;
  }

  editOrderRestrictions(warehouse) {
    this.editWarehouse(warehouse, AttributeTypes.WarehouseOrderRestriction, { collections : this.orderRestrictions});
  }

  removeWarehouse(index) {
    const warehouse = this.candidateProduct.warehouses.splice(index, 1).pop();
    if (this.warehouseData && this.warehouses) {
      this.addRemovedWarehouseToDrawer(+warehouse.warehouseId);
    }
  }

  addRemovedWarehouseToDrawer(warehouseId) {
    for (let x = 0; x < this.warehouses.length; x++) {
      if (this.warehouses[x].warehouseId === warehouseId) {
        this.warehouseData.push(this.warehouses[x]);
        this.warehouseData[this.warehouseData.length - 1].orderUnitConfig = this.getOrderUnitConfiguration(warehouseId);
        break;
      }
    }
  }

  openWarehouseDrawer() {
    this.displayingWarehouseInfo = true;
    this.displayingExistingCasesDrawer = false;
    this.getWarehouses(this.candidate);
    this.pmReview.openDrawer();
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane?.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, don't add to warehouse list.
    if (this.candidateProduct.warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  onClickCasePack(casePack) {
    casePack.isViewing = !casePack.isViewing;
  }

  collapse() {
    this.displayingExistingCasesDrawer = false;
    this.displayingWarehouseInfo = false;
    this.pmReview.closeDrawer();
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  /**
   * Saves candidate.
   */
  update() {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidate));
    tempCandidate.candidateProducts[this.currentCandidateProductIndex].warehouses =
      this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
    this.candidateService.validateCandidate(tempCandidate, [CandidateValidatorType.WAREHOUSE_VALIDATOR,
      CandidateValidatorType.BICEP_VALIDATOR])
      .subscribe(() => {
        this.candidateProduct.warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
        this.candidateService.saveCandidate(this.candidate, true).subscribe(savedCandidate => {
          this.setOriginalAndCurrentCandidate(savedCandidate);
          this.collapse();
        });
      }, (error) => {
        if (error.error.candidateErrors) {
          this.warehouseCandidateError = error.error.candidateErrors;
          this.warehouseCandidateProductError  = this.warehouseCandidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message); // TODO: new way to handle server side errors?
        }
      });
  }

  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  /**
   * Saves candidate.
   */
  save() {
    this.candidateProduct.warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
    this.setWarehouseData();
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }

  editCandidate(attributeType: AttributeTypes) {
    this.showEditCandidateModal(attributeType);
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        // only reset warehouse data when lane is updated
        if (type === AttributeTypes.Warehouse) {
          this.resetWarehouseData();
        }
      }
    });
  }

  editCaseDescription(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  showEditCandidateProductModal(type: AttributeTypes, overrides?: any) {
    const tempCandidateProduct = JSON.parse(JSON.stringify(this.candidateProduct));
    this.editCandidateModalService.openModal(type, tempCandidateProduct, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
        this.candidate.candidateProducts[this.currentCandidateProductIndex] = this.candidateProduct;
        if (type === AttributeTypes.ProductImages) {
          this.candidateProductImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[this.currentCandidateProductIndex].imageLinks);
        }
      }
    });
  }

  editCaseUPC() {
    this.showEditCandidateProductModal(AttributeTypes.CaseUPC, {
      validationService: this.candidateService
    });
  }

  editCandidateProduct(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  isTiedToCatchOrVariableWeightBuyer(): boolean {
    if (this.candidate.commodity && this.candidate.commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(this.candidate.commodity.departmentId);
    } else {
      return false;
    }
  }

  editSeasonYear() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.Season},
        {type: AttributeTypes.SeasonYear},
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  showRejectPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }

  reject(event, panel, target) {
    this.areButtonsDisabled = true;
    this.candidate.vendorComment = event;
    this.candidate.status = Candidate.DECLINED;
    this.workflowService.addProcessVariableViewed(this.task.processInstanceId, false).subscribe(() => {
      this.saveAndCompleteTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE,
        TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION,
        'Successfully rejected candidate');
      panel.hide();
    });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( () => {
          this.areButtonsDisabled = false;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.areButtonsDisabled = false;
      });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   */
  onClickActivate() {
    this.areButtonsDisabled = true;
    this.candidate.candidateProducts[this.currentCandidateProductIndex] = this.candidateProduct;
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.UPC_VALIDATOR, CandidateValidatorType.BONUS_CASE_PACK_DETAILS_VALIDATOR,
        CandidateValidatorType.BUYER_BONUS_SIZE_VALIDATOR, CandidateValidatorType.SCA_PRODUCT_REVIEW_VALIDATOR]).subscribe(() => {
        this.workflowService.addProcessVariableViewed(this.task.processInstanceId, false).subscribe(() => {
          this.candidateService.activateCandidate(this.candidate).subscribe(() => {
            this.completeTaskAndRouteToTasksPage(
              WorkflowService.ACTION_COMPLETE,
              TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
              'Successfully activated candidate.'
            );
          }, (error) => {
            this.growlService.addError(error);
            this.areButtonsDisabled = false;
          });
        });
      }, (error) => {
        if (error.error.candidateErrors) {
          this.candidateError = error.error.candidateErrors;
          this.candidateProductError = this.candidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message);
        }
        this.areButtonsDisabled = false;
      });
  }

  editUnitOfMeasure() {
    this.showEditCandidateModal(AttributeTypes.UnitOfMeasure, {collections: this.unitsOfMeasures});
  }

  editCostLink() {
    this.editCandidateModalService
      .openModal(AttributeTypes.CostLinkedItem, this.candidate, {
        validationService: this.candidateService
      })
      .subscribe(response => {
        if (response) {
          this.candidate = response;
        }
      });
  }

  editProductUPC() {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidate));
    this.editCandidateModalService.openModal(AttributeTypes.ProductUPC, tempCandidate, { validationService: this.candidateService }).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidateProduct = this.candidate.candidateProducts[this.currentCandidateProductIndex];
      }
    });
  }

  getNumRetailUnits() {
    if (!this.candidate.displayReadyUnit || !this.candidate.displayReadyUnitRowsDeep ||
      !this.candidate.displayReadyUnitRowsFacing || !this.candidate.displayReadyUnitRowsHigh) {
      return 0;
    } else {
      return +this.candidate.displayReadyUnitRowsDeep *
        +this.candidate.displayReadyUnitRowsFacing *
        +this.candidate.displayReadyUnitRowsHigh;
    }
  }

  getActivateButtonTitle() {
    if (this.areButtonsDisabled) {
      return 'Activating';
    } else {
      return 'Activate';
    }
  }

  getCheckDigit() {
    this.upcCheckDigit = calculateCheckDigit(this.productData.primaryScanCodeId);
  }

  toggleResolved() {
    this.isWarehouseMissingResolved = !this.isWarehouseMissingResolved;

    if (this.isWarehouseMissingResolved) {
      this.candidate.missingWarehousesResolveUser = this.authService.getUser();
    } else {
      this.candidate.missingWarehousesResolveUser = null;
    }
  }

  toggleExpandCollapseMissingWarehouse() {
    if (this.expandCollapseMissingWarehouseButtonText === 'Collapse') {
      this.expandCollapseMissingWarehouseButtonText = 'Expand';
      this.isExpandedWarehouse = false;
    } else {
      this.expandCollapseMissingWarehouseButtonText = 'Collapse';
      this.isExpandedWarehouse = true;
    }
  }

  isActivateButtonDisabled() {
    if (!this.candidate) {
      return true;
    }
    return this.candidate.missingWarehouses && !this.isWarehouseMissingResolved;
  }

  historyPanelOpen() {
    this.candidateHistoryService.historyPanelOpen();
    this.showHistoryPanel = true;
    this.pmReview.openDrawer();
  }

  historyPanelClose() {
    this.candidateHistoryService.historyPanelClose();
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  addAttachments(event) {
    this.candidate.attachments = event;
    this.save();
  }

  getUpcDetailLabel() {
    if (this.isReplacementUpc) {
      return 'Replacement UPC details';
    } else {
      return 'Bonus UPC Details';
    }
  }

  editCostOwner() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.CostOwner, overrides: { collections : this.costOwners}},
        {type: AttributeTypes.TopToTop, overrides: { collections : this.costOwners}}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  editWarehouseSupplier() {
    if (this.candidate.vendor && !this.candidate.vendor.lanes && this.candidate.vendor.apNumber) {
      this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe(() => {
        this.showEditCandidateModal(AttributeTypes.Warehouse, {collections: this.candidate.vendor.lanes});
      });
    } else {
      this.showEditCandidateModal(AttributeTypes.Warehouse,
        {collections: this.candidate.vendor ? this.candidate.vendor.lanes : []});
    }
  }

  private resetWarehouseData() {
    if (this.candidate.vendor?.lanes && this.candidate.lane?.id !== null && this.candidate.lane?.id !== undefined) {
      this.lookupService.findWarehousesByVendorApNumberAndLaneId(this.candidate.vendor.apNumber,
        this.candidate.lane.id).subscribe(warehouses => {
        this.warehouses = warehouses;
        this.getWarehouses(this.candidate);
        if (this.candidate.vendor.apNumber !== undefined) {
          this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe();
        }
      });
    } else {
      this.warehouses = [];
      this.warehouseData = [];
      this.candidateProduct.warehouses = [];
    }
    this.setWarehouseData();
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.piaComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.showHistoryPanel = false;
    this.showWarehousePanel = false;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.showHistoryPanel = false;
    this.showWarehousePanel = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.piaComment = this.tempCommentHolder;
    this.candidate.piaCommentUser = this.authService.getUser();
    this.openCommentDrawer = false;
    this.showHistoryPanel = false;
    this.showWarehousePanel = false;
    this.pmReview.closeDrawer();
  }
}
