export enum CandidateValidatorType {
  VENDOR_DATA_VALIDATOR = 'VENDOR_DATA_VALIDATOR',
  BUYER_DATA_VALIDATOR = 'BUYER_DATA_VALIDATOR',
  SCA_DATA_VALIDATOR = 'SCA_DATA_VALIDATOR',
  UPC_VALIDATOR = 'UPC_VALIDATOR',
  CASE_UPC_VALIDATOR = 'CASE_UPC_VALIDATOR',
  SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR = 'SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR',
  SUPPLIER_HEB_SETUP_VALIDATOR = 'SUPPLIER_HEB_SETUP_VALIDATOR',
  SUPPLIER_PRODUCT_DETAILS_VALIDATOR = 'SUPPLIER_PRODUCT_DETAILS_VALIDATOR',
  SUPPLIER_CASE_PACK_VALIDATOR = 'SUPPLIER_CASE_PACK_VALIDATOR',
  COST_LINK_VALIDATOR = 'COST_LINK_VALIDATOR',
  RETAIL_LINK_VALIDATOR = 'RETAIL_LINK_VALIDATOR',
  SCA_PRODUCT_REVIEW_VALIDATOR = 'SCA_PRODUCT_REVIEW_VALIDATOR',
  BUYER_PRODUCT_REVIEW_VALIDATOR = 'BUYER_PRODUCT_REVIEW_VALIDATOR',
  PROCUREMENT_SUPPORT_PRODUCT_REVIEW_VALIDATOR = 'PROCUREMENT_SUPPORT_PRODUCT_REVIEW_VALIDATOR',
  WAREHOUSE_VALIDATOR = 'WAREHOUSE_VALIDATOR',
  EXISTING_UPC_VALIDATOR = 'EXISTING_UPC_VALIDATOR',
  EXISTING_ITEM_CODE_VALIDATOR = 'EXISTING_ITEM_CODE_VALIDATOR',
  CANDIDATE_NAME_VALIDATOR = 'CANDIDATE_NAME_VALIDATOR',
  ADDITIONAL_CASE_PACK_DETAILS_VALIDATOR = 'ADDITIONAL_CASE_PACK_DETAILS_VALIDATOR',
  EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR = 'EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR',
  BONUS_SIZE_DETAILS_VALIDATOR = 'BONUS_SIZE_DETAILS_VALIDATOR',
  BONUS_CASE_PACK_DETAILS_VALIDATOR = 'BONUS_CASE_PACK_DETAILS_VALIDATOR',
  BUYER_ADDITIONAL_CASE_PACK_VALIDATOR = 'BUYER_ADDITIONAL_CASE_PACK_VALIDATOR',
  BUYER_BONUS_SIZE_VALIDATOR = 'BUYER_BONUS_SIZE_VALIDATOR',
  ASSOCIATE_CASE_PACK_DETAILS_VALIDATOR = 'ASSOCIATE_CASE_PACK_DETAILS_VALIDATOR',
  ASSOCIATE_BASIC_DETAILS_VALIDATOR = 'ASSOCIATE_BASIC_DETAILS_VALIDATOR',
  ASSOCIATE_FINAL_REVIEW_VALIDATOR = 'ASSOCIATE_FINAL_REVIEW_VALIDATOR',
  MRT_UPC_VALIDATOR = 'MRT_UPC_VALIDATOR',
  SUPPLIER_SETUP_MRT_ITEMS_VALIDATOR = 'SUPPLIER_SETUP_MRT_ITEMS_VALIDATOR',
  MRT_CASE_DETAILS_VALIDATOR = 'MRT_CASE_DETAILS_VALIDATOR',
  MRT_INNER_PRODUCT_DETAILS_VALIDATOR = 'MRT_INNER_PRODUCT_DETAILS_VALIDATOR',
  NON_REPLENISH_APPROVE_VALIDATOR = 'NON_REPLENISH_APPROVE_VALIDATOR',
  PIA_MRT_REVIEW_VALIDATOR = 'PIA_MRT_REVIEW_VALIDATOR',
  SUPPLIER_COMMENT_VALIDATOR = 'SUPPLIER_COMMENT_VALIDATOR',
  BUYER_MRT_VALIDATOR = 'BUYER_MRT_VALIDATOR',
  DISTRIBUTION_CHANNEL_VALIDATOR = 'DISTRIBUTION_CHANNEL_VALIDATOR',
  PIA_PRODUCT_DETAILS_VALIDATOR = 'PIA_PRODUCT_DETAILS_VALIDATOR',
  PIA_PRODUCT_CASE_DETAILS_VALIDATOR = 'PIA_PRODUCT_CASE_DETAILS_VALIDATOR',
  PIA_ASSOCIATE_CASE_PACK_DETAILS_VALIDATOR = 'PIA_ASSOCIATE_CASE_PACK_DETAILS_VALIDATOR',
  PIA_ASSOCIATE_BASIC_DETAILS_VALIDATOR = 'PIA_ASSOCIATE_BASIC_DETAILS_VALIDATOR',
  PIA_BONUS_SIZE_DETAILS_VALIDATOR = 'PIA_BONUS_SIZE_DETAILS_VALIDATOR',
  PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR = 'PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR',
  PIA_BONUS_SIZE_VALIDATOR = 'PIA_BONUS_SIZE_VALIDATOR',
  PIA_CASE_PACK_VALIDATOR = 'PIA_CASE_PACK_VALIDATOR',
  PIA_ADDITIONAL_CASE_PACK_DETAILS_VALIDATOR = 'PIA_ADDITIONAL_CASE_PACK_DETAILS_VALIDATOR',
  PIA_ADDITIONAL_CASE_PACK_VALIDATOR = 'PIA_ADDITIONAL_CASE_PACK_VALIDATOR',
  UNIT_COST_VALIDATOR = 'UNIT_COST_VALIDATOR',
  BICEP_VALIDATOR = 'BICEP_VALIDATOR',
  LOCATION_GROUP_STORES_VALIDATOR = 'LOCATION_GROUP_STORES_VALIDATOR',
  SCALE_INFORMATION_VALIDATOR = 'SCALE_INFORMATION_VALIDATOR',
  SCALE_ENGLISH_LABEL_VALIDATOR = 'SCALE_ENGLISH_LABEL_VALIDATOR',
  INVITED_DISTRIBUTOR_CASE_DETAILS_VALIDATOR = 'INVITED_DISTRIBUTOR_CASE_DETAILS_VALIDATOR',
  INVITED_DISTRIBUTOR_REVIEW_VALIDATOR = 'INVITED_DISTRIBUTOR_REVIEW_VALIDATOR',
  PHARM_PRODUCT_DETAILS_VALIDATOR = 'PHARM_PRODUCT_DETAILS_VALIDATOR',
  SUPPLIER_HEADER_VALIDATOR = 'SUPPLIER_HEADER_VALIDATOR',
  LANE_VALIDATOR = 'LANE_VALIDATOR',
  PIA_INNER_PRODUCT_DETAILS_VALIDATOR = 'PIA_INNER_PRODUCT_DETAILS_VALIDATOR',
  PIA_MRT_INNER_CASE_DETAILS_VALIDATOR = 'PIA_MRT_INNER_CASE_DETAILS_VALIDATOR',
  DEPOSIT_UPC_VALIDATOR = 'DEPOSIT_UPC_VALIDATOR',
  PHARMACY_PRODUCT_CASE_DETAILS_VALIDATOR = 'PHARMACY_PRODUCT_CASE_DETAILS_VALIDATOR',
  PIA_MRT_CASE_DETAILS_VALIDATOR = 'PIA_MRT_CASE_DETAILS_VALIDATOR',
  ADDITIONAL_DISTRIBUTOR_REVIEW_VALIDATOR = 'ADDITIONAL_DISTRIBUTOR_REVIEW_VALIDATOR',
  MAT_ATTRIBUTES_VALIDATOR = 'MAT_ATTRIBUTES_VALIDATOR',
  MAT_HIERARCHY_VALIDATOR = 'MAT_HIERARCHY_VALIDATOR'
}
