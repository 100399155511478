<pm-editor (closeEvent)="onClose()">

  <pm-sidebar>
    <div *ngIf="invitedDistributorService.candidate" class="product-info">
      <div class="row image-row">
        <img class="product-image" src="{{invitedDistributorService.getProductImage()}}">
      </div>
      <div class="row header-row">
        UPC: {{invitedDistributorService.candidate.candidateProducts[0].upc}}
      </div>
      <div class="row description-row">
        {{invitedDistributorService.productData?.productDescription}}
      </div>
      <div class="row body-row">
        Brand: {{invitedDistributorService.productData?.brand.description}}
      </div>
      <div class="row body-row">
        Size: {{invitedDistributorService.upc?.size}}
      </div>
      <div class="row body-row">
        Retail: {{invitedDistributorService.upc?.xfor}} for ${{invitedDistributorService.upc?.retailPrice}}
      </div>
    </div>
  </pm-sidebar>

  <!-- Page Titles -->
  <pm-title>Authorize stores</pm-title>
  <pm-sub-title>Set up store authorization for {{distributorName}}.</pm-sub-title>
  <p></p><p></p><p></p>

  <h4>Set up stores</h4>
  <p class="mb-1" style="max-width: 80%;">
    Below are stores you can select, organized by authorization group. If you need to adjust the list cost for a group, you can do so in the Deals Cost Manager (DCM) app after we activate this product.  </p>

  <div *ngIf="invitedDistributorService.notFoundStores && invitedDistributorService.notFoundStores.length > 0" class="row ml-0">
    <div class="col-md-10 p-0">
      <div class="row stores-not-found-container">
        <img class="ban-icon" src="/assets/images/ban-icon.svg">
        <div class="header-row">
          Stores not found
        </div>
        <div class="row message-body-row">
          <p>
            You've entered a store number that you don't have access to or it doesn't exist. If you should have access to this store, contact your buyer.
          </p>
          <ul>
            <ng-template ngFor let-store let-i="index" [ngForOf]="invitedDistributorService.notFoundStores">
              <li>#{{store}}</li>
            </ng-template>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="backToStoreButton mb-4">
    <button pButton type="button" label="Back to store selection" class="ui-button-link" (click)="onClickBackToStoreSelection()"></button>
  </div>

  <div class="row ml-0">
    <ng-template ngFor let-authGroup let-i="index" [ngForOf]="invitedDistributorService.selectedAuthGroups">

      <div *ngIf="authGroup.stores && authGroup.stores.length > 0" class="col-md-10 authGroupContainer">

        <div class="row headerRow">
          <i *ngIf="!authGroup.isViewing" class="pi pi-caret-up" (click)="onClickAuthGroupCaret(authGroup)"></i>
          <i *ngIf="authGroup.isViewing" class="pi pi-caret-down" (click)="onClickAuthGroupCaret(authGroup)"></i>

          <div>
            <div class="headerText">Group {{authGroup.splrLocationGroupId}}</div>
            <div class="headerSubText">
              {{getSelectedStoresCountString(authGroup)}}
              <button *ngIf="displayShowAllStores(authGroup)" pButton type="button"
                      label="Show all stores in this group" class="ui-button-link pl-1"
                      (click)="onClickShowAllStores(authGroup)">
              </button>
            </div>
          </div>

          <div class="listCostColumn" *ngxPermissionsOnly="['ROLE_DSD_MULT_COST']">

            <div>
              <pm-attribute-display-text-input [attribute]="listCostConfiguration"
                                               [showErrorMessage]="false"
                                               [attributeError]="candidateProductError?.locationGroupStoresErrors[authGroup.splrLocationGroupId]?.listCost"
                                               [(model)]="authGroup.listCost"
                                               (modelChange)="onListCostChange($event, authGroup)"
                                               (inputFocus)="listCostFocus($event, listCostOverlay, listCostDiv)"
                                               (focus)="listCostFocus($event, listCostOverlay, listCostDiv)"
                                               (focusin)="listCostFocus($event, listCostOverlay, listCostDiv)">
              </pm-attribute-display-text-input>
              <div #listCostDiv></div>
              <pm-static-overlay-panel #listCostOverlay appendTo="body" [style]="getListCostOverlayStyle(i)">

                <div class="listCostOverlayWrapper">
                  <div class="listCostOverlay">
                    <div class="row m-0">
                      <img src="/assets/images/bulk-quick-action-bolt.svg">
                      <button pButton type="button" label="Apply to all groups"
                              class="ui-button-link"
                              (click)="onClickApplyToAllGroups(authGroup.listCost, listCostOverlay)"></button>
                    </div>
                    <button *ngIf="i !== 0" pButton type="button" label="Apply to all remaining groups"
                            class="ui-button-link remainGroupsButton"
                            (click)="onClickApplyToAllRemainingGroups(authGroup.listCost, listCostOverlay)">

                    </button>

                  </div>
                </div>
              </pm-static-overlay-panel>
              <div *ngIf="candidateProductError?.locationGroupStoresErrors && candidateProductError?.locationGroupStoresErrors[authGroup.splrLocationGroupId]?.listCost">
                <p class="error-message">
                  {{candidateProductError?.locationGroupStoresErrors[authGroup.splrLocationGroupId]?.listCost}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="authGroup.isViewing" class="authGroupBody">
          <div class="row ml-0">
            <div class="checkboxColumn">
              <p-checkbox [(ngModel)]="authGroup.checked"
                          (onChange)="invitedDistributorService.onAuthGroupChecked($event, authGroup)"
                          binary="true">
              </p-checkbox>
            </div>
            <div class="col-md-2 columnHeader">
              Store #
            </div>
            <div class="col-md-7 columnHeader">
              Name
            </div>
            <div class="col-md-2 columnHeader" style="padding-left: 2.25rem;" *ngxPermissionsOnly="['ROLE_DSD_MULT_COST']">
              List cost
            </div>
          </div>
          <ng-template ngFor let-store let-x="index" [ngForOf]="authGroup.stores">

            <div class="row ml-0" *ngIf="!store.isHidden" [ngClass]="{'error-row' : candidateErrorUtilService.has706StoreError(candidateProductError, authGroup.splrLocationGroupId) && store.custLocationNumber === 706}">
              <div class="checkboxColumn">
                <p-checkbox [(ngModel)]="store.checked"
                            (onChange)="onStoreChecked($event, store, authGroup)"
                            binary="true">
                </p-checkbox>
              </div>
              <div class="col-md-2 columnCell" [ngClass]="{'disabledCell': !store.checked}">
                {{store.custLocationNumber}}
              </div>
              <div class="col-md-7 columnCell" [ngClass]="{'disabledCell': !store.checked}">
                {{store.locationName}}
              </div>
              <div class="col-md-2 columnCell" style="padding-left: 2.25rem;" [ngClass]="{'disabledCell': !store.checked}">
                {{costService.toCurrency(store.listCost)}}
              </div>
            </div>
            <p *ngIf="!store.isHidden && candidateErrorUtilService.has706StoreError(candidateProductError, authGroup.splrLocationGroupId) && store.custLocationNumber === 706" class="error-message">
              {{candidateErrorUtilService.get706StoreError(candidateProductError, authGroup.splrLocationGroupId)}}
            </p>
          </ng-template>

        </div>
      </div>
    </ng-template>
  </div>
  <div class="authStoresSummary">
    <p class="totalStoreSelected">{{getAllStoreAuthCountsString()}}</p>
    <ng-template ngFor let-authGroup let-i="index" [ngForOf]="invitedDistributorService.selectedAuthGroups">
      <p *ngIf="authGroup.stores && authGroup.stores.length > 0" class="authStoresSelected">
        {{getStoreAuthCountsString(authGroup)}}
      </p>
    </ng-template>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickSubmit()" [disabled]="!canClickSubmit || hasNoStoresSelected()"
              pTooltip="{{hasNoStoresSelected() ? 'Select at least one store.' : ''}}"></p-button>
  </pm-footer>
</pm-editor>
